import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Row, Col, Card, Form, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { CustomDataTable } from '../../../../components/design/Datatables/CustomDataTable'
import useAuth from '../../../../context/auth/useAuth'
import { changeSiteStatus, getSitesByClub, removeSite } from '../../../../actions/sites/sitesActions'
import SitesSubMenu from '../../../../components/menus/SitesSubMenu'
import Swal from 'sweetalert2'

export default function Sites({clubselected, handleSiteSelect}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
	const {clientauth} = useAuth()

    const {sites, result} = useSelector(state=>state.sites)

    useEffect(() => {
        handleSiteSelect(null)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if(clientauth){
            dispatch(getSitesByClub(clubselected))
        }
        // eslint-disable-next-line
    },[dispatch, t, clientauth])

    useEffect(() => {
        if(result?.message){
            switch (result.message) {
                case 'editedSuccess':
                    dispatch(getSitesByClub(clubselected))
                    dispatch({
                        type: 'SITE_RESET_RESULT'
                    })
                    break;
                    
                case "deleteSiteWrongRerservations":
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.accept'),
                        icon: 'warning',
                        text: t('sites.deleteSiteWrongRerservations')
                    })
                    dispatch({
                        type: 'SITE_RESET_RESULT'
                    })
                    break;
                case "deleteZoneWrongRerservations":
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.accept'),
                        icon: 'warning',
                        text: t('sites.deleteZoneWrongRerservations')
                    })
                    dispatch({
                        type: 'SITE_RESET_RESULT'
                    })
                    break;
                case "deleteCourtWrongRerservations":
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.accept'),
                        icon: 'warning',
                        text: t('sites.deleteCourtWrongRerservations')
                    })
                    dispatch({
                        type: 'SITE_RESET_RESULT'
                    })
                    break;
                case "deleteAreaWrongRerservations":
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.accept'),
                        icon: 'warning',
                        text: t('sites.deleteAreaWrongRerservations')
                    })
                    dispatch({
                        type: 'SITE_RESET_RESULT'
                    })
                    break;
                default:
                    break;
            }
        }
        // eslint-disable-next-line
    }, [result])

    const handleStatusChange = (siteId, status) => {
        dispatch(changeSiteStatus(siteId, status))
    }

    const handleRemoveSite = (e, siteId) => {
        e.preventDefault()

        dispatch(removeSite(siteId))
    }

	const columnsSites = [
        {
            name: t('sites.image'),
            width:'120px',
            cell: site =>
                <Link onClick={() => handleSiteSelect(site._id)} to={`/club/sites/edit`} className='table-link'>
                    {site.image ?
                        <img src={site.image} alt={site.title} className='imagen'/>
                    : 
                        <div className='imagen'></div>
                    }
                </Link>,
            selector: (site) => site.image,
            sortable: false,
        },{
            name: t('sites.name'),
            cell: site =>
                <Link onClick={() => handleSiteSelect(site._id)} to={`/club/sites/edit`} className='table-link'>
                    {site.name}
                </Link>,
            selector: (site) => site.name,
            sortable: true,
        },{
			name: t('sites.manager'),
			cell: site =>
				<Link onClick={() => handleSiteSelect(site._id)} to={`/club/sites/edit`} className='table-link'>
					{site.responsible.name}
				</Link>,
			selector: (site) => site.responsible.name,
			sortable: true,
		},{
            name: t('sites.status'),
            center: 'true',  
            width: '100px',
            cell: site =>
                <div>
                    <Form.Check
                        type='switch'
                        checked={site.active}
                        onChange={({target}) => (handleStatusChange(site._id, target.checked))}
                    />
                </div>,
            selector: (site) => site.active,
            sortable: false,
        },{
			name: t('global.actions'), 
            center: 'true',  
            width:'120px',
			cell: site =>
                <OverlayTrigger
                    placement="bottom"
                    trigger='focus'
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <Tooltip className='menu-puntos text-left'>
                            <Link onClick={() => handleSiteSelect(site._id)} to={`/club/sites/edit`} className='dropdown-item'>
                                <img src="/images/edit.svg" alt={t('sites.dataTitle')} width={15}></img> {t('sites.dataTitle')}
                            </Link>
                            <Link onClick={() => handleSiteSelect(site._id)} to={`/club/sites/edit/step2`} className='dropdown-item'>
                                <img src="/images/menus/calendar.svg" alt={t('sites.schedules')} width={15}></img> {t('sites.schedules')}
                            </Link>
                            <Link onClick={() => handleSiteSelect(site._id)} to={`/club/sites/edit/step3`} className='dropdown-item'>
                                <img src="/images/menus/user.svg" alt={t('sites.manager')} width={15}></img> {t('sites.manager')}
                            </Link>
                            <Link onClick={(e) => handleRemoveSite(e, site._id)} className='dropdown-item'>						
                                <img src="/images/trash.svg" alt="trash" width={15} /> {t('global.delete')}
					        </Link>                            
                        </Tooltip>
                    }
                    >
                    <Button variant="puntos"><img src='/images/menupuntos.svg' alt='Menu auxiliar'/></Button>
                </OverlayTrigger>,
			sortable: false,
		}
	]

    return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <SitesSubMenu active="sites"/>
                </Col>
            </Row>
            <Row className='my-3'>
                <Col>
                    <Card>
                        <Card.Body>
                            <h2>{t('sites.sitesList')}</h2>
                            <hr className='mb-4'/>
                            <div className='buttons d-flex justify-content-end'>
                                <Link to="/club/sites/new" className='btn btn-secondary'>
                                    <i className='fas fa-plus'></i> {t('sites.createSite')}
                                </Link>
                            </div>
                            <div className='my-3'>
                                <Row>
                                    <Col>
                                        {
                                            sites?.length > 0
                                                ?
                                                    <CustomDataTable columns={columnsSites} data={sites} exportable={false} printable={false}/>
                                                :
                                                    <div className='contenedor-vacio text-center'>
                                                        <h4>{t('sites.noSites')}</h4>
                                                        {t('sites.thereIsNotSitesPhrase')}
                                                        <div className='mt-3'>
                                                            <Link to="/club/sites/new" className='btn btn-secondary'>
                                                                {t('sites.createSite')}
                                                            </Link>
                                                        </div>
                                                    </div>
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}