import React from 'react'
import { Container, Row, Col, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"


export default function SitesSubMenu({ active }) {
    const { t } = useTranslation()

    return (        
        <Container fluid className='submenu px-0 mb-3'>
            <Row>
                <Col className='d-flex align-items-center'>
                    <h1 className='ms-2 my-0'>{t('club.sites')}</h1>
                </Col>
            </Row>
            <Row className='my-4'>
                <Col>
                    <Nav defaultActiveKey="/site" as="ul">
                        <Nav.Item as="li">
                            <Nav.Link as={Link} active={active === "sites"} to="/club/sites">{t('menus.sites.sites')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link as={Link} active={active === "zones"} to="/club/zones">{t('menus.sites.zones')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link as={Link} active={active === "courts"} to="/club/courts">{t('menus.sites.courts')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link as={Link} active={active === "areas"} to="/club/areas">{t('menus.sites.areas')}</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
            </Row>
        </Container>
    )
}