import AxiosControl from '../../context/axios/AxiosControl'
const Axios = AxiosControl()

export const getZonesByClub = (club) => async (dispatch) => {
    dispatch({
        type: 'ZONE_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/zone/getZonesByClub/'+ club, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'ZONE_GETALL_SUCCESS',
                payload: data.zones
            })
        }
    } catch (error) {
        dispatch({
            type: 'ZONE_NOT_LOADING'
        })
    }
}

export const getZonesBySite = (siteId) => async (dispatch) => {
    dispatch({
        type: 'ZONE_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/zone/getZonesBySite/'+ siteId, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'ZONE_GETALL_SUCCESS',
                payload: data.zones
            })
        }
    } catch (error) {
        dispatch({
            type: 'ZONE_NOT_LOADING'
        })
    }
}

export const getZone = (zoneId) => async (dispatch) => {
    dispatch({
        type: 'ZONE_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/zone/getZone/'+ zoneId, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'ZONE_GET_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'ZONE_NOT_LOADING'
        })
    }
}

export const addZone = (zoneData) => async (dispatch) => {
    dispatch({
        type: 'ZONE_LOADING'
    })
    try {

        const { data, status} = await Axios.post('/api/zone/register', zoneData, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'ZONE_ADD_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'ZONE_NOT_LOADING'
        })
    }
}

export const editZoneManagerData = (managerData, id) => async (dispatch) => {
    dispatch({
        type: 'ZONE_LOADING'
    })
    try {
        const { data, status} = await Axios.put('/api/zone/editZoneManagerData/' + id, managerData, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'ZONE_ADD_SUCCESS',
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: 'ZONE_NOT_LOADING'
        })
    }
}

export const changeZoneStatus = (id, activeStatus) => async (dispatch) => {
    dispatch({
        type: 'ZONE_LOADING'
    })
    try {
        const { data, status} = await Axios.put('/api/zone/changeStatus/' + id, {active: activeStatus}, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'ZONE_EDIT_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'ZONE_NOT_LOADING'
        })
    }
}

export const editZone = (zoneData, zoneId) => async (dispatch) => {
    dispatch({
        type: 'ZONE_LOADING'
    })
    try {
        const { data, status} = await Axios.put('/api/zone/edit/' + zoneId, zoneData, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'ZONE_ADD_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'ZONE_NOT_LOADING'
        })
    }
}

export const removeZone = (zoneId) => async (dispatch) => {
    try {
        const { data, status } = await Axios.delete('/api/zone/remove/' + zoneId, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200 || status === 263) {
            dispatch({
                type: 'ZONE_REMOVE_RESULT',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'ZONE_NOT_LOADING'
        })
    }
}