import React from "react";
import {Card} from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function BannerHead({image,title}) {
    const { t } = useTranslation()
    const backImage = {
        backgroundImage: 'url('+image+')',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100%'
    }

    return (
        <div className='p-5 text-center border-0 card-gradient position-relative' style={backImage} >
            <h2 className='display-6 text-white'>{title}</h2>
        </div>
    )
}