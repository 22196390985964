export const groupsReducer = (state = {loadinggroup: true, resultgroup: null, groups: null, group: null } , action) => {
    switch (action.type) {
        case 'GROUP_GET_SUCCESS':
            return {
                ...state,
                loadinggroup: false,
                group: action.payload.group
            }
        case 'GROUP_GETALL_SUCCESS':
            return {
                ...state,
                loadinggroup: false,
                groups: action.payload.groups
            }
        case 'GROUP_ADD_SUCCESS':
            return {
                ...state,
                loadinggroup:false,
                resultgroup: action.payload.message,
                groups: action.payload.groups
            }
        case 'GROUP_EDIT_SUCCESS':
            return {
                ...state,
                loading:false,
                resultgroup: action.payload.message
            }
        case 'GROUP_IMPORT_SUCCESS':
            return {
                ...state,
                resultgroup: action.payload.message,
                groups: action.payload.groups
            }
        case 'GROUP_REMOVE_RESULT':
            return {...state,
                loading:false,
                resultgroup: action.payload.message,
                groups: state.groups.filter(groups => groups._id !== action.payload.group)
            }
        case 'GROUP_RESET_RESULT':
            return {
                ...state,
                resultgroup: null
            }
        case 'GROUP_LOADING':
            return {
                ...state,
                loadinggroup:true
            }
        case 'GROUP_NOT_LOADING':
            return {
                ...state,
                loadinggroup:false
            }
        default:
            return state
    }
}