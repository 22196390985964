import React, { useEffect, useState, createRef } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Button, Row, Form, Col, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { editUserClub } from '../../../actions/profiles/usersActions'
import { getClub } from '../../../actions/profiles/clubsActions'
import Swal from 'sweetalert2'
import useAuth from '../../../context/auth/useAuth'
import HeadConfigClub from '../../../components/menus/SubMenuConfigClub'

import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'

export default function Basicdata({clubselected}) {
    const { t } = useTranslation()
	const {clientauth} = useAuth()
    const dispatch = useDispatch()
    const fileRef = createRef("<HTMLInputElement>")
    const fileRefTwo = createRef("<HTMLInputElement>")

    const {user, resultuser} = useSelector(state=>state.users)
    const {club} = useSelector(state=>state.clubs)

    const [isUpdating, setIslogging] = useState(false)
    const [formState, setFormState] = useState({})
    const [imageAux, setImage] = useState(null)
    const [bannerAux, setBanner] = useState(null)
    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    useEffect(() => {
        if(clientauth && clubselected){
            dispatch(getClub(clubselected))
        }
    },[dispatch, t, clientauth, clubselected])

    useEffect(() => {
        if(club){
            if (club?.description) {
                const blocksFromHtml = htmlToDraft(club.description)
                const { contentBlocks, entityMap } = blocksFromHtml
                setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(contentBlocks, entityMap)))
            }

            setFormState({
                name: club?.name,
                avatar: club?.avatar,
                phone: club?.phone,
                description: club?.description,
                showMarketplace: club?.showMarketplace,
                bannerMarketplace: club?.bannerMarketplace,
                web: club?.web,
            })

			setImage(club?.avatar)
        }
    // eslint-disable-next-line
    }, [user, club])

    const handleInputChange = ({target})=>{
        setFormState({
            ...formState,
            [target.name]: target.value
        })
    }

    const handleCheckboxChange = ({target}) => {
        setFormState({
            ...formState,
            [target.name]: target.checked
        })
    }

	const changeImage = (e) => {
		setImage(e.target.files[0])
	}

	const changeBanner = (e) => {
		setBanner(e.target.files[0])
	}

    const handleEditorStateChange = (editorState) => {
        setEditorState(editorState)

        setFormState({
            ...formState,
            description: draftToHtml(convertToRaw(editorState.getCurrentContent()))
        })
    }

    const handleEdit = async(e) => {
        e.preventDefault()
        setIslogging(true)
        dispatch(editUserClub(formState, clubselected, imageAux, bannerAux, null, null))
        setIslogging(false)
    }

    useEffect(() => {
        if(resultuser){
            let newresult = resultuser
            dispatch({
                type: 'USER_RESET_RESULT'
            })
            switch (newresult) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'success',
                        text: t('club.clubedited')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [resultuser])

    return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <HeadConfigClub nuevaSeccion="basicdata"/>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <h2>{t('club.editaccount')}</h2>
                            <hr className='mb-4'/>
                            <Row>
                                <Col xs={12}className='mx-auto'>
                                    <Form className="formulario" onSubmit={handleEdit}>
                                        <Row className="mt-3 mb-4">
                                            <Col xs={12} lg={6} className='d-flex align-items-center'>
                                                <div className='avatar me-2'>
                                                    {imageAux
                                                        ?
                                                            <img className="img-profile" alt="Preview" width={100} height={100} src={typeof imageAux === 'string' ? imageAux : URL.createObjectURL(imageAux)} />
                                                        :
                                                            formState.avatar
                                                                ?
                                                                    <img className="img-profile" alt="Preview" src={typeof formState.avatar === 'string' ? formState.avatar : URL.createObjectURL(formState.avatar)} />
                                                                :
                                                                    <div className="img-profile"></div>
                                                    }
                                                
                                                    <input 
                                                        className='custom-file-input' 
                                                        style={{ display: 'none' }}
                                                        ref={fileRef}
                                                        type="file" 
                                                        name="myImage" 
                                                        onChange={changeImage} 
                                                    />
                                                </div>
                                                <div className="editaravatar" onClick={() => fileRef.current?.click()}>{t('club.avatar')}</div>
                                            </Col>
                                            <Col xs={12} lg={6} className='d-flex align-items-center'>
                                                <div className='bannerclub me-2'>
                                                    {bannerAux
                                                        ?
                                                            <img className="img-profile" alt="Preview" src={typeof bannerAux === 'string' ? bannerAux : URL.createObjectURL(bannerAux)} />
                                                        :
                                                            formState.bannerMarketplace
                                                                ?
                                                                    <img className="img-profile" alt="Preview" src={typeof formState.bannerMarketplace === 'string' ? formState.bannerMarketplace : URL.createObjectURL(formState.bannerMarketplace)} />
                                                                :
                                                                    <div className="img-profile"></div>
                                                    }
                                                    <input 
                                                        className='custom-file-input' 
                                                        style={{ display: 'none' }}
                                                        ref={fileRefTwo}
                                                        type="file" 
                                                        name="myBanner" 
                                                        onChange={changeBanner} 
                                                    />
                                                </div>
                                                <div className="editaravatar" onClick={() => fileRefTwo.current?.click()}>{t('club.banner')}</div>
                                            </Col>
                                        </Row>
                                        <Row className="my-3">
                                            <Col xs={12} lg={6}>
                                                <Form.Group className='form-group'>
                                                    <Form.Control
                                                        className={formState.name ? 'has-content' : ''}
                                                        type='text'
                                                        name='name' 
                                                        value={formState?.name ||  ''}
                                                        onChange={handleInputChange}
                                                        required
                                                    />
                                                    <Form.Label htmlFor='name'>{t('club.name')}</Form.Label>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} lg={6}>
                                                <Form.Group className='form-group'>
                                                    <Form.Control
                                                        className={formState.web ? 'has-content' : ''}
                                                        type='text'
                                                        name='web' 
                                                        value={formState?.web ||  ''}
                                                        onChange={handleInputChange}
                                                    />
                                                    <Form.Label htmlFor='web'>{t('club.web')}</Form.Label>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className="my-3">
                                            <Col xs={12} lg={6}>
                                                <Form.Group className='form-group'>
                                                    <Form.Control
                                                        className={formState.phone ? 'has-content' : ''}
                                                        type='text'
                                                        name='phone' 
                                                        value={formState?.phone ||  ''}
                                                        onChange={handleInputChange}
                                                    />
                                                    <Form.Label htmlFor='phone'>{t('club.phone')}</Form.Label>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} lg={6}>
                                                <Form.Group className="d-flex">
                                                    <Form.Label htmlFor='show_marketplace'>{t('club.showmarketplace')}</Form.Label>
                                                    <Form.Check
                                                        className="ms-3"
                                                        type="switch"
                                                        name="showMarketplace"
                                                        onChange={handleCheckboxChange}
                                                        checked={formState?.showMarketplace || false}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className="my-3">
                                            <Col xs={12} lg={6}>
                                                <Form.Label htmlFor='description'>{t('club.description')}</Form.Label>
                                                <Form.Group className='form-group'>
                                                    <Editor
                                                        toolbar={{
                                                            options: ['inline', 'list', 'textAlign', 'link'],
                                                            inline: { options: ['bold', 'italic', 'underline'] },
                                                            list: { options: ['unordered', 'ordered'] },
                                                            textAlign: { options: ['left', 'center', 'right'] },
                                                            link: { options: ['link', 'unlink'] }
                                                        }}
                                                        editorState={editorState}
                                                        onEditorStateChange={handleEditorStateChange}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className="mt-5">
                                            <Col xs={12} lg={6} className='ms-auto'>
                                                <Button variant='primary' type="submit" className="w-100 my-2">
                                                {isUpdating
                                                    ?
                                                        <>
                                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                        </>
                                                    :
                                                        <>{t('global.update')}</>
                                                }
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}
