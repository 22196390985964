import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Card} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { addSiteSchedulesAndBlockedDates, getSite } from '../../../../actions/sites/sitesActions'
import SiteFormStep2 from '../../../../components/forms/sites/SiteFormStep2'
import { getClub } from '../../../../actions/profiles/clubsActions'

export default function AddSiteStipe2({clubselected, selectedSite}) {
  	const { t } = useTranslation()
	const dispatch = useDispatch()
    const navigate = useNavigate()

	const {club} = useSelector(state=>state.clubs)

    const {result, loading, site} = useSelector(state => state.sites)

    const [newSite, setNewSite] = useState({
        days: [
            {weekDays: ['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'], startHour: null, endHour: null},
        ],
        datesBlocked: [],
        deletedDays: [],
        deletedDatesBlocked: []
    })

    const {siteId} = JSON.parse(sessionStorage.getItem("dataIncrono"))
    

    const handleDayCheck = (target, index) => {
        setNewSite({
            ...newSite,
            days: newSite?.days?.map((day, i) => {
                if (i === index) {
                    if (target.checked) {
                        if (target.name !== 'all') {
                            return {
                                ...day,
                                weekDays: [...day.weekDays, target.name]
                            }
                        } else {
                            return {
                                ...day,
                                weekDays: ['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
                            }
                        }
                    } else {
                        if (target.name !== 'all') {
                            return {
                                ...day,
                                weekDays: day.weekDays.filter(d => (d !== target.name && d !== "all"))
                            } 
                        } else {
                            return {
                                ...day,
                                weekDays: []
                            }
                        }
                    }
                } else {
                    return day
                }
            })
        })
    }

    const addSchedule = () => {
        setNewSite({
            ...newSite,
            days: [...newSite.days, {weekDays: ['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'], startHour: null, endHour: null}]
        })
    }

    const removeSchedule = (day, index) => {
        let deletedScheduleId = newSite.days.find((d, i) => i === index)._id
        setNewSite({
            ...newSite,
            days: newSite.days.filter((d, i) => i !== index),
            deletedDays: deletedScheduleId ? [...newSite.deletedDays, deletedScheduleId] : newSite.deletedDays
        })
    }

    const handleScheduleInputChange = (target, day) => {
        setNewSite({
            ...newSite,
            days: newSite.days.map(d => {
                if (d.weekDays === day) {
                    return {
                        ...d,
                        [target.name]: target.value
                    }
                } else {
                    return d
                }
            })
        })
    }

    const handleAddBlockedRange = (range) => {
        setNewSite({
            ...newSite,
            datesBlocked: [...newSite.datesBlocked, {startDate: range[0], endDate: range[1]}]
        })
    }

    const handleRemoveBlockedRange = (index) => {
        let deletedBlockedRangeId = newSite.datesBlocked.find((d, i) => i === index)._id
        setNewSite({
            ...newSite,
            datesBlocked: newSite.datesBlocked.filter((d, i) => i !== index),
            deletedDatesBlocked: deletedBlockedRangeId ? [...newSite.deletedDatesBlocked, deletedBlockedRangeId] : newSite.deletedDatesBlocked
        })
    }

    const handleAdd = async(e) => {
        e.preventDefault()

        dispatch(addSiteSchedulesAndBlockedDates(newSite, selectedSite))
    }
    
    useEffect(()=>{
        if (site && club) {
            setNewSite({
                days: site.schedule.length > 0 ? site.schedule : [{weekDays: ['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'], startHour: null, endHour: null}],
                datesBlocked: site.datesBlocked.map(dB => {
                    return {...dB, startDate: new Date(dB.startDate), endDate: new Date(dB.endDate)}
                }),
                deletedDays: [],
                deletedDatesBlocked: []
            })
        } else {
            if(club){
                setNewSite({
                    days: [
                        {weekDays: ['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'], startHour: null, endHour: null},
                    ],
                    datesBlocked: [],
                    deletedDays: [],
                    deletedDatesBlocked: []
                })
            }
        }
    //eslint-disable-next-line
    }, [club, site])

    useEffect(() => {
        if(result){
            switch (result) {
                case 'success':
                    navigate('/club/sites/new/step3')
                    dispatch({
                        type: 'SITE_RESET_RESULT'
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [result])

    useEffect(() => {
        if (siteId) {
            dispatch(getSite(siteId))
        }
    }, [siteId, dispatch])

    useEffect(()=>{
        if(clubselected){
            dispatch(getClub(clubselected))
        }
    //eslint-disable-next-line
    }, [dispatch, clubselected])

	return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <h1 className='ms-2 my-0'>{t('sites.sites')}</h1>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <h2 className='fs-18'>{t('sites.createSite')}</h2>
                            <hr className='mb-4'/>
                            <SiteFormStep2 state={newSite} statetype={'add'} usertype={'club'} isAdding={loading} addSchedule={addSchedule} removeSchedule={removeSchedule} submit={handleAdd} handleDayCheck={handleDayCheck} handleScheduleInputChange={handleScheduleInputChange} handleAddBlockedRange={handleAddBlockedRange} handleRemoveBlockedRange={handleRemoveBlockedRange} cancelBtn={<Link to="/club/sites/new"><Button variant="default" className="w-100 mt-3">{t('global.back')}</Button></Link>}/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
	)
}