import React, { useEffect } from 'react'
import useAuth from '../../../context/auth/useAuth'
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../../actions/profiles/usersActions';
import Loader from '../../../components/design/Loader/Loader';

export default function Inscription({activity, club}) {
	const {clientauth} = useAuth()
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const {user, loading} = useSelector(state=>state.users)

    console.log(user);

    useEffect(() => {
        dispatch(getUser(clientauth?.id))
    }, [clientauth, dispatch])
    return (
        <>
            {
                loading
                    ?
                        <Loader/>
                    :
                        <div>
                            <Card className='border-0'>
                                <Card.Body>
                                    <h2>{t('activities.inscription')}</h2>
                                    <hr className="mb-4"/>
                                    <p>
                                        {t("activities.inscriptionText1")}{club.name}{t("activities.inscriptionText2")}
                                    </p>
                                    <p>
                                        {t("activities.inscriptionText3")}<span className='fw-bold'>{club.phone}</span>.
                                    </p>
                                    {
                                        !clientauth
                                            ?
                                                <div className='contenedor-vacio text-center py-4'>
                                                    <h4 className='fw-bold'>{t('marketplace.registerOrLogIn')}</h4>
                                                    {t('marketplace.activity.needAccount')}
                                                    <div className='mt-3'>
                                                        <Link to="/login" className='btn btn-primary m-3'>
                                                            {t('signin.signin')}
                                                            <img alt={t("signin.signin")} src='/images/register.svg' className='ms-2'/>
                                                        </Link>
                                                        <Link to="/login" className='btn btn-secondary m-3'>
                                                            {t('signin.login')}
                                                            <img alt={t("signin.login")} src='/images/login.svg' className='ms-2'/>
                                                        </Link>
                                                    </div>
                                                </div>
                                            :
                                                <></>
                                    }
                                </Card.Body>
                            </Card>
                        </div>
            }
        </>
    )
}