import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Button, Row, Form, Col, Card, CardBody} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { editUserClub } from '../../../actions/profiles/usersActions'
import Swal from 'sweetalert2'
import useAuth from '../../../context/auth/useAuth'
import { getClub } from '../../../actions/profiles/clubsActions'
import HeadConfigClub from '../../../components/menus/SubMenuConfigClub'

export default function Socialdata({clubselected}) {
    const { t } = useTranslation()
	const {clientauth} = useAuth()
    const dispatch = useDispatch()

    const {user, resultuser} = useSelector(state=>state.users)
    const {club} = useSelector(state=>state.clubs)

    const [isUpdating, setIslogging] = useState(false)
    const [formState, setFormState] = useState({})

    useEffect(() => {
        if(clientauth && clubselected){
            dispatch(getClub(clubselected))
        }
    },[dispatch, t, clientauth, clubselected])

    useEffect(() => {
        if(club){
            setFormState({
                x: club?.x,
                facebook: club?.facebook,
                instagram: club?.instagram,
                tiktok: club?.tiktok,
                twitch: club?.twitch,
                linkedin: club?.linkedin,
            })
        }
    // eslint-disable-next-line
    }, [user, club])

    const handleInputChange = ({target})=>{
        setFormState({
            ...formState,
            [target.name]: target.value
        })
    }

    const handleEdit = async(e) => {
        e.preventDefault()
        setIslogging(true)
        dispatch(editUserClub(formState, clubselected, null, null, null))
        setIslogging(false)
    }

    useEffect(() => {
        if(resultuser){
            let newresult = resultuser
            dispatch({
                type: 'USER_RESET_RESULT'
            })
            switch (newresult) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'success',
                        text: t('club.clubedited')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [resultuser])

    return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <HeadConfigClub nuevaSeccion="social"/>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <CardBody>
                            <h2>{t('users.editsocial')}</h2>
                            <hr className='mb-4'/>
                            <Form className="formulario" onSubmit={handleEdit}>
                                <Row className="my-3">
                                    <Col xs={12} lg={6}>
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                className={formState.x ? 'has-content' : ''}
                                                type='text'
                                                name='x' 
                                                value={formState.x ||  ''}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Label htmlFor='x'>{t('users.x')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                className={formState.facebook ? 'has-content' : ''}
                                                type='text'
                                                name='facebook' 
                                                value={formState.facebook ||  ''}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Label htmlFor='facebook'>{t('users.facebook')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="my-3">
                                    <Col xs={12} lg={6}>
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                className={formState.instagram ? 'has-content' : ''}
                                                type='text'
                                                name='instagram' 
                                                value={formState.instagram ||  ''}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Label htmlFor='instagram'>{t('users.instagram')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                className={formState.tiktok ? 'has-content' : ''}
                                                type='text'
                                                name='tiktok' 
                                                value={formState.tiktok ||  ''}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Label htmlFor='tiktok'>{t('users.tiktok')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="my-3">
                                    <Col xs={12} lg={6}>
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                className={formState.twitch ? 'has-content' : ''}
                                                type='text'
                                                name='twitch' 
                                                value={formState.twitch ||  ''}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Label htmlFor='twitch'>{t('users.twitch')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                className={formState.linkedin ? 'has-content' : ''}
                                                type='text'
                                                name='linkedin' 
                                                value={formState.linkedin ||  ''}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Label htmlFor='linkedin'>{t('users.linkedin')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="mt-5">
                                    <Col xs={12} lg={6} className='ms-auto'>
                                        <Button variant='primary' type="submit" className="w-100 my-2">
                                        {isUpdating
                                            ?
                                                <>
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </>
                                            :
                                                <>{t('global.update')}</>
                                        }
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}
