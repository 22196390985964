import React from 'react'
import { Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"


export default function SidebarClub({ isCollapsed }) {
	const { t } = useTranslation()

  	return (		
		<div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
			<Link to="/"><img src='/images/logotipo-incrono-cabecera.svg' id="logo-menu" alt='Logotipo Incrono' className='mb-5 ms-3' /></Link>
			<Nav className="flex-column p-0">
				<Nav.Item>
					<Link to="/club/dashboard"><img src="/images/menus/dashboard.svg" alt={t('menus.general.dashboard')}/> {!isCollapsed && t('menus.general.dashboard')}</Link>
				</Nav.Item>				
				<Nav.Item className='disabled'>
					<Link to="/#" onClick={(e) => e.preventDefault()}><img src="/images/menus/calendar.svg" alt={t('menus.general.calendar')}/> {!isCollapsed && t('menus.general.calendar')}</Link>
				</Nav.Item>
				<Nav.Item className='disabled'>
					<Link to="/#" onClick={(e) => e.preventDefault()}><img src="/images/menus/members.svg" alt={t('menus.general.clients')}/> {!isCollapsed && t('menus.general.clients')}</Link>
				</Nav.Item>
				<Nav.Item className='disabled'>
					<Link to="/#" onClick={(e) => e.preventDefault()}><img src="/images/menus/payments.svg" alt={t('menus.general.payments')}/> {!isCollapsed && t('menus.general.payments')}</Link>
				</Nav.Item>
				<Nav.Item className='disabled'>
					<Link to="/#" onClick={(e) => e.preventDefault()}><img src="/images/menus/reserves.svg" alt={t('menus.general.reserves')}/> {!isCollapsed && t('menus.general.reserves')}</Link>
				</Nav.Item>
				<Nav.Item>
					<Link to="/club/activities"><img src="/images/menus/activities.svg" alt={t('menus.general.activities')}/> {!isCollapsed && t('menus.general.activities')}</Link>
				</Nav.Item>
				<Nav.Item>
					<Link to="/club/sites"><img src="/images/menus/sites.svg" alt={t('menus.general.sites')}/> {!isCollapsed && t('menus.general.sites')}</Link>
				</Nav.Item>
				<Nav.Item className='disabled'>
					<Link to="/#" onClick={(e) => e.preventDefault()}><img src="/images/menus/billing.svg" alt={t('menus.general.billing')}/> {!isCollapsed && t('menus.general.billing')}</Link>
				</Nav.Item>
				<Nav.Item className='disabled'>
					<Link to="/#" onClick={(e) => e.preventDefault()}><img src="/images/menus/reports.svg" alt={t('menus.general.reports')}/> {!isCollapsed && t('menus.general.reports')}</Link>
				</Nav.Item>
				<Nav.Item>
					<Link to="/club/basicdata"><img src="/images/menus/config.svg" alt={t('menus.general.config')}/> {!isCollapsed && t('menus.general.config')}</Link>
				</Nav.Item>
			</Nav>
		</div>
	)
}