import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Button, Row, Form, Col, Card} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { editUserClient } from '../../../actions/profiles/usersActions'
import { getClient } from '../../../actions/profiles/clientsActions'
import Swal from 'sweetalert2'
import useAuth from '../../../context/auth/useAuth'
import HeadConfig from '../../../components/menus/SubMenuConfig'

export default function Carddata({ clientselected }) {
    const { t } = useTranslation()
	const {clientauth} = useAuth()
    const dispatch = useDispatch()

    const {user, resultuser} = useSelector(state=>state.users)
    const {client} = useSelector(state=>state.clients)

    const [isUpdating, setIslogging] = useState(false)
    const [formState, setFormState] = useState({})

    useEffect(() => {
        if(clientauth){
            dispatch(getClient(clientselected))
        }
    },[dispatch, t, clientauth, clientselected])

    useEffect(() => {
        if(client){
            setFormState({})
        }
    // eslint-disable-next-line
    }, [user, client])

    const handleEdit = async(e) => {
        e.preventDefault()
        setIslogging(true)
        dispatch(editUserClient({...formState, usertoupdate: clientauth?.id}, null, null))
        setIslogging(false)
    }

    useEffect(() => {
        if(resultuser){
            let newresult = resultuser
            dispatch({
                type: 'USER_RESET_RESULT'
            })
            switch (newresult) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'success',
                        text: t('users.useredited')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [resultuser])

    return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <HeadConfig nuevaSeccion="card" type={clientauth?.client === clientselected ? 'client' : 'member'}/>
                </Col>
            </Row>
            {clientauth?.client === clientselected
            ?
                <Row className="my-3">
                    <Col xs={12} lg={7} className='mb-2'>
                        <Card>
                            <Card.Body>
                                <h2>{t('users.editcards')}</h2>
                                <hr className='mb-4'/>
                                <h4>{t('users.newcard')}</h4>
                                <Form className="p-4 formulario" onSubmit={handleEdit}>
                                    <Row className="my-3">
                                        <Col>
                                            {/* AQUI VA UN TODO NO UNA FRASE QUE SE NOS PUEDE PASAR POR ALTO //TO:DO */}
                                            Lo que haya que rellenar de la tarjeta ueva
                                        </Col>
                                    </Row>
                                    <Row className="mt-5">
                                        <Col xs={12} lg={6} className='ms-auto'>
                                            <Button variant='primary' type="submit" className="w-100 my-2">
                                            {isUpdating
                                                ?
                                                    <>
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                    </>
                                                :
                                                    <>{t('global.update')}</>
                                            }
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} lg={5}>
                        <Card>
                            {/* AQUI VA UN TODO NO UNA FRASE QUE SE NOS PUEDE PASAR POR ALTO //TO:DO */}
                            Hueco para tarjetas imitar Direcciones.<br/>
                            Hay una sección en traducciones preparada.
                        </Card>
                    </Col>
                </Row>
            :
                <Row>
                    <Col xs={12}>
                        <Card>
                            <h4 className='text-center'>{t('users.cards.noauthorized')}</h4>
                        </Card>
                    </Col>
                </Row>
            }
        </Container>
    )
}
