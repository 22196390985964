export const courtsReducer = (state = {loading: false, result: null, courts: [], court: null}, action) => {
    switch (action.type) {
        case 'COURT_LOADING':
            return {
                ...state,
                loading: true
            }
        case 'COURT_NOT_LOADING':
            return {
                ...state,
                loading: false
            }
        case 'COURT_GETALL_SUCCESS':
            return {
                ...state,
                loading: false,
                courts: action.payload
            }
        case 'COURT_GET_SUCCESS':
            return {
                ...state,
                loading: false,
                court: action.payload
            }
        case 'COURT_ADD_SUCCESS':
            return {
                ...state,
                loading: false,
                result: action.payload
            }
        case 'COURT_EDIT_SUCCESS':
            return {
                ...state,
                loading: false,
                result: action.payload
            }
        case 'COURT_REMOVE_RESULT':
            return {
                ...state,
                loading: false,
                result: action.payload,
                courts: action.payload.message === "deleteCourtSuccess" ? state.courts.filter(s => s._id !== action.payload.court) : state.courts
            }
        case 'COURT_RESET_RESULT':
            return {
                ...state,
                result: null
            }
        default:
            return state
    }
}