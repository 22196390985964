import React, { useEffect } from 'react'
import { Container, Row } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { getClubsPublic } from '../../actions/profiles/clubsActions'
import { useDispatch, useSelector } from 'react-redux'
import MenuMarketplace from '../../components/menus/MenuMarketplace'
import Grid from '../../components/design/Grid/Grid'
import { FooterMarketplace } from '../../components/footers/FooterMarketplace'
import SearchClubs from '../../components/design/Search/SearchClubs'
import BannerHead from '../../components/Banners/BannerHead'


export default function Clubs() {
    const { t } = useTranslation()
	const dispatch = useDispatch()

    const {clubs} = useSelector(state=>state.clubs)

    useEffect(() =>{
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        dispatch(getClubsPublic())
    }, [dispatch])

    return (
        <>
            <MenuMarketplace section='clubs'/>
            <BannerHead image='/images/fondo-auth.jpg' title={t('marketplace.home.clubs')}/>
            <SearchClubs/>
            <Container className='market'>
                <Row className="my-3">
                    <Grid type="club" data={clubs}/>
                </Row>
            </Container>
            <FooterMarketplace className='m-0'/>
        </>
        
    )
}