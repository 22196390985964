import React, { useEffect, useState } from 'react'
import {Container, Button, Navbar, NavDropdown, Dropdown, Form} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import useAuth from '../../context/auth/useAuth'
import { Link, useNavigate } from 'react-router-dom'
import { MenuDropdown, MenuDropdownClub } from '../design/Dropdown/MenuDropdown'
import { addClub, getClubsByUser } from '../../actions/profiles/clubsActions'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'

export default function MenuSuperior({toggleSidebar , isCollapsed, client, avatar, clubs, handleClientSelect, handleClubSelect}) {
	const { t } = useTranslation()
    const dispatch = useDispatch()
	const {clientauth, logout} = useAuth()
    const navigate = useNavigate()

    const {club, resultclub} = useSelector(state=>state.clubs)

    const [showNewClub, setShowNewClub] = useState(false)
    const [nameNewClub, setNameNewClub] = useState('')
    const [isCreating, setIsCreating] = useState(false)

    const showInputNewClub = async(e) => {
        e.preventDefault()

		setShowNewClub(!showNewClub)
		setNameNewClub('')
    }

	const handleAdd = async(e) => {
        e.preventDefault()
		setIsCreating(true)

		if(nameNewClub !== ''){
			dispatch(addClub({name: nameNewClub, userid: clientauth?.id}))
		}

		setIsCreating(false)
    }

    useEffect(() => {
        if(resultclub){
            let newresult = resultclub
            dispatch({
                type: 'CLUB_RESET_RESULT'
            })
            switch (newresult) {
                case 'success':
					dispatch(getClubsByUser(clientauth?.id))
					handleClubSelect(club._id)
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('club.gotoclub'),
                        icon: 'success',
                        text: t('club.clubadd')
                    }).then((newresult) => {
                        if (newresult.isConfirmed) {
                            navigate('/club/basicdata')
                        }
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [resultclub])

  	return (
		<Navbar expand="md" id="menu-superior" className='py-2 bg-white border-bottom border-2 border-dark'>
			<Container fluid>
				<Navbar.Brand href="#">
					<Button variant='default' className='btn-icono' onClick={toggleSidebar}>
						{isCollapsed ? 
							<>
								<img src='/images/flechas.svg'  className='rotate-horizontal' alt='Flecha'/>
							</>
						:
							<>
								<img src='/images/flechas.svg' alt='Flecha'/>
							</>
						}
					</Button>
				</Navbar.Brand>
				<div className='d-flex'>
					<MenuDropdownClub>
						{clubs?.map((clubOption, index) => (
							<Dropdown.Item key={index} eventKey={clubOption.name} to="/club/dashboard" as={Link} onClick={() => handleClubSelect(clubOption._id)} className='d-flex align-items-center'>
								<figure className='club' style={{
									backgroundImage: `url(${clubOption.avatar})`,
									backgroundSize: 'cover',
									backgroundPosition: 'center',
								}}>
								</figure>
								<span>{clubOption.name}</span>
							</Dropdown.Item>
						))}
						<div className='d-flex align-items-center py-2 ps-4' onClick={(e) => showInputNewClub(e)}>
							<figure className='new'></figure>{nameNewClub ? nameNewClub : t('menus.general.newclub')}
						</div>
						<div className='px-2'>
							{showNewClub ?
								<Form className="formulario" onSubmit={handleAdd}>
									<Form.Group className='form-group'>
										<Form.Control
											type='text'
											name='namenewclub' 
											value={nameNewClub ||  ''}
											onChange={(e) => setNameNewClub(e.target.value)}
											required
										/>
									</Form.Group>
									<Button variant='primary' type="submit" className="w-100 my-2">
									{isCreating
										?
											<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
										:
											<>{t('global.create')}</>
									}
									</Button>
								</Form>
							:
								''
							}
						</div>
					</MenuDropdownClub>
					<MenuDropdown backgroundImage={avatar}>
						<Link to="/client/dashboard" className='dropdown-item'>{t('menus.general.dashboard')}</Link>
						<Link to="/client/personaldata" onClick={() => handleClientSelect(client)} className='dropdown-item'>{t('menus.general.profile')}</Link>
						<NavDropdown.Divider />
						<Link to="/" className='dropdown-item' onClick={logout}>{t('menus.logout')}</Link>
					</MenuDropdown>
				</div>
			</Container>
		</Navbar>
	)
}