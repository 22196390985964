import React, {Fragment, useState} from 'react'
import axios from 'axios'
import { useNavigate, useParams  } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import Swal from 'sweetalert2'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'

export default function ResetPassword(props) {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState("")
    const [password, setPassword] = useState("")

    const { id } = useParams()
    const { tokenresetpassword } = useParams()

    /**
     * Captura el submit del formulario y cambia la clave de la cuenta
     * @param {*} e 
     */
    const handleSubmit = async(e) => {
        e.preventDefault()

        const clientPassword = {
            password: e.target.password.value
        }        
           
        setIsLoading(true)

        await axios.put(process.env.REACT_APP_DB_ADDRESS + '/api/forgot/resetpassword?id=' + id + '&token=' + tokenresetpassword, clientPassword, {
            where: {
                id: id,
                tokenresetpassword: tokenresetpassword
            }
        })
        .then((res) => {            
            setIsLoading(false)

            // Mostramos la notificación
            Swal.fire({
                showConfirmButton: true,
                icon: 'success',
                text: 'Contraseña cambiada con éxito'
            })
            
            // Redirigimos a la raíz
            navigate('/')

        }).catch((err) => {
            setIsLoading(false)
            Swal.fire({
                showConfirmButton: true,
                icon: 'error',
                text: 'Ha habido un error al intentar enviar los datos, vuelva a intentarlo más tarde'
            })
        })
    }

    const checkValidation = (e) => {
        const confirmPass = e.target.value

        if (password !== confirmPass) {
            setIsError("Las contraseñas no coinciden")
        }else {
            setIsError("")
        }
    }

    return (
        <Fragment>
            <header className="cabecera sticky-top d-flex align-items-center px-4">
                <Link to='/'><img src="/images/logotipo-incrono-cabecera.svg" alt="Logotipo Incrono" /></Link>
            </header>
            <Container fluid className='p-0 m-0'>
                <Row className='p-0 m-0 centro-auth'>   
                    <Col xs={12} md={5} className='d-flex align-items-center justify-content-center p-5 mx-auto'>
                        <Container>
                            <Row>
                                <Col className='text-center'>
                                    <h1>{t('passwordreset.question')}</h1>
                                    {t('passwordreset.help')}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form className="formulario" onSubmit={handleSubmit}>                                    
                                        <Row className="my-3">
                                            <Col>
                                                <Form.Label htmlFor='password'>{t('passwordreset.new_password')}</Form.Label>
                                                <Form.Control type="password" className="login" name="password" autoComplete="off" onChange={(e) => setPassword(e.target.value)} />
                                            </Col>
                                        </Row>
                                        <Row className="my-3">
                                            <Col>
                                                <Form.Label htmlFor='confirm_password'>{t('passwordreset.confirm_password')}</Form.Label>
                                                <Form.Control type="password" className="login" name="confirm_password" autoComplete="off" onChange={checkValidation}/>
                                            </Col>
                                        </Row>
                                        {/* Mostramos si coinciden o no las contraseñas */}
                                        {isError !== ''
                                            ?
                                                <Row className="my-3">
                                                    <Col>
                                                        <div className="text-danger">* {t('passwordreset.passwordsdoesnotmatch')} *</div>
                                                    </Col>
                                                </Row>
                                            :
                                                <></>
                                        }
                                        <Row className="my-3">
                                            <Col>
                                                <Button type="submit" variant="primary" className="w-100 my-2">
                                                    {/* Mostramos un loader mientras se realiza la petición */}
                                                    {isLoading
                                                        ?
                                                            <span className="indicator-label">
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                            </span>
                                                        :
                                                            <span className="indicator-label">{t('passwordreset.submit')}</span>
                                                    }
                                                </Button>                                           
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </Row>
                            <Row className="my-3">
                                <Col>
                                    <Link to="/login" className='btn btn-default w-100 my-2'>
                                            {t('global.return')}
                                    </Link>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}