import { createRef } from 'react'
import { Button, Col, Form, Row} from "react-bootstrap"
import { useTranslation } from "react-i18next"
import Select from "react-select"
import { Link } from 'react-router-dom'

import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

export default function ActivityForm({state, statetype, usertype, isAddingOrEditing, clubs, sports, submit, inputChange, checkboxChange, selectChange, handleImage, editorState, handleEditorStateChange}) {
    const { t } = useTranslation()
    const fileRef = createRef("<HTMLInputElement>")

    return (
        <Form onSubmit={submit}>
            <Row className="mt-3 mb-4">
                <Col xs={12} lg={6} className='d-flex align-items-center'>
                    <div className='avatar-cuadrado me-2'>
                        {state?.image
                            ?
                                <img className="img-profile" alt="Preview" src={typeof state?.image === 'string' ? state?.image : URL.createObjectURL(state?.image)} />
                            :
                                <div className="img-profile"></div>
                        }
                        <input 
                            className='custom-file-input' 
                            style={{ display: 'none' }}
                            ref={fileRef}
                            type="file" 
                            name="myImage" 
                            onChange={handleImage} 
                        />
                    </div>
                    <div className="editaravatar" onClick={() => fileRef.current?.click()}>{t('activities.avatar')}</div>
                </Col>
            </Row>
            <Row className="my-3">
                <Col xs={12} lg={6}>
                    <Form.Group className='form-group'>
                        <Form.Control 
                            className={state?.title ? 'has-content' : ''}
                            type='text'
                            name='title'
                            value={state?.title ||  ''}
                            onChange={inputChange}
                            required
                        />
                        <Form.Label htmlFor='title'>{t('activities.title')}</Form.Label>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="my-3">
                <Col xs={12}>
                    <Form.Group className='form-group'>
                        <Form.Label htmlFor='description'>{t('activities.description')}</Form.Label>
                        <Form.Group className='form-group'>
                            <Editor
                                toolbar={{
                                    options: ['inline', 'list', 'textAlign', 'link'],
                                    inline: { options: ['bold', 'italic', 'underline'] },
                                    list: { options: ['unordered', 'ordered'] },
                                    textAlign: { options: ['left', 'center', 'right'] },
                                    link: { options: ['link', 'unlink'] }
                                }}
                                editorState={editorState}
                                onEditorStateChange={handleEditorStateChange}
                            />
                        </Form.Group>
                    </Form.Group>
                </Col>
            </Row>
            {usertype === 'superadmin' ?
                <Row className="my-3">
                    <Col xs={12} lg={6}>
                        <Form.Group className='form-group'>                            
                            <Select
                                className="form-control has-content"
                                name='club'
                                options={clubs}
                                value={{ value: state?.club?.value || '', label: state?.club?.label }}
                                onChange={(e) => selectChange(e, 'club')}
                                required
                            />
                            <Form.Label htmlFor='club'>{t('activities.club')}</Form.Label>
                        </Form.Group>
                    </Col>
                </Row>
            :
                ''
            }
            <Row className="my-3">
                <Col xs={12} lg={6}>
                    <Form.Group className='form-group'>
                        <Select
                            className="form-control has-content"
                            name='sport'
                            options={sports}
                            value={{ value: state?.sport || '', label: t('sports.' + (state?.sport || '')) }}
                            onChange={(e) => selectChange(e, 'sport')}
                            required
                        />
                        <Form.Label htmlFor='sport'>{t('activities.sport')}</Form.Label>
                    </Form.Group>
                </Col>
                <Col xs={12} lg={6}>
                    <Form.Group className='form-group'>
                        <Select
                            className="form-control has-content"
                            name='visibility_type'
                            options={[
                                { value: 'general' || '', label: t('activities.visibilityTypes.general') },
                                { value: 'adult' || '', label: t('activities.visibilityTypes.adult') },
                                { value: 'childish' || '', label: t('activities.visibilityTypes.childish') }
                            ]}
                            value={{ value: state?.visibility_type || '', label: t('activities.visibilityTypes.' + (state?.visibility_type)) || '' }}
                            onChange={(e) => selectChange(e, 'visibility_type')}
                            required
                        />
                        <Form.Label htmlFor='visibilityType'>{t('activities.visibilityType')}</Form.Label>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="my-3">
                <Col xs={12} lg={6}>
                    <Form.Group className="d-flex">
                        <Form.Label htmlFor='visibility'>{t('activities.visibility')}</Form.Label>
                        <Form.Check
                            className="ms-3"
                            type="switch"
                            name="visibility"
                            onChange={checkboxChange}
                            checked={state?.visibility || false}
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} lg={6}>
                    <Form.Group className="d-flex">
                        <Form.Label htmlFor='active'>{t('activities.active')}</Form.Label>
                        <Form.Check
                            className="ms-3"
                            type="switch"
                            name="active"
                            onChange={checkboxChange}
                            checked={state?.active || false}
                        />
                    </Form.Group>
                </Col>
            </Row>
            {isAddingOrEditing
                ?
                    //TODO:
                    'CARGANDO'
                :
                    <Row className="mt-5">
                        <Col xs={12} lg={6}>
                            <Link to="/club/activities" className="btn btn-default my-2 w-100">
                                {t('global.cancel')}
                            </Link>
                        </Col>
                        <Col xs={12} lg={6} className='ms-auto'>
                            <Button type="submit" variant="primary" className='my-2 w-100'>
                                {
                                    statetype === 'add' ? t('global.next') : 
                                    statetype === 'edit' ? t('global.edit') : ''
                                }
                            </Button>
                        </Col>
                    </Row>
            }
        </Form>
    )    
}
