import React, { useEffect, useState } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import NotFoundPage from '../pages/error/404'
import { Container } from 'react-bootstrap'
import Dashboard from '../pages/client/Dashboard'
import Personaldata from '../pages/client/config/Personaldata'
import Socialdata from '../pages/client/config/Socialdata'
import Addressdata from '../pages/client/config/Addressdata'
import Medicaldata from '../pages/client/config/Medicaldata'
import Carddata from '../pages/client/config/Carddata'
import Accountdata from '../pages/client/config/Accountdata'
import Identitydata from '../pages/client/config/Identitydata'
import Members from '../pages/client/member/Members'
import RegisterMember from '../pages/client/member/RegisterMember'
import MenuSuperior from '../components/menus/MenuSuperior'
import SidebarClient from '../components/menus/SidebarClient'
import useAuth from '../context/auth/useAuth'
import { getClubsByUser } from '../actions/profiles/clubsActions'
import { useDispatch, useSelector } from 'react-redux'


export default function ClientRouter () {
	const navigate = useNavigate()
	const {clientauth} = useAuth()
    const dispatch = useDispatch()

    const {clubs} = useSelector(state=>state.clubs)

	const [selectedClientId, setSelectedClientId] = useState(null)
	const [isCollapsed, setIsCollapsed] = useState(false)

	const toggleSidebar = () => {
		setIsCollapsed(!isCollapsed)
	}

	const updateSessionStorage = (newValues) => {
		const currentData = JSON.parse(sessionStorage.getItem('dataIncrono')) || {}
		const updatedData = { ...currentData, ...newValues }
		sessionStorage.setItem('dataIncrono', JSON.stringify(updatedData))
	}

	const handleClientSelect = (clientId) => {
		setSelectedClientId(clientId)
		updateSessionStorage({ clientId })
	}
	const handleClubSelect = (clubId) => {
		updateSessionStorage({ clubId })
	}

    useEffect(() => {
		const storedData = sessionStorage.getItem('dataIncrono')
		if (storedData) {
			const { clientId } = JSON.parse(storedData)
			if (clientId) setSelectedClientId(clientId)
		}
    }, [])

	useEffect(() => {
		let token = sessionStorage.getItem('jwt')

		if (!token) {
			navigate('/login')
		}
	}, [navigate])

	useEffect(() => {
		if(clientauth){
			dispatch(getClubsByUser(clientauth?.id))
		}
	}, [dispatch, clientauth])

	useEffect(() => {
		if(clientauth){
			dispatch(getClubsByUser(clientauth?.id))
		}
	}, [dispatch, clientauth])

	return (
		<Container fluid className='d-flex p-0'>
			<SidebarClient isCollapsed={isCollapsed} client={clientauth?.client} handleClientSelect={handleClientSelect}/>
			<Container fluid className={`contenedor ${isCollapsed ? 'expanded' : ''}`}>
				<MenuSuperior toggleSidebar={toggleSidebar} isCollapsed={isCollapsed} avatar={clientauth?.avatar} client={clientauth?.client} clubs={clubs}  handleClientSelect={handleClientSelect} handleClubSelect={handleClubSelect}/>				
				<Routes>
					{/* Base */}
					<Route path="/dashboard" element={<Dashboard handleClientSelect={handleClientSelect} handleClubSelect={handleClubSelect}/>}/>

					{/* Configuración */}
					<Route path="/personaldata" element={<Personaldata clientselected={selectedClientId}/>}/>
					<Route path="/socialdata" element={<Socialdata clientselected={selectedClientId}/>}/>
					<Route path="/addressdata" element={<Addressdata clientselected={selectedClientId}/>}/>
					<Route path="/medicaldata" element={<Medicaldata clientselected={selectedClientId}/>}/>
					<Route path="/carddata" element={<Carddata clientselected={selectedClientId}/>}/>
					<Route path="/accountdata" element={<Accountdata clientselected={selectedClientId}/>}/>
					<Route path="/identitydata" element={<Identitydata clientselected={selectedClientId}/>}/>

					{/* Miembros */}
					<Route path="/members" element={<Members handleClientSelect={handleClientSelect}/>}/>
					<Route path="/registermember" element={<RegisterMember/>}/>

					{/* Otros */}
					<Route path="*" element={<NotFoundPage />}/>
				</Routes>
			</Container>			
		</Container>
	)
}