import AxiosControl from '../../context/axios/AxiosControl'
const Axios = AxiosControl()

export const getAreasByClub = (club) => async (dispatch) => {
    dispatch({
        type: 'AREA_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/area/getAreasByClub/'+ club, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'AREA_GETALL_SUCCESS',
                payload: data.areas
            })
        }
    } catch (error) {
        dispatch({
            type: 'AREA_NOT_LOADING'
        })
    }
}

export const getArea = (areaId) => async (dispatch) => {
    dispatch({
        type: 'AREA_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/area/getArea/'+ areaId, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'AREA_GET_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'AREA_NOT_LOADING'
        })
    }
}

export const addArea = (areadata) => async (dispatch) => {
    dispatch({
        type: 'AREA_LOADING'
    })
    try {
        if(areadata.image !== null && areadata.image !== undefined && areadata.image !== '' && typeof areadata.image !== 'string'){
            let formData = new FormData()
            formData.append('image', areadata.image)

            const {data} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/upload/uploadimage', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`,
                    auth: sessionStorage.getItem('jwt')
                }
            })
            areadata.image = data
        }

        const { data, status} = await Axios.post('/api/area/register', areadata, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'AREA_ADD_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'AREA_NOT_LOADING'
        })
    }
}

export const addAreaSchedulesAndBlockedDates = (areadata, id) => async (dispatch) => {
    dispatch({
        type: 'AREA_LOADING'
    })
    try {
        const { data, status} = await Axios.put('/api/area/addScheduleAndBlockedDates/' + id, areadata, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'AREA_ADD_SUCCESS',
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: 'AREA_NOT_LOADING'
        })
    }
}

export const editAreaManagerData = (managerData, id) => async (dispatch) => {
    dispatch({
        type: 'AREA_LOADING'
    })
    try {
        const { data, status} = await Axios.put('/api/area/editAreaManagerData/' + id, managerData, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'AREA_ADD_SUCCESS',
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: 'AREA_NOT_LOADING'
        })
    }
}

export const changeAreaStatus = (id, activeStatus) => async (dispatch) => {
    dispatch({
        type: 'AREA_LOADING'
    })
    try {
        const { data, status} = await Axios.put('/api/area/changeStatus/' + id, {active: activeStatus}, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'AREA_EDIT_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'AREA_NOT_LOADING'
        })
    }
}

export const editArea = (areaData, areaId) => async (dispatch) => {
    dispatch({
        type: 'AREA_LOADING'
    })
    try {
        if(areaData.image !== null && areaData.image !== undefined && areaData.image !== '' && typeof areaData.image !== 'string'){
            let formData = new FormData()
            formData.append('image', areaData.image)

            const {data} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/upload/uploadimage', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`,
                    auth: sessionStorage.getItem('jwt')
                }
            })
            areaData.image = data
        }

        const { data, status} = await Axios.post('/api/area/edit/' + areaId, areaData, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'AREA_ADD_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'AREA_NOT_LOADING'
        })
    }
}

export const removeArea = (areaId) => async (dispatch) => {
    try {
        const { data, status } = await Axios.delete('/api/area/remove/' + areaId, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200 || status === 263) {
            dispatch({
                type: 'AREA_REMOVE_RESULT',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'AREA_NOT_LOADING'
        })
    }
}