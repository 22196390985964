import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Button, Row, Form, Col, Card, CardBody } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { editUserClub } from '../../../actions/profiles/usersActions'
import Swal from 'sweetalert2'
import useAuth from '../../../context/auth/useAuth'
import { getClub } from '../../../actions/profiles/clubsActions'
import { useForm } from 'react-hook-form'
import HeadConfigClub from '../../../components/menus/SubMenuConfigClub'
import { FileNameExtractor } from '../../../components/filenameextractor'
import { Link } from 'react-router-dom'

export default function Documents({clubselected}) {
    const { t } = useTranslation()
	const {clientauth} = useAuth()
    const dispatch = useDispatch()
    const {register, watch, setValue} = useForm()

    const {user, resultuser} = useSelector(state=>state.users)
    const {club} = useSelector(state=>state.clubs)

    const [isUpdating, setIslogging] = useState(false)
    const [formState, setFormState] = useState({})

    const [newDocument, setNewDocument] = useState({})

    useEffect(() => {
        if(clientauth && clubselected){
            dispatch(getClub(clubselected))
        }
    },[dispatch, t, clientauth, clubselected])

    useEffect(() => {
        if(club){
            setFormState({
                documents: club?.documents || [],
            })
            setNewDocument({
                ...newDocument,
                title: '',
            })
        }
    // eslint-disable-next-line
    }, [user, club])

    const handleInputChange = ({target})=>{
        setNewDocument({
            ...newDocument,
            [target.name]: target.value
        })
    }

    const data = {
        name: watch('name'),
        file: watch('file'),
    }

    const deleteDocument = (addressIndex)=>{
        let arrayDocuments = [...formState.documents]
        arrayDocuments.splice(addressIndex, 1)

        setFormState({
            documents: arrayDocuments
        })

        dispatch(editUserClub({...formState, documents: arrayDocuments}, clubselected, null, null, null, null))
        setValue('name', null)
        setValue('file', null)
    }

    const handleEdit = async(e) => {
        e.preventDefault()
        setIslogging(true)

        dispatch(editUserClub(formState, clubselected, null, null, {title: newDocument.title, document: data.file[0]}, null))
        setValue('name', null)
        setValue('file', null)

        setIslogging(false)
    }

    useEffect(() => {
        if(resultuser){
            let newresult = resultuser
            dispatch({
                type: 'USER_RESET_RESULT'
            })
            switch (newresult) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'success',
                        text: t('users.useredited')
                    })
                    setNewDocument({
                        title: '',
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [resultuser])
    

    return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <HeadConfigClub nuevaSeccion="documents"/>
                </Col>
            </Row>
            <Row className="my-3">
                <Col xs={12} lg={7} className='mb-2'>
                    <Card>
                        <CardBody>
                            <h2>{t('club.documents')}</h2>
                            <hr className='mb-4'/>
                            <Form className="formulario" onSubmit={handleEdit}>
                                <Row className="my-3">
                                    <Col xs={12}>
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                className={newDocument?.title ? 'has-content' : ''}
                                                type='text'
                                                name='title' 
                                                value={newDocument?.title ||  ''}
                                                onChange={handleInputChange}
                                                required
                                            />
                                            <Form.Label htmlFor='title'>{t('club.title')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="my-3">
                                    <Col xs={12}>
                                        <input type="file" name="name" className="form-control form-control-solid mb-3 mb-lg-0" placeholder="Documento" {...register(
                                            'file', {
                                                required: t('club.selectfile')
                                            }
                                        )} />
                                    </Col>
                                </Row>
                                <Row className="my-3">
                                    <Col xs={12} lg={6} className='ms-auto'>
                                        <Button variant='primary' type="submit" className="w-100 my-2">
                                        {isUpdating
                                            ?
                                                <>
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </>
                                            :
                                                <>{t('global.add')}</>
                                        }
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
                <Col xs={12} lg={5} className='mb-2'>
                    {formState?.documents && formState?.documents?.length > 0 ? (
                            formState?.documents?.map((document, index) => (
                                <Col key={index} className="mb-2">
                                    <Card>
                                        <Row>
                                            <Col className='d-flex flex-column'>
                                                <Col className='d-flex justify-content-between'>
                                                    <div>
                                                        <h3>{document?.title}</h3>
                                                    </div>
                                                    <i className="fas fa-trash cursor" aria-hidden="true" onClick={() => deleteDocument(index)}></i>
                                                </Col>
                                                <Col>
                                                    <Link to={document?.document} className="link" target="_blank" rel="noopener noreferrer"><small><FileNameExtractor documentimage={document?.document}/></small></Link>
                                                </Col>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            ))
                    ) : (
                        ''
                    )}
                </Col>
            </Row>
        </Container>
    )
}
