import React, { useState } from 'react'
import { Row, Col, Card, Form, Button } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import Select from "react-select"
import SportsComponent from '../../../components/sports'
import towns from '../../../components/towns.json'

export default function SearchClubs(){
    const { t } = useTranslation()
    const {sports} = SportsComponent()    
    const [formState, setFormState] = useState({})
    
    const handleInputChange = ({target})=>{
        setFormState({
            ...formState,
            [target.name]: target.value
        })
    }

    const handleSelectChange = (target, selectType) => {
        
    }

    return (
        <div className='p-0 form-special w-100 border-bottom border-2 border-dark'>
            <Card.Body className='w-75 m-auto'>
                <Row>
                    <Col>
                        <Form.Group className='form-group'>
                            <Form.Control
                                type='text'
                                name='search'
                                onChange={handleInputChange}
                                placeholder={t('marketplace.clubsearch')}
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className='form-group'>
                            <Select
                                className="form-control select has-content"
                                name= "city"
                                options={towns}
                                placeholder={t('marketplace.town')}
                                onChange={(e) => handleSelectChange(e, 'sport')}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className='form-group'>
                            <Select
                                className="form-control select has-content"
                                name= "sport"
                                options={sports}
                                placeholder={t('marketplace.sport')}
                                onChange={(e) => handleSelectChange(e, 'sport')}
                            />
                        </Form.Group>
                    </Col>
                    <Col className='text-center'>
                        <Button variant='secondary' type="submit" className="my-2">
                            {t('marketplace.search')}
                        </Button>
                    </Col>
                </Row>
            </Card.Body>
        </div>
    )
}