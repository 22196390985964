import { useTranslation } from "react-i18next"
import { Container, Row, Col, Button } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import { Fragment } from "react"

export default function EmailSend() {
    const { t } = useTranslation()
    const { email } = useParams()

    return (
        <Fragment>
            <header className="cabecera sticky-top d-flex align-items-center px-4">
                <Link to='/'><img src="/images/logotipo-incrono-cabecera.svg" alt="Logotipo Incrono" /></Link>
            </header>
            <Container fluid className='p-0 m-0'>
                <Row className='p-0 m-0 centro-auth'>   
                    <Col xs={12} className='d-flex align-items-center justify-content-center p-5'>
                        <Container>
                            <Row>
                                <Col className='text-center'>
                                    <h1 className="mb-5">{t('forgot.recoverypassword')}</h1>
                                    {t('forgot.emailsend1')}<br/>
                                    <h4 className="my-4">{email}</h4>
                                    {t('forgot.emailsend2')}<br/>
                                    <Link to="/forgotpassword" className="link">{t('forgot.emailsend3')}</Link>
                                </Col>
                            </Row>
                            <Row className="my-3">
                                <Col className='text-center'>
                                    <Link to="/login">
                                        <Button className="my-2 px-5">
                                            {t('global.return')}
                                        </Button>
                                    </Link>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )    
}
