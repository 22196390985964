import React, { useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import useAuth from '../../context/auth/useAuth'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getClientByUser } from '../../actions/profiles/clientsActions'

export const FooterMarketplace = () => {
    const { t } = useTranslation()
	const {user, logout} = useAuth()
    const dispatch = useDispatch()

    const {client} = useSelector(state=>state.clients)

    return (
        <footer className='escaparate bg-white m-0 p-0 border-top border-2 border-dark'>
            <Container className='my-5'>
                <Row className='p-2'>
                    <Col xs={12} lg={3}>
                        <img src='/images/logotipopie.svg' alt="Logotipo Incrono" /><br/>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </Col>
                    <Col xs={12} lg={3}>
                        <h4>{t('marketplace.footer.interest')}</h4>
                        <ul>
                            <li>¿Qué es incrono?</li>
                            <li>¿A qué esperas para jugar?</li>
                            <li>Acceso a clubes</li>
                            <li>Acceso a demo gratuita</li>
                        </ul>
                    </Col>
                    <Col xs={12} lg={3}>
                        <h4>{t('marketplace.footer.legal')}</h4>
                        <ul>
                            <li>Política de Cookies</li>
                            <li>Aviso legal</li>
                            <li>Política de Privacidad</li>
                            <li>Devoluciones</li>
                        </ul>
                    </Col>
                    <Col xs={12} lg={3}>
                        <h4>{t('marketplace.footer.links')}</h4>
                        <ul>
                            <li>Actividades</li>
                            <li>Clubes</li>
                            <li>Alquilar pistas</li>
                            <li>Eventos</li>
                        </ul>
                    </Col>
                </Row>
            </Container>
            <Container fluid className='m-0 p-5 bg-dark'>
                <Row>
                    <Col></Col>
                </Row>
            </Container>
        </footer>
    )
}
