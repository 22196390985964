import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Card, CardBody } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { getSitesByClub } from '../../../../actions/sites/sitesActions'
import CourtForm from '../../../../components/forms/courts/CourtForm'
import { getZonesBySite } from '../../../../actions/sites/zonesActions'
import { editCourt, getCourt } from '../../../../actions/sites/courtsActions'

export default function EditCourt({clubselected}) {
  	const { t } = useTranslation()
	const dispatch = useDispatch()
    const navigate = useNavigate()

    const {loading: loadingSite, sites} = useSelector(state => state.sites)
    const {loading: loadingZone, zones} = useSelector(state => state.zones)
    const {loading: loadingCourt, result, court} = useSelector(state => state.courts)

    const [courtData, setCourtData] = useState({
        name: '',
        image: null,
        zone: null,
        site: null,
        rentable: true,
        active: true,
    })

    const [siteOptions, setSiteOptions] = useState([])
    const [zoneOptions, setZoneOptions] = useState([])

    const {courtId} = JSON.parse(sessionStorage.getItem("dataIncrono"))

    const handleInputChange = ({target})=>{
        setCourtData({
            ...courtData,
            [target.name]: target.value
        })
    }

    const handleCheckboxChange = ({target}) => {
        setCourtData({
            ...courtData,
            [target.name]: target.checked
        })
    }

    const handleSelectChange = (target, selectType) => {
        if (selectType === 'site') {
            dispatch(getZonesBySite(target.value))
        }
        setCourtData({
            ...courtData,
            [selectType]: target
        })
    }

    const handleImage = (e)=>{
        setCourtData({
            ...courtData,
            image: e.target.files[0]
        })
    }

    const handleAdd = async(e) => {
        e.preventDefault()

        if(courtData.name !== '' || courtData.address === '' || !courtData.province || courtData.town !== ""){
            dispatch(editCourt({...courtData, zone: courtData.zone.value}, courtId))
        }else{
            Swal.fire({
                showConfirmButton: true,
                showDenyButton: false,
                confirmButtonText: t('global.ok'),
                icon: 'warning',
                text: t('courts.formNotFinished')
            })
        }
    }

    useEffect(() => {
        if(result?.message){
            switch (result.message) {
                case 'success':
                    navigate('/club/courts/edit/step2')
                    dispatch({
                        type: 'COURT_RESET_RESULT'
                    })
                    
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [result])

    useEffect(()=>{
        async function setCourtInfo(court) {
            setCourtData({
                name: court.name,
                image: court.image || null,
                zone: {value: court.zone._id, label: court.zone.name},
                site: {value: court.zone.site._id, label: court.zone.site.name},
                rentable: court.rentable || false,
                active: court.active || false,
            })
        }

        if (court) {
            setCourtInfo(court.court)
        }
    //eslint-disable-next-line
    }, [court])

    useEffect(() => {
        if (courtId) {
            dispatch(getCourt(courtId))
        }
    }, [courtId])

    useEffect(() => {
        if (clubselected) {
            dispatch(getSitesByClub(clubselected))
        }
    }, [])

    useEffect(() => {
        setSiteOptions(sites.map(site => {
            return {label: site.name, value: site._id}
        }))
    }, [sites])

    useEffect(() => {
        setZoneOptions(zones.map(zone => {
            return {label: zone.name, value: zone._id}
        }))
    }, [zones])

	return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <h1 className='ms-2 my-0'>{t('sites.sites')}</h1>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <CardBody>
                            <h2 className='fs-18'>{t('courts.editCourt')}</h2>
                            <hr className='mb-4'/>
                            <CourtForm state={courtData} statetype={'edit'} usertype={'club'} isAdding={loadingSite || loadingZone || loadingCourt} submit={handleAdd} inputChange={handleInputChange} checkboxChange={handleCheckboxChange} selectChange={handleSelectChange} handleImage={handleImage} sites={siteOptions} zones={zoneOptions} cancelBtn={<Link to="/club/courts"><Button variant="default" className="w-100 mt-3">{t('global.return')}</Button></Link>}/>
                        </CardBody>
                    </Card>
                </Col>                
            </Row>
        </Container>
	)
}