import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Row, Col,Button, Card, CardBody, Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { CustomDataTable } from '../../../../components/design/Datatables/CustomDataTable'
import useAuth from '../../../../context/auth/useAuth'
import { changeZoneStatus, getZonesByClub, removeZone } from '../../../../actions/sites/zonesActions'
import SitesSubMenu from '../../../../components/menus/SitesSubMenu'
import Swal from 'sweetalert2'

export default function Zones({clubselected, handleZoneSelect}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
	const {clientauth} = useAuth()

    const {zones, result} = useSelector(state=>state.zones)

    useEffect(() => {
        handleZoneSelect(null)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if(clientauth){
            dispatch(getZonesByClub(clubselected))
        }
        // eslint-disable-next-line
    },[dispatch, clientauth])

    useEffect(() => {
        if(result?.message){
            switch (result.message) {
                case 'editedSuccess':
                    dispatch(getZonesByClub(clubselected))
                    dispatch({
                        type: 'ZONE_RESET_RESULT'
                    })
                    break;
                case "deleteZoneWrongRerservations":
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.accept'),
                        icon: 'warning',
                        text: t('zones.deleteZoneWrongRerservations')
                    })
                    dispatch({
                        type: 'ZONE_RESET_RESULT'
                    })
                    break;
                case "deleteCourtWrongRerservations":
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.accept'),
                        icon: 'warning',
                        text: t('zones.deleteCourtWrongRerservations')
                    })
                    dispatch({
                        type: 'ZONE_RESET_RESULT'
                    })
                    break;
                case "deleteAreaWrongRerservations":
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.accept'),
                        icon: 'warning',
                        text: t('zones.deleteAreaWrongRerservations')
                    })
                    dispatch({
                        type: 'ZONE_RESET_RESULT'
                    })
                    break;
                default:
                    break;
            }
        }
        // eslint-disable-next-line
    }, [result, dispatch, t])

    const handleStatusChange = (zoneId, status) => {
        dispatch(changeZoneStatus(zoneId, status))
    }

    const handleRemoveZone = (e, zoneId) => {
        e.preventDefault()

        dispatch(removeZone(zoneId))
    }

	const columnsZones = [
        {
            name: t('zones.name'),
            cell: zone =>
                <Link onClick={() => handleZoneSelect(zone._id)} to={`/club/zones/edit`} className='table-link'>
                    {zone.name}
                </Link>,
            selector: (zone) => zone.name,
            sortable: true,
        },{
			name: t('zones.site'),
			cell: zone =>
				<div>
					{zone.site.name}
				</div>,
			selector: (zone) => zone.site.name,
			sortable: true,
		},{
            name: t('zones.status'),
            center: 'true',
            width: '100px',
            cell: zone =>
                <div>
                    <Form.Check
                        type='switch'
                        checked={zone.active}
                        onChange={({target}) => (handleStatusChange(zone._id, target.checked))}
                    />
                </div>,
            selector: (zone) => zone.active,
            sortable: false,
        },{
			name: t('global.actions'), 
            center: 'true',
            width:'120px',
			cell: zone =>
                <OverlayTrigger
                    placement="bottom"
                    trigger='focus'
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <Tooltip className='menu-puntos text-left'>
                            <Link onClick={() => handleZoneSelect(zone._id)} to={`/club/zones/edit`} className='dropdown-item'>
                                <img src="/images/edit.svg" alt={t('sites.dataTitle')} width={15}></img> {t('global.edit')}
                            </Link>
                            <Link onClick={(e) => handleRemoveZone(e, zone._id)} className='dropdown-item'>						
                                <img src="/images/trash.svg" alt="trash" width={15} /> {t('global.delete')}
					        </Link>                            
                        </Tooltip>
                    }
                    >
                    <Button variant="puntos"><img src='/images/menupuntos.svg' alt='Menu auxiliar'/></Button>
                </OverlayTrigger>,
			sortable: false,
		}
	]

    return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <SitesSubMenu active="zones"/>
                </Col>
            </Row>
            <Row className='my-3'>
                <Col>
                    <Card>
                        <CardBody>
                            <h2>{t('zones.zonesList')}</h2>
                            <hr className='mb-4'/>
                            <div className='buttons d-flex justify-content-end'>
                                <Link to="/club/zones/new" className='btn btn-secondary'>
                                    <i className='fas fa-plus'></i> {t('zones.createZone')}
                                </Link>
                            </div>
                            <div className='my-3'>
                                <Row>
                                    <Col>
                                        {
                                            zones?.length > 0
                                                ?
                                                    <CustomDataTable columns={columnsZones} data={zones} exportable={false} printable={false}/>
                                                :
                                                    <div className='contenedor-vacio text-center'>
                                                        <h4>{t('zones.noZones')}</h4>
                                                        {t('zones.thereIsNoZonesPhrase')}
                                                        <div className='mt-3'>
                                                            <Link to="/club/zones/new" className='btn btn-secondary'>
                                                                {t('zones.createZone')}
                                                            </Link>
                                                        </div>
                                                    </div>
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}