import React, { useEffect } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { getClubPublicByUrl } from '../../actions/profiles/clubsActions'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import MenuMarketplace from '../../components/menus/MenuMarketplace'
import BannerHead from '../../components/Banners/BannerHead'

export default function Club() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { clubUrl } = useParams()

    const { club } = useSelector(state => state.clubs)
    console.log(club)
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        dispatch(getClubPublicByUrl(clubUrl))
    }, [dispatch, clubUrl]) 

    return (
        <>
            <MenuMarketplace section='clubs'/>
            <BannerHead image={club?.bannerMarketplace} title={club?.name}/>
            <Container className='market'>
                <Row className="my-3">
                    <Col xs={12} lg={9}>
                        <Row>
                            <Col>
                                <Card className='border-0'>
                                    <Card.Body>
                                        <div className='d-flex justify-content-between'>
                                            <div className='d-flex align-items-center mb-4' style={{ flexGrow: 1 }}>
                                                <img src={club?.avatar} style={{width:'80px'}} alt={club?.name}/>
                                                <div className='ms-3' style={{ flexGrow: 1 }}>
                                                    <h2 className='border-2 border-bottom border-dark'>{club?.name}</h2>
                                                </div>
                                            </div>
                                            <div className='ps-3'>
                                                <img src="/images/share.svg" alt="Compartir Club" />
                                            </div>
                                        </div>
                                        <div dangerouslySetInnerHTML={{ __html: club?.description }} />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} lg={6} className='my-3'>
                                <Card className='border-0 p-2'>
                                    <Card.Body>
                                        <h2 className='border-2 border-bottom border-dark mb-4'>{t('global.services') }</h2>
                                        <div className='d-flex flex-wrap'>
                                            {club?.services?.map ((service, i) => {
                                                return (
                                                    <div className='item' key={i}>
                                                        <img src={`/images/services/${service}.svg`} alt={service} />
                                                        {t('services.'+service) }
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} lg={6} className='my-3'>
                                <Card className='border-0 p-2'>
                                    <Card.Body>
                                        <h2 className='border-2 border-bottom border-dark mb-4'>{t('global.sports') }</h2>
                                        <div className='d-flex flex-wrap'>
                                            {club?.sports?.map ((sport, i) => {
                                                return (
                                                    <div className='item' key={i}>
                                                        <img src={`/images/sports/iconos/${sport}.svg`} alt={sport} />
                                                        {t('sports.'+sport) }
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card className='border-0 p-2'>
                                    <Card.Body>
                                        <div className='d-flex' style={{ flexGrow: 1 }}>
                                            <div style={{ flexGrow: 1 }}>
                                                <h2 className='border-2 border-bottom border-dark'>{t('global.contact') }</h2>
                                            </div>
                                            <div className='ps-3'>
                                                { club?.facebook
                                                    ?
                                                    <img src="/images/facebook.svg" alt="Facebook" className='mx-1'/>
                                                    : ''
                                                }
                                                { club?.instagram
                                                    ?
                                                    <img src="/images/instagram.svg" alt="Instagram" className='mx-1' />
                                                    : ''
                                                }
                                                { club?.x
                                                    ?
                                                    <img src="/images/twitter.svg" alt="X/Twitter" className='mx-1' />
                                                    : ''
                                                }
                                                { club?.tiktok
                                                    ?
                                                    <img src="/images/tiktok.svg" alt="Tik Tok" className='mx-1' />
                                                    : ''
                                                }
                                                { club?.twitch
                                                    ?
                                                    <img src="/images/twitch.svg" alt="Twitch" className='mx-1' />
                                                    : ''
                                                }
                                                { club?.linkedin
                                                    ?
                                                    <img src="/images/linkedin.svg" alt="Linkedin" className='mx-1' />
                                                    : ''
                                                }
                                            </div>
                                        </div>
                                        <Row>
                                            <Col xs={12} lg={5}>
                                                {club?.phone 
                                                ?
                                                    <>
                                                        <img src="/images/phone.svg" atl="Phone" className='me-2 my-2' />
                                                        {club?.phone}<br/>
                                                    </>
                                                : ''
                                                }
                                                {club?.email 
                                                ?
                                                    <>
                                                        <img src="/images/envelope.svg" atl="Email" className='me-2 my-2' />
                                                        {club?.email}<br/>
                                                    </>
                                                : ''
                                                }
                                                {club?.web 
                                                ?
                                                    <>
                                                        <img src="/images/web.svg" atl="Web" className='me-2 my-2' />
                                                        {club?.web}<br/>
                                                    </>
                                                : ''
                                                }
                                                <img src="/images/club.svg" atl="Phone" className='me-2 my-2' />
                                                {club?.name}                                                    
                                            </Col>
                                            <Col xs={12} lg={7}>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} lg={3}>
                        <Card className='p-1'>
                            <Card.Body>
                                <h3>{t('club.activitiesfeatured') }</h3>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}