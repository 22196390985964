import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Row, Col, Card, OverlayTrigger, Tooltip, Button} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getActivitiesByClub, removeActivity } from '../../../../actions/activities/activitiesActions'
import { CustomDataTable } from '../../../../components/design/Datatables/CustomDataTable'
import useAuth from '../../../../context/auth/useAuth'
import Swal from 'sweetalert2'

export default function Activities({clubselected, handleActivitySelect}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
	const {clientauth} = useAuth()

    const {activities, resultactivity} = useSelector(state=>state.activities)

    useEffect(() => {
        if(clientauth){
            dispatch(getActivitiesByClub(clubselected))
        }
    },[dispatch, t, clientauth, clubselected])

    const handleRemoveActivity = async(e, activityId) => {
        e.preventDefault()

        dispatch(removeActivity(activityId))
    }

	const columnsActivities = [
        {
            name: t('activities.image'),
            width:'120px',
            cell: activity =>
                <Link onClick={() => handleActivitySelect(activity._id)} to={`/club/categories`} className='table-link'>
                    {activity.image ?
                        <img src={activity.image} alt={activity.title} className='imagen'/>
                    : 
                        <div className='imagen'></div>
                    }
                </Link>,
            selector: (activity) => activity.image,
            sortable: false,
        },{
            name: t('activities.title'),
            cell: activity =>
                <Link onClick={() => handleActivitySelect(activity._id)} to={`/club/categories`} className='table-link'>
                    {activity.title}
                </Link>,
            selector: (activity) => activity.title,
            sortable: true,
        },{
			name: t('activities.sport'),
			cell: activity =>
				<Link onClick={() => handleActivitySelect(activity._id)} to={`/club/categories`} className='table-link'>
					{t('sports.'+activity.sport)}
				</Link>,
			selector: (activity) => activity.sport,
			sortable: true,
		},{
			name: t('activities.visibilityType'),
			cell: activity =>
				<div>
					{t('activities.visibilityTypes.'+activity.visibility_type)}
				</div>,
			selector: (activity) => t('activities.visibilityTypes.'+activity.visibility_type),
			sortable: true,
		},{
			name: t('global.actions'), 
            center: 'true',  
            width: '120px',
			cell: activity =>
                <OverlayTrigger
                    placement="bottom"
                    trigger='focus'
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <Tooltip className='menu-puntos text-left'>
                            <Link onClick={() => handleActivitySelect(activity._id)} to={`/club/editactivity`} className='dropdown-item'>
                                <img src="/images/edit.svg" alt="pencil" width={15}></img> {t('global.edit')}
                            </Link>
                            <Link onClick={() => handleActivitySelect(activity._id)} to={`/club/categories`} className='dropdown-item'>
                                <img src="/images/lupa.svg" alt="pencil" width={15}></img> {t('categories.categorylist')}
                            </Link>
                            <Link onClick={(e) => handleRemoveActivity(e, activity._id)} className='dropdown-item'>						
                                <img src="/images/trash.svg" alt="trash" width={15} /> {t('global.delete')}
					        </Link>                            
                        </Tooltip>
                    }
                    >
                    <Button variant="puntos"><img src='/images/menupuntos.svg' alt='Menu auxiliar'/></Button>
                </OverlayTrigger>,
			sortable: false,
		}
	]

    useEffect(() => {
        if(resultactivity){
            let newresult = resultactivity
            dispatch({
                type: 'ACTIVITY_RESET_RESULT'
            })
            switch (newresult) {
                case 'deleteactivitysuccess':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('activities.deleteactivitysuccess')
                    })
                    break;
                case 'deleteactivitywrong':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.ok'),
                        icon: 'success',
                        text: t('activities.deleteactivitywrong')
                    })
                    break;
                case 'deleteactivitynotexist':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.ok'),
                        icon: 'success',
                        text: t('activities.deleteactivitynotexist')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [resultactivity])

    return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <h1 className='ms-2 my-0'>{t('club.activities')}</h1>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <h2>{t('activities.activitylist')}</h2>
                            <hr className='mb-4'/>
                            <div className='buttons d-flex justify-content-end'>
                                <Link to="/club/newactivity" className='btn btn-secondary'>
                                    <i className='fas fa-plus'></i> {t('activities.createnewactivity')}
                                </Link>
                            </div>
                            <div className="my-3">
                                <Row>
                                    <Col>
                                        {activities?.length > 0 ?
                                            <CustomDataTable columns={columnsActivities} data={activities} exportable={false} printable={false} />
                                        :
                                            <div className='contenedor-vacio text-center'>
                                                <h4>{t('activities.thereisnotactivities')}</h4>
                                                {t('activities.thereisnotactivitiesphrase')}
                                                <div className='mt-3'>
                                                    <Link to="/club/newactivity" className='btn btn-secondary'>
                                                        {t('activities.createnewactivity')}
                                                    </Link>
                                                </div>
                                            </div>
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}
