import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function BannerFullWidth() {
    const { t } = useTranslation()    
    
    return (
        <div className='position-relative bg-dark'>
            <Container fluid className='banner'>
                <Row>
                    <Col lg={4}>
                        <img src="/images/montajeapp.png" alt="APP Incrono" className='img-float' style={{width:'550px'}}/>
                    </Col>
                    <Col lg={8} className='py-3'>
                        <Row>
                            <Col lg={8} className='d-flex'>
                                <img src="/images/logoreducido.svg" alt="Logotipo Incrono Reducido"/>
                                <h2 className='display-6 text-white ms-4 mt-1' dangerouslySetInnerHTML={{ __html: t('marketplace.connectreservplay') }} />
                            </Col>
                            <Col className='text-end'>
                                <Link  to="/registerlanding" className="btn btn-secondary mt-3">
                                    {t('signin.register')}
                                    <img src="images/rignt-arrow.svg" alt="Continuar" className="ms-3"/>
                                </Link>
                            </Col>
                        </Row>
                        <Row className='text-white'>
                            <Col>
                                <div className='d-flex mb-3'>
                                    <img src="/images/numbers/one.svg" alt="One" className='me-2' />
                                    {t('marketplace.easyaccess')}
                                </div>
                                <div className='fw-lighter'>{t('marketplace.easyaccessphrase')}</div>
                            </Col>
                            <Col>
                                <div className='d-flex mb-3'>
                                    <img src="/images/numbers/two.svg" alt="Two" className='me-2' />
                                    {t('marketplace.searchandreserve')}
                                </div>
                                <div className='fw-lighter'>{t('marketplace.searchandreservephrase')}</div>
                            </Col>
                            <Col>
                                <div className='d-flex mb-3'>
                                    <img src="/images/numbers/three.svg" alt="Three" className='me-2' />
                                    {t('marketplace.enjoyactivity')}
                                </div>
                                <div className='fw-lighter'>{t('marketplace.enjoyactivityphrase')}</div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}