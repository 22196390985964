import React from "react";
import { Col, Row, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next"


export default function UsersRegistered() {
    const { t } = useTranslation()

    return (
        <Card className='border-0 p-1 card-banner'>
            <Card.Body>            
                <Row>
                    <Col className='d-flex'>
                        <img src="/images/usersregistered.svg" alt="Usuarios registrado" />
                        <div className="ms-4">
                            <h2 className='display-6 mt-1 mb-1 text-secondary'>+12K</h2>
                            <h2 className="text-secondary">{t('marketplace.users')}</h2>
                        </div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}