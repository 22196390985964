import React, { useEffect, useState } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import NotFoundPage from '../pages/error/404'
import {Container} from 'react-bootstrap'
import MenuAdmin from '../components/menus/MenuAdmin'
import SidebarAdmin from '../components/menus/SidebarAdmin'
import Dashboard from '../pages/admin/Dashboard'
import Config from '../pages/admin/Config'
import Activities from '../pages/admin/activities/Activities'
import ActivityAdd from '../pages/admin/activities/ActivityAdd'
import ActivityEdit from '../pages/admin/activities/ActivityEdit'

export default function AdminRouter () {
	const navigate = useNavigate()

	const [selectedActivityId, setSelectedActivityId] = useState(null)
	const [isCollapsed, setIsCollapsed] = useState(false)
	const toggleSidebar = () => {
		setIsCollapsed(!isCollapsed)
	}

    const handleActivitySelect = (activityId) => {
        setSelectedActivityId(activityId)
        sessionStorage.setItem('selectedActivityId', activityId)
    }

    useEffect(() => {
        const storedActivityId = sessionStorage.getItem('selectedActivityId')

        storedActivityId && setSelectedActivityId(storedActivityId)
    }, [])

    useEffect(() => {
		let token = sessionStorage.getItem('jwt')

		if (token === 'undefined') {
			navigate('/login')
		}
    }, [navigate])

	return (
		<Container fluid className='d-flex p-0'>
			<SidebarAdmin isCollapsed={isCollapsed} />
			<Container fluid className={`contenedor ${isCollapsed ? 'expanded' : ''}`}>
				<MenuAdmin toggleSidebar={toggleSidebar} isCollapsed={isCollapsed}/>
				<Routes>
					{/* Base */}
					<Route path="/dashboard" element={<Dashboard/>}/>
					<Route path="/config" element={<Config/>}/>

					{/* Actividades */}
					<Route path="/activities" element={<Activities handleActivitySelect={handleActivitySelect}/>}/>
					<Route path="/newactivity" element={<ActivityAdd/>}/>
					<Route path="/editactivity" element={<ActivityEdit activityselected={selectedActivityId}/>}/>
					
					{/* Otros */}
					<Route path="*" element={<NotFoundPage />}/>
				</Routes>
			</Container>			
		</Container>
	)
}