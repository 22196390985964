import React from 'react'
import {Container, Button, Navbar, Nav, NavDropdown} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import useAuth from '../../context/auth/useAuth'
import { Link } from 'react-router-dom'

export default function MenuAdmin( { toggleSidebar , isCollapsed} ) {
	const { t } = useTranslation()
	const {logout} = useAuth()
  	return (
		<Navbar expand="md" id="menu-superior" className='py-2 bg-white border-bottom border-2 border-dark'>
			<Container fluid>
				<Navbar.Brand href="#">
					<Button variant='default' className='btn-icono' onClick={toggleSidebar}>
						{isCollapsed ? 
							<>
								<img src='/images/flechas.svg'  className='rotate-horizontal' alt='Flecha'/>
							</>
						:
							<>
								<img src='/images/flechas.svg' alt='Flecha'/>
							</>
						}
					</Button>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" className='dropstart'/>
				<Navbar.Collapse id="basic-navbar-nav" >
					<Nav className="ms-auto">
						<NavDropdown title="Perfil" id="basic-nav-dropdown">
							<Link to="/admin/config" className='dropdown-item'>{t('menus.profile')}</Link>
							<NavDropdown.Divider />							
							<Link to="/" className='dropdown-item' onClick={logout}>{t('menus.logout')}</Link>							
						</NavDropdown>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	)
}

