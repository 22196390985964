import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import InscriptionForm from '../../../../components/forms/activities/InscriptionForm'
import { getActivity } from '../../../../actions/activities/activitiesActions'
import { getCategory } from '../../../../actions/activities/categoriesActions'
import { addInscription } from '../../../../actions/activities/inscriptionsActions'

export default function InscriptionAdd({activityselected, categoryselected, handleInscriptionSelect}) { 
  	const { t } = useTranslation()
	const dispatch = useDispatch()
    const navigate = useNavigate()

	const {resultinscription, loadinginscription} = useSelector(state=>state.inscriptions)
	const {activity} = useSelector(state=>state.activities)
	const {category} = useSelector(state=>state.categories)

    const [newInscription, setNewInscription] = useState()

    useEffect(()=>{
        if(activityselected && categoryselected){
            dispatch(getActivity(activityselected))
            dispatch(getCategory(categoryselected))
        }
    //eslint-disable-next-line
    }, [dispatch, activityselected, categoryselected])

    useEffect(()=>{
        if(category){
            setNewInscription({
                category: categoryselected,
                title: '',
                seasonStart: null,
                seasonEnd: null,
                seasonPayments: [],
                registrationStart: null,
                registrationEnd: null,
                registrationPayment: 0,
                assignableGroup: false,
                verify: false,
                federated: false,
                groups: [{
                    name: 'General',
                    places: 0,
                    trainer: '',
                    schedule: {
                        monday: [],
                        tuesday: [],
                        wednesday: [],
                        thursday : [],
                        friday: [],
                        saturday: [],
                        sunday: []
                    }
                }],
                active: false
            })
        }
    //eslint-disable-next-line
    }, [category])

    const handleInputChange = ({target})=>{
        setNewInscription({
            ...newInscription,
            [target.name]: target.value
        })
    }

    const handleCheckboxChange = ({target}) => {
        setNewInscription({
            ...newInscription,
            [target.name]: target.checked
        })
    }

    const handleDaterangeChange = (value, index) => {
        setNewInscription({
            ...newInscription,
            [`${index}Start`]: value[0],
            [`${index}End`]: value[1]
        })
    }

    const handleAdd = async(e) => {
        e.preventDefault()

        dispatch(addInscription(newInscription))
    }

    useEffect(() => {
        if(resultinscription){
            let newresult = resultinscription
            dispatch({
                type: 'INSCRIPTION_RESET_RESULT'
            })
            switch (newresult) {
                case 'success':
                    handleInscriptionSelect(activity?._id)
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('inscriptions.addsuccess')
                    }).then(() => {
                        navigate('/club/inscriptions')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [resultinscription])

	return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <h1 className='ms-2 my-0'>{activity?.title} - {category?.title}</h1>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <h2>{t('inscriptions.createnewinscription')}</h2>
                            <hr className='mb-4'/>
                            <InscriptionForm state={newInscription} statetype={'add'} usertype={'club'} isAdding={loadinginscription} submit={handleAdd} inputChange={handleInputChange} checkboxChange={handleCheckboxChange} daterangeChange={handleDaterangeChange}/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
	)
}