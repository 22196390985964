import React, { Fragment, useEffect, useState } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { jwtDecode } from 'jwt-decode'

export default function Lobby() {
    const { t } = useTranslation()
    const tokensave = sessionStorage.getItem('jwt')

    const {user} = useSelector(state=>state.users)

    const [token, setToken] = useState()
    const [isSuperadmin, setIsSuperadmin] = useState()
    const [isClient, setIsClient] = useState()
    const [isClub, setIsClub] = useState()
    const [club, setClub] = useState(null)
    const [multipleClubs, setMultipleClubs] = useState(false)

    const updateSessionStorage = (newValues) => {
		const currentData = JSON.parse(sessionStorage.getItem('dataIncrono')) || {}
		const updatedData = { ...currentData, ...newValues }
		sessionStorage.setItem('dataIncrono', JSON.stringify(updatedData))
	}

    useEffect(() =>{
        if(tokensave){
            setToken(jwtDecode(tokensave))
        }
    }, [tokensave])

    useEffect(() =>{
        if(token){
            setIsSuperadmin(token.roles.some(role => role.profileType === 'Superadmin'))
            setIsClient(token.roles.some(role => role.profileType === 'Client'))

            let clubRoles = token.roles.filter(role => role.profileType === 'Club')
            setIsClub(clubRoles.length > 0)
            setMultipleClubs(clubRoles.length > 1)
            if(clubRoles.length === 1){
                setClub(clubRoles[0].profile._id)
            }
        }
    }, [token])

    return (
        <Fragment>
            <header className="cabecera sticky-top d-flex align-items-center px-4">
                <Link to='/'><img src="/images/logotipo-incrono-cabecera.svg" alt="Logotipo Incrono" /></Link>
            </header>
            <Container className='p-5'> 
                <Row>
                    <Col>
                        <div className='sd-label-dashboard'>{t('lobby.dashboard.welcome')}</div>
                        <div className="sd-label-userdashboard">{user?.firstname}</div>
                    </Col>
                </Row>
                <Row className='mb-5'>
                    <Col>
                        <h3>{t('lobby.dashboard.subwelcome')}</h3>
                    </Col>
                </Row>
                <Row>
                    {isClient ? 
                        <Col>
                            <Card>
                                <Link to="/client/dashboard" className='btn btn-dashboard'>
                                    <img
                                        src={token.avatar ? token.avatar : "/images/athlete.png"}
                                        alt="Icon athlete"
                                        width={100}
                                    />
                                    <h2 className='noBold'>{t('lobby.dashboard.client')}</h2>
                                </Link>
                            </Card>
                        </Col>
                    : ''}
                    {multipleClubs ? 
                        <Col>
                            <Card>
                                <Link to="/club/lobby" className='btn btn-dashboard'>
                                    <img
                                        src="/images/shield.png"
                                        alt="Icon club"
                                        width={100}
                                    />
                                    <h2 className='noBold'>{t('lobby.dashboard.club')}</h2>
                                </Link>
                            </Card>
                        </Col>
                    : isClub ? 
                        <Col>
                            <Card onClick={() => updateSessionStorage({ clubId: club })}>
                                <Link to="/club/dashboard" className='btn btn-dashboard'>
                                    <img
                                        src="/images/shield.png"
                                        alt="Icon club"
                                        width={100}
                                    />
                                    <h2 className='noBold'>{t('lobby.dashboard.club')}</h2>
                                </Link>
                            </Card>
                        </Col>
                    : ''}
                    {isSuperadmin ? 
                        <Col>
                            <Card>
                                <Link to="/admin/dashboard" className='btn btn-dashboard'>
                                    <img
                                        src="/images/admin.png"
                                        alt="Icon admin"
                                        width={150}
                                    />
                                    <h2 className='noBold'>{t('lobby.dashboard.admin')}</h2>
                                </Link>
                            </Card>
                        </Col>
                    : ''}
                </Row>
            </Container>
        </Fragment>
    )
}