import Login from '../pages/auth/Login'
import Forgot from '../pages/auth/Forgot'
import EmailSend from '../pages/auth/EmailSend'
import ResetPassword from '../pages/auth/ResetPassword'
import ResetPasswordSuccess from '../pages/auth/ResetPasswordSuccess'
import RegisterLanding from '../pages/auth/RegisterLanding'
import RegisterClient from '../pages/auth/RegisterClient'
import RegisterMember from '../pages/auth/RegisterMember'
import RegisterClub from '../pages/auth/RegisterClub'
import Activation from '../pages/auth/Activation'
import Lobby from '../pages/auth/Lobby'

// Mapeo de alias a paths reales
export const aliasMapAuth = {
    "login": "login",
    "forgotpassword": "forgotpassword",
    "emailsend/:email": "emailsend/:email",
    "resetpassword/:id/:tokenresetpassword": "resetpassword/:id/:tokenresetpassword",
    "resetpasswordsuccess": "resetpasswordsuccess",
    "registerlanding": "registerlanding",
    "registerclient": "registerclient",
    "registermember/:clientid/:emailuser": "registermember/:clientid/:emailuser",
    "registerclub": "registerclub",
    "activation/:idUser": "activation/:idUser",
    "lobby": "lobby",
}

// Función auxiliar para obtener el componente basado en el path real
export const getComponentAuth = (path) => {
    switch (path) {
        case "login": return <Login />;
        case "forgotpassword": return <Forgot />;
        case "emailsend/:email": return <EmailSend />;
        case "resetpassword/:id/:tokenresetpassword": return <ResetPassword />;
        case "resetpasswordsuccess": return <ResetPasswordSuccess />;
        case "registerlanding": return <RegisterLanding />;
        case "registerclient": return <RegisterClient />;
        case "registermember/:clientid/:emailuser": return <RegisterMember />;
        case "registerclub": return <RegisterClub />;
        case "activation/:idUser": return <Activation />;
        case "lobby": return <Lobby />;
        default: return <Login />;
    }
}