import React,{useState} from "react";
import { Col, Row, Card, Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function Suscribete() {
    const { t } = useTranslation()
    const backImage = {
        backgroundImage: 'url("/images/fondo-suscribete.jpg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center' 
    }
    const [formState, setFormState] = useState({})
    const [policy, setPolicy] = useState(false)

    const handleInputChange = ({target})=>{
        setFormState({
            ...formState,
            [target.name]: target.value
        })
    }

    const handlePolicy  = (e) => {
        setPolicy(e.target.checked)
    }

    return (
        <Card className='border-0 card-banner' style={backImage} >
            <div className="gradient"></div>
            <Card.Body>            
                <Row>
                    <Col className='d-flex'>
                        <img src="/images/suscribete.svg" alt="Suscribete"/>
                        <h2 className='display-6 text-white ms-4 mt-1'>{t('marketplace.suscribetitle')}</h2>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col xs={8} className="text-white fw-light">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore  consectetur adipiscing elit, sed do eius magna aliqua:
                        <ul className="mt-3">
                            <li>Todas las actividades de tu ciudad</li>
                            <li>Últimas noticias de deportes y salud</li>
                            <li>Los próximos eventos y torneos</li>
                            <li>Consejos prácticos para tus ejercicios</li>
                        </ul>
                    </Col>
                </Row>
                <Row className='text-white'>
                    <Col className='d-flex justify-content-between'>
                        <Form.Group className='form-group especial w-75'>
                            <Form.Control
                                className={formState.email ? 'has-content' : ''}
                                type='text'
                                name='email' 
                                onChange={handleInputChange}
                            />
                            <Form.Label htmlFor='email'>{t('signin.email')}</Form.Label>
                        </Form.Group>
                        <Col className="ps-3">
                            <Button variant='secondary' type="submit" className="w-100 my-2" disabled={!policy}>
                                {t('marketplace.suscribe')}
                                <img src="images/rignt-arrow.svg" alt="Continuar" className="ms-3"/>
                            </Button>
                        </Col>
                    </Col>
                </Row>
                <Row>
                    <Col className='d-flex align-items-start fw-light'>
                        <Form.Check className='float-left pe-3' type='checkbox' name='policy' onChange={handlePolicy} checked={policy || false} />                                
                        <p className="text-white small">
                            {t('users.policy1')}
                            <a href="https://incrono.com/es/politica-cookies" className='link' target='_blank' rel="noreferrer"><u>{t('users.policy2')}</u></a>
                            {t('users.policy3')}
                            <a href="https://incrono.com/es/politica-privacidad" className='link' target='_blank' rel="noreferrer"><u>{t('users.policy4')}</u></a>
                        </p>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}