import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import GroupForm from '../../../../components/forms/activities/GroupForm'
import { getGroup, updateGroup } from '../../../../actions/activities/groupsActions'
import { getInscription } from '../../../../actions/activities/inscriptionsActions'

export default function GroupEdit({inscriptionselected, groupselected}) { 
  	const { t } = useTranslation()
	const dispatch = useDispatch()

	const {inscription} = useSelector(state=>state.inscriptions)
	const {group, resultgroup, loadinggroup} = useSelector(state=>state.groups)

    const [editGroup, setEditGroup] = useState()

    useEffect(()=>{
        if(groupselected){
            dispatch(getInscription(inscriptionselected))
            dispatch(getGroup(groupselected))
        }
    //eslint-disable-next-line
    }, [dispatch, groupselected])

    useEffect(()=>{
        if(groupselected){
            setEditGroup({
                name: group?.name,
                places: group?.places,
                trainer: group?.trainer,
                active: group?.active,
                days: group?.schedule.length > 0 ? group?.schedule : [{weekDays: ['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'], startHour: null, endHour: null}]
            })
        }
    //eslint-disable-next-line
    }, [group])
 
    const handleDayCheck = (target, position) => {
        setEditGroup({
            ...editGroup,
            days: editGroup.days.map((day, index) => {
                if(index === position){
                    if(target.checked){
                        if(target.name !== 'all'){
                            return {
                                ...day,
                                weekDays: [...day.weekDays, target.name]
                            }
                        }else{
                            return {
                                ...day,
                                weekDays: ['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
                            }
                        }
                    }else{
                        if(target.name !== 'all'){
                            return {
                                ...day,
                                weekDays: day.weekDays.filter(dayFilter => (dayFilter !== target.name && dayFilter !== "all"))
                            } 
                        }else{
                            return {
                                ...day,
                                weekDays: []
                            }
                        }
                    }
                }else{
                    return day
                }
            })
        })
    }

    const addSchedule = () => {
        setEditGroup({
            ...editGroup,
            days: [...editGroup.days, {weekDays: ['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'], startHour: null, endHour: null}]
        })
    }

    const removeSchedule = (day, position) => {
        setEditGroup({
            ...editGroup,
            days: editGroup.days.filter((d, i) => i !== position)
        })
    }

    const handleScheduleInputChange = (target, day) => {
        setEditGroup({
            ...editGroup,
            days: editGroup.days.map(d => {
                if (d.weekDays === day) {
                    return {
                        ...d,
                        [target.name]: target.value
                    }
                } else {
                    return d
                }
            })
        })
    }

    const handleInputChange = ({target})=>{
        setEditGroup({
            ...editGroup,
            [target.name]: target.value
        })
    }

    const handleCheckboxChange = ({target}) => {
        setEditGroup({
            ...editGroup,
            [target.name]: target.checked
        })
    }

    const handleAdd = async(e) => {
        e.preventDefault()

        dispatch(updateGroup({...editGroup, inscription: inscriptionselected}, groupselected))
    }

    useEffect(() => {
        if(resultgroup){
            let newresult = resultgroup
            dispatch({
                type: 'GROUP_RESET_RESULT'
            })
            switch (newresult) {
                case 'successeditgroup':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('groups.successeditgroup')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [resultgroup])

	return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <h1 className='ms-2 my-0'>{inscription?.title}</h1>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <h2>{t('groups.editgroup')}</h2>
                            <hr className='mb-4'/>
                            <Row>
                                <Col>
                                    <GroupForm state={editGroup} statetype={'edit'} usertype={'club'} isAdding={loadinggroup} submit={handleAdd} inputChange={handleInputChange} checkboxChange={handleCheckboxChange} addSchedule={addSchedule} removeSchedule={removeSchedule} handleDayCheck={handleDayCheck} handleScheduleInputChange={handleScheduleInputChange}/>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
	)
}