import { Button, Col, Form, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css'
import 'react-calendar/dist/Calendar.css'
import SimpleSchedule from "../../design/Schedule/SimpleSchedule"
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import es from 'react-phone-number-input/locale/es'

export default function SiteFormStep3({statetype, state, isAddingOrEditing, submit, handleDayCheck, addSchedule, removeSchedule, handleScheduleInputChange, handleInputChange, cancelBtn}) {
    const { t } = useTranslation()

    return (
        <Form onSubmit={submit}>
            <Row className="my-3">
                <Col xs={12} className="mb-3">
                    {t('sites.managerDataTitle')}
                    <hr className="fina" />
                </Col>
            </Row>
            <Row className="my-3">
                <Col xs={12} lg={6}>
                    <Form.Group className='form-group'>
                        <Form.Control 
                            className={state?.name ? 'has-content' : ''}
                            name="name" 
                            id="name" 
                            type="text" 
                            onChange={handleInputChange} value={state.name || ''}
                        />
                        <Form.Label htmlFor="name">{t('sites.name')}</Form.Label>
                    </Form.Group>
                </Col>
                <Col xs={12} lg={6}>
                    <Form.Group className='form-group'>
                        <PhoneInput 
                            className="form-control has-content" 
                            name="phoneNumber" 
                            id="phoneNumber" 
                            defaultCountry="ES" 
                            labels={es} 
                            onChange={value => handleInputChange({target: {name: "phoneNumber", value: value}})} value={state.phoneNumber || ''}
                        />
                        <Form.Label htmlFor="phoneNumber">{t('sites.phone')}</Form.Label>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="my-3">
                <Col xs={12} lg={6}>
                    <Form.Group className='form-group'>
                        <Form.Control 
                            className={state?.email ? 'has-content' : ''}
                            name="email" 
                            id="email" 
                            type="text" 
                            onChange={handleInputChange} value={state.email || ''}
                        />
                        <Form.Label htmlFor="email">{t('sites.email')}</Form.Label>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="my-5">
                <Col>
                    <SimpleSchedule title={t('sites.managerScheduleTitle')} value={state.days} removeSchedule={removeSchedule} addSchedule={addSchedule} handleDayCheck={handleDayCheck} onScheduleInputChange={handleScheduleInputChange}/>
                </Col>
            </Row>            
            <Row className="my-3">
                <Col xs={6}>
                    {cancelBtn}
                </Col>
                <Col xs={6}>
                    {isAddingOrEditing
                        ?
                            //TODO:
                            'CARGANDO'
                        :
                            <Button type="submit" variant="primary" className='mt-3 w-100'>
                                {
                                    statetype === 'add'
                                        ?
                                            t('sites.publish')
                                        :
                                            t('global.update')
                                }
                            </Button>
                    }
                </Col>
            </Row>
        </Form>
    )    
}
