import React, { useEffect, useState, createRef } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Button, Row, Form, Col, Card} from 'react-bootstrap'
import Select, { components } from "react-select"
import { useDispatch, useSelector } from 'react-redux'
import { editUserClient, getUser } from '../../../actions/profiles/usersActions'
import { getClient } from '../../../actions/profiles/clientsActions'
import Swal from 'sweetalert2'
import useAuth from '../../../context/auth/useAuth'
import HeadConfig from '../../../components/menus/SubMenuConfig'
import FamiliarComponent from '../../../components/familiar'

import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'

export default function Personaldata({clientselected}) {
    const { t } = useTranslation()
	const {clientauth} = useAuth()
    const dispatch = useDispatch()
	const {familiar} = FamiliarComponent()
    const Input = ({ ...rest }) => <components.Input {...rest} autoComplete={'nope'} />

    const [familiarsAux, setFamiliarsAux] = useState([])
    const fileRef = createRef("<HTMLInputElement>")
    const [biographyEditorState, setBiographyEditorState] = useState(EditorState.createEmpty())
    const [observationsEditorState, setObservationsEditorState] = useState(EditorState.createEmpty())

    const gendertype = [
        {
            "value": '',
            "label": t('users.gendertype.select')
        },
        {
            "value": "Male",
            "label": t('users.gendertype.male')
        },
        {
            "value": "Female",
            "label": t('users.gendertype.female')
        },
        {
            "value": "Other",
            "label": t('users.gendertype.other')
        },
    ]
    const {user, resultuser} = useSelector(state=>state.users)
    const {client} = useSelector(state=>state.clients)

    const [isUpdating, setIslogging] = useState(false)
    const [formState, setFormState] = useState({})
    const [imageAux, setImage] = useState(null)

    const [genderAux, setGenderAux] = useState('')

    useEffect(() => {
        if(clientauth){
            dispatch(getUser(clientauth?.id))
            dispatch(getClient(clientselected))
            setFamiliarsAux(familiar)
        }
    // eslint-disable-next-line
    },[dispatch, t, clientauth, clientselected])

    useEffect(() => {
        if(client){
            if (client?.biography) {
                const blocksFromHtml = htmlToDraft(client.biography)
                const { contentBlocks, entityMap } = blocksFromHtml
                setBiographyEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(contentBlocks, entityMap)))
            }
            if (client?.observations) {
                const blocksFromHtml = htmlToDraft(client.observations)
                const { contentBlocks, entityMap } = blocksFromHtml
                setObservationsEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(contentBlocks, entityMap)))
            }

            let arrayMembersOfMembers = []

            if (user?.members?.length > 0) {
                user?.members?.forEach(async (member, index) => {
                    if(member?.permitUsers?.some(permit => permit.client === clientauth?.client && permit.permit.includes('total'))){
                        let memberdata = await client?.permitUsers?.filter(permit => permit.client === member?._id)

                        arrayMembersOfMembers[index] = {
                            ...member,
                            membertype: memberdata[0]?.membertype || 'nothing',
                            name: member?.firstname + ' ' + member?.lastname
                        }
                    }
                })
            }

            setFormState({
                firstname: client?.firstname || '',
                lastname: client?.lastname || '',
                birthdate: client?.birthdate?.substring(0, 10) || '',
                birthplace: client?.birthplace || '',
                nationality: client?.nationality || '',
                gender: client?.gender || '',
                biography: client?.biography || '',
                phone: client?.phone || '',
                emergencyPhone: client?.emergencyPhone || '',
                verified: client?.verified || false,
                observations: client?.observations || '',
                email: client?.email || '',
                password: '',
                permitUsers: arrayMembersOfMembers,
                familiar: client?.permitUsers?.some(permit => permit.client === clientauth?.client) ? t(client?.permitUsers?.filter(permit => permit.client === clientauth?.client)[0].membertype) : '',
                familiarclient: clientauth?.client,
                tutor1Name: client?.tutor1Name || null,
                tutor1Email: client?.tutor1Email || null,
                tutor1Phone: client?.tutor1Phone || null,
                tutor2Name: client?.tutor2Name || null,
                tutor2Email: client?.tutor2Email || null,
                tutor2Phone: client?.tutor2Phone || null,
            })

            if(client?.gender && client?.gender !== ''){
                if(client?.gender === 'Male' || client?.gender === 'Female'){
                    setGenderAux(client?.gender)
                }else{
                    setGenderAux('Other')
                }
            }

			setImage(client?.avatar)
        }
    // eslint-disable-next-line
    }, [user, client])

    const handleInputChange = ({target})=>{
        setFormState({
            ...formState,
            [target.name]: target.value
        })
    }

    const handleDateChange = ({target}) => {
        let currentDate = new Date()
        let date18YearsAgo = new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate())

        if (new Date(target.value) > date18YearsAgo) {
            setFormState({
                ...formState,
                tutor1Name: formState.tutor1Name ? formState.tutor1Name : client?.tutor1Name || '',
                tutor1Email: formState.tutor1Email ? formState.tutor1Email : client?.tutor1Email || '',
                tutor1Phone: formState.tutor1Phone ? formState.tutor1Phone : client?.tutor1Phone || '',
                tutor2Name: formState.tutor2Name ? formState.tutor2Name : client?.tutor2Name || '',
                tutor2Email: formState.tutor2Email ? formState.tutor2Email : client?.tutor2Email || '',
                tutor2Phone: formState.tutor2Phone ? formState.tutor2Phone : client?.tutor2Phone || '',
                [target.name]: target.value
            })
        } else {
            setFormState({
                ...formState,
                tutor1Name: null,
                tutor1Email: null,
                tutor1Phone: null,
                tutor2Name: null,
                tutor2Email: null,
                tutor2Phone: null,
                [target.name]: target.value
            })
        }
    }

    const handleCheckboxChange = ({target}) => {
        setFormState({
            ...formState,
            [target.name]: target.checked
        })
    }

    const handleGenderChange = (target) => {
        setGenderAux(target.value)

        setFormState({
            ...formState,
            gender: target.value
        })
    }

    const handleOtherGenderChange = ({target}) => {
        setFormState({
            ...formState,
            gender: target.value
        })
    }

	const changeImage = (e) => {
		setImage(e.target.files[0])
	}

    const handleBiographyEditorStateChange = (biographyEditorState) => {
        setBiographyEditorState(biographyEditorState)

        setFormState({
            ...formState,
            biography: draftToHtml(convertToRaw(biographyEditorState.getCurrentContent()))
        })
    }

    const handleObservationsEditorStateChange = (observationsEditorState) => {
        setObservationsEditorState(observationsEditorState)

        setFormState({
            ...formState,
            observations: draftToHtml(convertToRaw(observationsEditorState.getCurrentContent()))
        })
    }

    const handleFamiliarChange = (target) => {
        setFormState({
            ...formState,
            familiar: target.value
        })
    }

    const handleSelectMemberChange = (target, index) => {
        let arrayMembersOfMembers = formState?.permitUsers
        arrayMembersOfMembers[index] = {
            ...formState?.permitUsers[index],
            membertype: target.value
        }

        setFormState({
            ...formState,
            permitUsers: arrayMembersOfMembers
        })
    }

    const handleEdit = async(e) => {
        e.preventDefault()
        setIslogging(true)

        dispatch(editUserClient({...formState, gender: formState.gender, clienttoupdate: clientselected}, imageAux, null))

        setIslogging(false)
    }

    useEffect(() => {
        if(resultuser){
            let newresult = resultuser
            dispatch({
                type: 'USER_RESET_RESULT'
            })
            switch (newresult) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'success',
                        text: t('users.useredited')
                    })
                    break;
                case 'email':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'warning',
                        text: t('users.emailduplicate')
                    })
                    break;
                case 'username':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'warning',
                        text: t('users.usernameduplicate')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [resultuser])

    return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <HeadConfig nuevaSeccion="personal" type={clientauth?.client === clientselected ? 'client' : 'member'}/>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <h2>{t('users.editprofile')}</h2>
                            <hr className='mb-4'/>
                            <Form className="formulario" onSubmit={handleEdit}>
                                <Row className="mt-3 mb-4">
                                    <Col xs='12' className='d-flex align-items-center'>
                                        <div className='avatar me-2'>
                                            {imageAux
                                                ?
                                                    <img className="img-profile" alt="Preview" width={100} height={100} src={typeof imageAux === 'string' ? imageAux : URL.createObjectURL(imageAux)} />
                                                :
                                                    formState.avatar
                                                        ?
                                                            <img className="img-profile" alt="Preview" src={typeof formState.avatar === 'string' ? formState.avatar : URL.createObjectURL(formState.avatar)} />
                                                        :
                                                            <div className="img-profile"></div>
                                            }
                                        
                                            <input 
                                                className='custom-file-input' 
                                                style={{ display: 'none' }}
                                                ref={fileRef}
                                                type="file" 
                                                name="myImage" 
                                                onChange={changeImage} 
                                            />
                                        </div>
                                        <div className="editaravatar" onClick={() => fileRef.current?.click()}>{t('users.editavatar')}</div>
                                    </Col>
                                </Row>
                            <Row className="my-3">
                                <Col xs={12} lg={6}>
                                    <Form.Group className='form-group'>
                                            <Form.Control
                                                className={formState.firstname ? 'has-content' : ''}
                                                type='text'
                                                name='firstname' 
                                                value={formState.firstname ||  ''}
                                                onChange={handleInputChange}
                                                required
                                            />
                                            <Form.Label htmlFor='firstname'>{t('users.firstname')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} lg={6}>  
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                className={formState.lastname ? 'has-content' : ''}
                                                type='text'
                                                name='lastname' 
                                                value={formState.lastname ||  ''}
                                                onChange={handleInputChange}
                                                required
                                            />
                                            <Form.Label htmlFor='lastname'>{t('users.lastname')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="my-3">
                                    <Col xs={12} lg={6}>
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                className="has-content"
                                                type='date'
                                                name='birthdate' 
                                                value={formState.birthdate ||  ''}
                                                onChange={handleDateChange}
                                            />
                                            <Form.Label htmlFor='birthdate'>{t('users.birthdate')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                className={formState.birthplace ? 'has-content' : ''}
                                                type='text'
                                                name='birthplace' 
                                                value={formState.birthplace ||  ''}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Label htmlFor='birthplace'>{t('users.birthplace')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {
                                    formState.tutor1Name !== null &&
                                    <>
                                        <Row className='my-3'>
                                            <Col xs={12} lg={6}>
                                                <Form.Label htmlFor='tutor1Name'>{t('users.tutor1Name')}</Form.Label>
                                                <Form.Control
                                                    className="login"
                                                    type='text'
                                                    name='tutor1Name' 
                                                    value={formState.tutor1Name ||  ''}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </Col>
                                            <Col xs={12} lg={6}>
                                                <Form.Label htmlFor='tutor1Phone'>{t('users.tutor1Phone')}</Form.Label>
                                                <Form.Control
                                                    className="login"
                                                    type='text'
                                                    name='tutor1Phone' 
                                                    value={formState.tutor1Phone ||  ''}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                        <Row className='my-3'>
                                            <Col xs={12} lg={6}>
                                                <Form.Label htmlFor='tutor1Email'>{t('users.tutor1Email')}</Form.Label>
                                                <Form.Control
                                                    className="login"
                                                    type='text'
                                                    name='tutor1Email' 
                                                    value={formState.tutor1Email ||  ''}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </Col>
                                            <Col xs={12} lg={6}>
                                                <Form.Label htmlFor='tutor2Name'>{t('users.tutor2Name')}</Form.Label>
                                                <Form.Control
                                                    className="login"
                                                    type='text'
                                                    name='tutor2Name' 
                                                    value={formState.tutor2Name ||  ''}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                        <Row className='my-3'>
                                            <Col xs={12} lg={6}>
                                                <Form.Label htmlFor='tutor2Phone'>{t('users.tutor2Phone')}</Form.Label>
                                                <Form.Control
                                                    className="login"
                                                    type='text'
                                                    name='tutor2Phone' 
                                                    value={formState.tutor2Phone ||  ''}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </Col>
                                            <Col xs={12} lg={6}>
                                                <Form.Label htmlFor='tutor2Email'>{t('users.tutor2Email')}</Form.Label>
                                                <Form.Control
                                                    className="login"
                                                    type='text'
                                                    name='tutor2Email' 
                                                    value={formState.tutor2Email ||  ''}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                    </>
                                }
                                <Row className="my-3">
                                    <Col xs={12} lg={6}>
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                className={formState.nationality ? 'has-content' : ''}
                                                type='text'
                                                name='nationality' 
                                                value={formState.nationality ||  ''}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Label htmlFor='nationality'>{t('users.nationality')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className='form-group'>
                                            <Row className='d-flex'>
                                                <Col>
                                                    <Form.Group className='form-group'>
                                                        <Select
                                                            className="form-control select has-content"
                                                            options={gendertype}
                                                            name='gender'
                                                            value={genderAux === 'Male' || genderAux === 'Female' || genderAux === 'Other' ? {value: genderAux, label: t('users.gendertype.'+genderAux)} : {value: "null", label: t('users.gendertype.select')}}
                                                            onChange={handleGenderChange}
                                                        />
                                                        <Form.Label htmlFor='gender'>{t('users.gender')}</Form.Label>
                                                    </Form.Group>
                                                </Col>
                                                {genderAux !== 'Male' && genderAux !== 'Female' && genderAux !== '' && (
                                                    <Col>
                                                        <Form.Group className='form-group'>
                                                            <Form.Control
                                                                className={genderAux ? 'has-content' : ''}
                                                                type="text"
                                                                name='genderidentify'
                                                                value={formState.gender}
                                                                onChange={handleOtherGenderChange}
                                                                required
                                                            />
                                                            <Form.Label htmlFor='genderidentify'>{t('users.genderidentify')}</Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                )}
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="my-3">
                                    <Col xs={12} lg={6}>
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                className={formState.phone ? 'has-content' : ''}
                                                type='text'
                                                name='phone' 
                                                value={formState.phone ||  ''}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Label htmlFor='phone'>{t('users.phone')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                className={formState.emergencyPhone ? 'has-content' : ''}
                                                type='text'
                                                name='emergencyPhone' 
                                                value={formState.emergencyPhone ||  ''}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Label htmlFor='emergencyPhone'>{t('users.emergencyphone')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="my-3">
                                    <Col xs={12} lg={6}>  
                                        <Form.Label htmlFor='biography'>{t('users.biography')}</Form.Label>
                                        <Editor
                                            toolbar={{
                                                options: ['inline', 'list', 'textAlign', 'link'],
                                                inline: { options: ['bold', 'italic', 'underline'] },
                                                list: { options: ['unordered', 'ordered'] },
                                                textAlign: { options: ['left', 'center', 'right'] },
                                                link: { options: ['link', 'unlink'] }
                                            }}
                                            editorState={biographyEditorState}
                                            onEditorStateChange={handleBiographyEditorStateChange}
                                        />
                                    </Col>
                                    <Col>  
                                        <Form.Label htmlFor='observations'>{t('users.observations')}</Form.Label>
                                        <Editor
                                            className='textarea'
                                            toolbar={{
                                                options: ['inline', 'list', 'textAlign', 'link'],
                                                inline: { options: ['bold', 'italic', 'underline'] },
                                                list: { options: ['unordered', 'ordered'] },
                                                textAlign: { options: ['left', 'center', 'right'] },
                                                link: { options: ['link', 'unlink'] }
                                            }}
                                            rows={6}
                                            editorState={observationsEditorState}
                                            onEditorStateChange={handleObservationsEditorStateChange}
                                        />
                                    </Col>
                                </Row>
                                <Row className="my-3">
                                    <Col>
                                        <Form.Group className="d-flex">
                                            <Form.Label htmlFor='verified'>{t('users.verifieddata')}</Form.Label>
                                            <Form.Check
                                                className="ms-3"
                                                type="switch"
                                                name="verified"
                                                id="verified"
                                                onChange={handleCheckboxChange}
                                                checked={formState?.verified || false}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {clientauth?.client !== clientselected ?
                                    <Row className="my-3">
                                        <Col xs={12} lg={6}>
                                            <Form.Group className='form-group'>
                                                <Select
                                                    components={{ Input }} 
                                                    className="form-control select has-content"
                                                    type='text'
                                                    name='familiar'
                                                    inputProps={{ autoComplete: 'nope' }}
                                                    options={[{ value: '', label: t('users.membertypes.nothing')}, ...familiarsAux]}
                                                    value={{ value: formState?.familiar || '', label: t('users.membertypes.' + (formState?.familiar || 'nothing')) }}
                                                    onChange={(e) => handleFamiliarChange(e)}
                                                    required
                                                />
                                                <Form.Label htmlFor='familiar'>{t('users.membertype')}</Form.Label>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                :
                                    ''
                                }
                                {formState?.permitUsers?.length > 0 && clientselected !== clientauth.client ?
                                    formState?.permitUsers?.map((client, index) => (
                                        client._id !== clientselected ?
                                            <Row key={index} className="my-3">
                                                <Col xs={2}>
                                                    {client.name}
                                                </Col>
                                                <Col xs={10}>
                                                    <Form.Group className='form-group'>
                                                        <Select
                                                            components={{ Input }} 
                                                            className="form-control select has-content"
                                                            type='text'
                                                            name='membertype'
                                                            inputProps={{ autoComplete: 'nope' }}
                                                            options={[{ value: '', label: t('users.membertypes.nothing')}, ...familiarsAux]}
                                                            value={{ value: formState?.permitUsers[index]?.membertype || '', label: t('users.membertypes.' + (formState?.permitUsers[index]?.membertype || 'nothing')) }}
                                                            onChange={(e) => handleSelectMemberChange(e, index)}
                                                            required
                                                        />
                                                        <Form.Label htmlFor='membertype'>{t('users.membertype')}</Form.Label>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        :
                                            ''
                                    ))
                                :
                                    ''
                                }
                                <Row className="mt-5">
                                    <Col xs={12} lg={6} className='ms-auto'>
                                        <Button variant='primary' type="submit" className="w-100 my-2">
                                        {isUpdating
                                            ?
                                                <>
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </>
                                            :
                                                <>{t('global.update')}</>
                                        }
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}
