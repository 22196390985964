import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Card} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import SportsComponent from '../../../../components/sports'
import ServicesComponent from '../../../../components/services'
import { getClub } from '../../../../actions/profiles/clubsActions'
import SiteForm from '../../../../components/forms/sites/SiteForm'
import provinces from "../../../../components/provinces.json"
import { editSite, getSite } from '../../../../actions/sites/sitesActions'

export default function EditSite({clubselected, handleSiteSelect}) {
  	const { t } = useTranslation()
	const dispatch = useDispatch()
    const navigate = useNavigate()

	const {club} = useSelector(state=>state.clubs)

    const {result, loading, site} = useSelector(state => state.sites)

	const {sports} = SportsComponent()
    const {services} = ServicesComponent()

    const [sportsAux, setSportsAux] = useState([])
    const [servicesAux, setServicesAux] = useState([])

    const [siteData, setSiteData] = useState()

    const {siteId} = JSON.parse(sessionStorage.getItem("dataIncrono"))

    useEffect(()=>{
        if(clubselected){
            dispatch(getClub(clubselected))
        }
    //eslint-disable-next-line
    }, [dispatch, clubselected])

    useEffect(()=>{
        async function setSiteInfo(site) {
            let sportsSaved = []
            let servicesSaved = []

            for await (const sport of site.sports) {
                sportsSaved.push(sports.find(s => s.value === sport))
            }

            for (const service of site.services) {
                servicesSaved.push(services.find(s => s.value === service))
            }
            
            setSiteData({
                name: site.name,
                sports: sportsSaved,
                image: site.image || null,
                club: {value: club._id, label: club.name},
                province: provinces.find(p => p.value === site.province) || null,
                town: site.town,
                address: site.address,
                services: servicesSaved,
                active: site.active
            })
        }

        if (site && club) {
            setSiteInfo(site.site)
            setSportsAux(sports)
            setServicesAux(services)
        }
    //eslint-disable-next-line
    }, [club, site])

    useEffect(() => {
        if (siteId) {
            dispatch(getSite(siteId))
        }
    }, [siteId, dispatch])

    const handleInputChange = ({target})=>{
        setSiteData({
            ...siteData,
            [target.name]: target.value
        })
    }

    const handleCheckboxChange = ({target}) => {
        setSiteData({
            ...siteData,
            [target.name]: target.checked
        })
    }

    const handleSelectChange = (target, selectType) => {
        setSiteData({
            ...siteData,
            [selectType]: target
        })
    }

    const handleImage = (e)=>{
        setSiteData({
            ...siteData,
            image: e.target.files[0]
        })
    }

    const handleEdit = async(e) => {
        e.preventDefault()

        if(siteData.name !== '' || siteData.address === '' || !siteData.province || siteData.town !== ""){
            let sportsToSend = siteData.sports?.map(sport => {
                return sport.value
            }) || []

            let servicesToSend = siteData.services?.map(service => {
                return service.value
            })

            dispatch(editSite({...siteData, club: clubselected, province: siteData.province.value, sports: sportsToSend, services: servicesToSend}, siteId))   
        }else{
            Swal.fire({
                showConfirmButton: true,
                showDenyButton: false,
                confirmButtonText: t('global.ok'),
                icon: 'warning',
                text: t('sites.formNotFinished')
            })
        }
    }

    useEffect(() => {
        if(result?.message){
            switch (result.message) {
                case 'success':
                    navigate('/club/sites')
                    dispatch({
                        type: 'SITE_RESET_RESULT'
                    })
                    
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [result])

	return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <h1 className='ms-2 my-0'>{t('sites.sites')}</h1>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <h2 className='fs-18'>{t('sites.editSite')}</h2>
                            <hr className='mb-4'/>
                            <SiteForm state={siteData} statetype={'edit'} usertype={'club'} provinces={provinces} isAdding={loading} services={servicesAux} sports={sportsAux} submit={handleEdit} inputChange={handleInputChange} checkboxChange={handleCheckboxChange} selectChange={handleSelectChange} handleImage={handleImage} cancelBtn={<Link to="/club/sites"><Button variant="default" className="w-100 mt-3">{t('global.cancel')}</Button></Link>}/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
	)
}