import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Button, Row, Form, Col, Card, CardBody } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { editUserClub } from '../../../actions/profiles/usersActions'
import Swal from 'sweetalert2'
import useAuth from '../../../context/auth/useAuth'
import { getClub } from '../../../actions/profiles/clubsActions'
import { useForm } from 'react-hook-form'
import HeadConfigClub from '../../../components/menus/SubMenuConfigClub'

export default function Paycometdata({ clubselected }) {
    const { t } = useTranslation()
    const { clientauth } = useAuth()
    const dispatch = useDispatch()
    const { register, reset, getValues } = useForm()

    const { user, resultuser } = useSelector(state => state.users)
    const { club } = useSelector(state => state.clubs)

    const [isUpdating, setIsUpdating] = useState(false)

    const [paycomet, setPaycomet] = useState({})

    useEffect(() => {
        if (clientauth && clubselected) {
            dispatch(getClub(clubselected))
        }
    }, [dispatch, t, clientauth, clubselected])

    useEffect(() => {
        if (club) {
            setPaycomet({
                clubName: club?.paycomet?.clubName || '',
                sepa: club?.paycomet?.sepa || '',
                iban: club?.paycomet?.iban || '',
                dniFront: club?.paycomet?.dniFront || '',
                dniFrontVerified: club?.paycomet?.dniFrontVerified || false,
                dniBack: club?.paycomet?.dniBack || '',
                dniBackVerified: club?.paycomet?.dniBackVerified || false,
                ownershipCertificate: club?.paycomet?.ownershipCertificate || '',
                ownershipCertificateVerified: club?.paycomet?.ownershipCertificateVerified || false,
                statutesCompany: club?.paycomet?.statutesCompany || '',
                statutesCompanyVerified: club?.paycomet?.statutesCompanyVerified || false,
                cif: club?.paycomet?.cif || '',
                cifVerified: club?.paycomet?.cifVerified || false,
                paymentUptodate: club?.paycomet?.paymentUptodate || '',
                paymentUptodateVerified: club?.paycomet?.paymentUptodateVerified || false,
                invoiceService: club?.paycomet?.invoiceService || '',
                invoiceServiceVerified: club?.paycomet?.invoiceServiceVerified || false,
                corporationTax: club?.paycomet?.corporationTax || '',
                corporationTaxVerified: club?.paycomet?.corporationTaxVerified || false,
                blocked: club?.paycomet?.blocked || false
            })
        }
    // eslint-disable-next-line
    }, [user, club])

    const handleEdit = async (e) => {
        e.preventDefault()

        setIsUpdating(true)

        const data = getValues()

        // Despachamos la acción con el FormData
        dispatch(editUserClub({paycomet: paycomet}, clubselected, null, null, null, data))

        setIsUpdating(false)
    }

    useEffect(() => {
        if (resultuser) {
            let newresult = resultuser
            dispatch({
                type: 'USER_RESET_RESULT'
            })
            switch (newresult) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'success',
                        text: t('users.useredited')
                    })
                    dispatch(getClub(clubselected))
                    reset()
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [resultuser])

    return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <HeadConfigClub nuevaSeccion="paycometdata" />
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <CardBody>
                            <h2>{t('club.documents')}</h2>
                            <hr className='mb-4' />
                            <Form className="formulario" onSubmit={handleEdit}>
                                <Row className="my-3">
                                    <Col xs={12} lg={6}>
                                        {paycomet?.dniFrontVerified ? <i className="fa-regular fa-circle-check"></i> : <i className="fa-regular fa-circle-xmark"></i>}
                                        <Form.Label className="ms-1">{t('club.dniFront')}</Form.Label>
                                        {paycomet?.dniFront ?
                                            <a href={paycomet?.dniFront} target="_blank" rel="noopener noreferrer" className="btn btn-primary float-end">
                                                {t('club.viewdocument')}
                                            </a>
                                        :
                                            <Form.Label className="float-end">{t('club.nodocument')}</Form.Label>
                                        }
                                        <input
                                            type="file"
                                            name="dniFront"
                                            className="form-control form-control-solid mb-3 mb-lg-0"
                                            placeholder="Documento"
                                            {...register('dniFront', { required: t('club.selectfile') })}
                                        />
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        {paycomet?.dniBackVerified ? <i className="fa-regular fa-circle-check"></i> : <i className="fa-regular fa-circle-xmark"></i>}
                                        <Form.Label className="ms-1">{t('club.dniBack')}</Form.Label>
                                        {paycomet?.dniBack ?
                                            <a href={paycomet?.dniBack} target="_blank" rel="noopener noreferrer" className="btn btn-primary float-end">
                                                {t('club.viewdocument')}
                                            </a>
                                        :
                                            <Form.Label className="float-end">{t('club.nodocument')}</Form.Label>
                                        }
                                        <input
                                            type="file"
                                            name="dniBack"
                                            className="form-control form-control-solid mb-3 mb-lg-0"
                                            placeholder="Documento"
                                            {...register('dniBack', { required: t('club.selectfile') })}
                                        />
                                    </Col>
                                </Row>
                                <Row className="my-3">
                                    <Col xs={12} lg={6}>
                                        {paycomet?.ownershipCertificateVerified ? <i className="fa-regular fa-circle-check"></i> : <i className="fa-regular fa-circle-xmark"></i>}
                                        <Form.Label className="ms-1">{t('club.ownershipCertificate')}</Form.Label>
                                        {paycomet?.ownershipCertificate ?
                                            <a href={paycomet?.ownershipCertificate} target="_blank" rel="noopener noreferrer" className="btn btn-primary float-end">
                                                {t('club.viewdocument')}
                                            </a>
                                        :
                                            <Form.Label className="float-end">{t('club.nodocument')}</Form.Label>
                                        }
                                        <input
                                            type="file"
                                            name="ownershipCertificate"
                                            className="form-control form-control-solid mb-3 mb-lg-0"
                                            placeholder="Documento"
                                            {...register('ownershipCertificate', { required: t('club.selectfile') })}
                                        />
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        {paycomet?.statutesCompanyVerified ? <i className="fa-regular fa-circle-check"></i> : <i className="fa-regular fa-circle-xmark"></i>}
                                        <Form.Label className="ms-1">{t('club.statutesCompany')}</Form.Label>
                                        {paycomet?.statutesCompany ?
                                            <a href={paycomet?.statutesCompany} target="_blank" rel="noopener noreferrer" className="btn btn-primary float-end">
                                                {t('club.viewdocument')}
                                            </a>
                                        :
                                            <Form.Label className="float-end">{t('club.nodocument')}</Form.Label>
                                        }
                                        <input
                                            type="file"
                                            name="statutesCompany"
                                            className="form-control form-control-solid mb-3 mb-lg-0"
                                            placeholder="Documento"
                                            {...register('statutesCompany', { required: t('club.selectfile') })}
                                        />
                                    </Col>
                                </Row>
                                <Row className="my-3">
                                    <Col xs={12} lg={6}>
                                        {paycomet?.paymentUptodateVerified ? <i className="fa-regular fa-circle-check"></i> : <i className="fa-regular fa-circle-xmark"></i>}
                                        <Form.Label className="ms-1">{t('club.paymentUptodate')}</Form.Label>
                                        {paycomet?.paymentUptodate ?
                                            <a href={paycomet?.paymentUptodate} target="_blank" rel="noopener noreferrer" className="btn btn-primary float-end">
                                                {t('club.viewdocument')}
                                            </a>
                                        :
                                            <Form.Label className="float-end">{t('club.nodocument')}</Form.Label>
                                        }
                                        <input
                                            type="file"
                                            name="paymentUptodate"
                                            className="form-control form-control-solid mb-3 mb-lg-0"
                                            placeholder="Documento"
                                            {...register('paymentUptodate', { required: t('club.selectfile') })}
                                        />
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        {paycomet?.invoiceServiceVerified ? <i className="fa-regular fa-circle-check"></i> : <i className="fa-regular fa-circle-xmark"></i>}
                                        <Form.Label className="ms-1">{t('club.invoiceService')}</Form.Label>
                                        {paycomet?.invoiceService ?
                                            <a href={paycomet?.invoiceService} target="_blank" rel="noopener noreferrer" className="btn btn-primary float-end">
                                                {t('club.viewdocument')}
                                            </a>
                                        :
                                            <Form.Label className="float-end">{t('club.nodocument')}</Form.Label>
                                        }
                                        <input
                                            type="file"
                                            name="invoiceService"
                                            className="form-control form-control-solid mb-3 mb-lg-0"
                                            placeholder="Documento"
                                            {...register('invoiceService', { required: t('club.selectfile') })}
                                        />
                                    </Col>
                                </Row>
                                <Row className="my-3">
                                    <Col xs={12} lg={6}>
                                        {paycomet?.corporationTaxVerified ? <i className="fa-regular fa-circle-check"></i> : <i className="fa-regular fa-circle-xmark"></i>}
                                        <Form.Label className="ms-1">{t('club.corporationTax')}</Form.Label>
                                        {paycomet?.corporationTax ?
                                            <a href={paycomet?.corporationTax} target="_blank" rel="noopener noreferrer" className="btn btn-primary float-end">
                                                {t('club.viewdocument')}
                                            </a>
                                        :
                                            <Form.Label className="float-end">{t('club.nodocument')}</Form.Label>
                                        }
                                        <input
                                            type="file"
                                            name="corporationTax"
                                            className="form-control form-control-solid mb-3 mb-lg-0"
                                            placeholder="Documento"
                                            {...register('corporationTax', { required: t('club.selectfile') })}
                                        />
                                    </Col>
                                    <Col xs={12} lg={6} className='ms-auto'>
                                        <Button variant='primary' type="submit" className="w-100 my-2" disabled={paycomet?.blocked}>
                                            {isUpdating
                                                ? <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                : <>{t('global.update')}</>
                                            }
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}