import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom"
import AuthProvider from './context/auth/AuthProvider'
import PrivateAdminRouter from './routers/PrivateAdminRouter'
import AdminRouter from './routers/AdminRouter'
import PrivateClientRouter from './routers/PrivateClientRouter'
import ClientRouter from './routers/ClientRouter'
import PrivateClubRouter from './routers/PrivateClubRouter'
import ClubRouter from './routers/ClubRouter'
import PublicRouter from './routers/PublicRouter'

export default function App() {
	return (
		<>
			<BrowserRouter>
				<AuthProvider>
					<Routes>
						<Route path="/admin/*" element={<PrivateAdminRouter><AdminRouter /></PrivateAdminRouter>} />
						<Route path="/client/*" element={<PrivateClientRouter><ClientRouter /></PrivateClientRouter>} />
						<Route path="/club/*" element={<PrivateClubRouter><ClubRouter /></PrivateClubRouter>} />
						<Route path="/*" element={<PublicRouter />}/>
					</Routes>
				</AuthProvider>
			</BrowserRouter>
		</>
	)
}
