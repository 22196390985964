import React, { useEffect } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import useAuth from '../../../context/auth/useAuth'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '../../../actions/profiles/usersActions'
import { getActivateDeactivate } from '../../../actions/profiles/clientsActions'
import { CustomDataTable } from '../../../components/design/Datatables/CustomDataTable'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'

export default function Members({handleClientSelect}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
	const {clientauth} = useAuth()

    const {user} = useSelector(state=>state.users)
    const {resultclient} = useSelector(state=>state.clients)

    useEffect(() => {
        if(clientauth){
            dispatch(getUser(clientauth?.id))
        }
    },[dispatch, t, clientauth])

    const activatePermitUser = async(e, member) => {
        e.preventDefault()

        dispatch(getActivateDeactivate(member, clientauth?.client))
    }

    useEffect(() => {
        if(resultclient){
            let newresult = resultclient
            dispatch({
                type: 'CLIENT_RESET_RESULT'
            })
            switch (newresult) {
                case 'successactivate':
                    dispatch(getUser(clientauth?.id))
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('users.memberdeactivate')
                    })
                    break;
                case 'successdeactivate':
                    dispatch(getUser(clientauth?.id))
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('users.memberactivate')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [resultclient])

	const columnsMembers = [
        {
            name: t('users.name'),
            cell: member =>
                <div className={member.permitUsers?.some(permit => permit.active && permit.client === clientauth?.client) ? 'd-flex align-items-center' : "disabled d-flex align-items-center" }>
                    <div className='avatar me-2' style={{width: "60px", height: "60px"}}>
                    {member.avatar ?
                        <img src={member.avatar} alt={member.title} height="60px" width="60px"/>
                    : 
                        <div className="img-profile" style={{width: "60px", height: "60px"}}></div>
                    }
                    </div>
                    {member.firstname}
                </div>,
            selector: (member) => member.name,
            sortable: true,
        },{
            name: t('users.lastname'),
            cell: member =>
                <div className={member.permitUsers?.some(permit => permit.active && permit.client === clientauth?.client) ? "" : 'disabled' }>
                    {member.lastname}
                </div>,
            selector: (member) => member.name,
            sortable: true,
        },{
            name: t('users.membertype'),
            cell: member =>
                <div>
                    {member.permitUsers?.some(permit => permit.active && permit.client === clientauth?.client) ?
                        t('users.membertypes.'+member.permitUsers?.filter(permit => permit.active && permit.client === clientauth?.client)[0].membertype)
                    : 
                        ''
                    }
                </div>,
            selector: (member) => member.name,
            sortable: true,
        },{
			name: t('global.actions'), 
            center: 'true',
            width: '120px',
			cell: member =>
				<div className='d-flex align-items-center justify-content-between w-50'>
					<Link onClick={() => handleClientSelect(member._id)} to={`/client/personaldata`}>
                        <img src="/images/edit.svg" alt="pencil" height={16}></img>
					</Link>
                    {member.permitUsers?.some(permit => permit.active && permit.client === clientauth?.client) ?
                        <img className="cursor" src="/images/eyeclose.svg" alt="eyes" height={16} onClick={(e) => activatePermitUser(e, member._id)}/>
                    :
                        <img className="cursor" src="/images/eyeopen.svg" alt="eyes" height={16} onClick={(e) => activatePermitUser(e, member._id)}/>
                    }
				</div>,
			sortable: false,
		}
	]

    return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col className='d-flex justify-content-between'>
                    <h1 className='ms-2 my-0'>{t('users.members')}</h1>
                    <div className='buttons d-flex justify-content-end'>
                        <Link to="/client/registermember" className='btn btn-secondary' onClick={() => handleClientSelect(clientauth?.client)}>
                            <i className='fas fa-plus'></i> {t('users.addmember')}
                        </Link>
                    </div>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <div className="my-3">
                                <Row>
                                    <Col>
                                    {user?.members?.length > 0 ?
                                        <CustomDataTable columns={columnsMembers} data={user?.members} exportable={false} printable={false} />
                                    :
                                        <div className='contenedor-vacio text-center'>
                                            <h4>{t('users.thereisnotmembers')}</h4>                                            
                                            <div className='mt-3'>
                                                <Link to="/client/registermember" className='btn btn-secondary' onClick={() => handleClientSelect(clientauth?.client)}>
                                                    {t('users.addmember')}
                                                </Link>
                                            </div>
                                        </div>
                                    }
                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}