export const inscriptionsReducer = (state = {loadinginscription: true, resultinscription: null, inscriptions: null, inscription: null } , action) => {
    switch (action.type) {
        case 'INSCRIPTION_GET_SUCCESS':
            return {
                ...state,
                loadinginscription: false,
                inscription: action.payload.inscription
            }
        case 'INSCRIPTION_GETALL_SUCCESS':
            return {
                ...state,
                loadinginscription: false,
                inscriptions: action.payload.inscriptions
            }
        case 'INSCRIPTION_ADD_SUCCESS':
            return {
                ...state,
                loadinginscription:false,
                resultinscription: action.payload.message,
                inscription: action.payload.inscription
            }
        case 'INSCRIPTION_EDIT_SUCCESS':
            return {
                ...state,
                loading:false,
                inscription: action.payload.inscription,
                resultinscription: action.payload.message
            }
        case 'INSCRIPTION_REMOVE_RESULT':
            return {...state,
                loading:false,
                resultinscription: action.payload.message,
                inscriptions: state.inscriptions.filter(inscriptions => inscriptions._id !== action.payload.inscription)
            }
        case 'INSCRIPTION_RESET_RESULT':
            return {
                ...state,
                resultinscription: null
            }
        case 'INSCRIPTION_LOADING':
            return {
                ...state,
                loadinginscription:true
            }
        case 'INSCRIPTION_NOT_LOADING':
            return {
                ...state,
                loadinginscription:false
            }
        default:
            return state
    }
}