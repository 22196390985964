import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Button, Row, Form, Col, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { editUserClient } from '../../../actions/profiles/usersActions'
import { getClient } from '../../../actions/profiles/clientsActions'
import Swal from 'sweetalert2'
import useAuth from '../../../context/auth/useAuth'
import HeadConfig from '../../../components/menus/SubMenuConfig'
import { FileNameExtractor } from '../../../components/filenameextractor'
import { Link } from 'react-router-dom'

export default function Identitydata({ clientselected }) {
    const { t } = useTranslation()
    const { clientauth } = useAuth()
    const dispatch = useDispatch()

    const { user, resultuser } = useSelector(state => state.users)
    const { client } = useSelector(state => state.clients)

    const [isUpdating, setIslUpdating] = useState(false)
    const [formState, setFormState] = useState({})

    const [newDocument, setNewDocument] = useState({})
    const [imagesAux, setImagesAux] = useState([])
    const [imagePreviews, setImagePreviews] = useState([])

    const fileInputRef = useRef(null)

    useEffect(() => {
        if (clientauth) {
            dispatch(getClient(clientselected))
        }
    }, [dispatch, t, clientauth, clientselected])

    useEffect(() => {
        if (client) {
            setFormState({
                identityDocuments: client?.identityDocuments || [],
            })
            setNewDocument({
                ...newDocument,
                documentType: 'dni',
            })
        }
        // eslint-disable-next-line
    }, [user, client])

    const handleInputChange = ({ target }) => {
        setNewDocument({
            ...newDocument,
            [target.name]: target.value
        })
    }

    const handleSelectChange = (e) => {
        setNewDocument({
            ...newDocument,
            documentType: e.target.value
        })
    }

    const handleDrop = (e) => {
        e.preventDefault()
        const files = [...e.dataTransfer.files]
        handleFiles(files)
    }

    const handleFiles = (files) => {
        setImagesAux(files)

        const previews = []
        files.forEach((file) => {
            const reader = new FileReader()
            reader.onload = (e) => {
                previews.push(e.target.result)
                setImagePreviews([...previews])
            }
            reader.readAsDataURL(file)
        })
    }

    const deleteIdentitydocument = (addressIndex) => {
        Swal.fire({
            showCancelButton: true,
            showConfirmButton: true,
            cancelButtonText: t('global.cancel'),
            icon: 'question',
            text: t('users.identitydeletequestion')
        }).then((result) => {
            if (result.isConfirmed) {
                let arrayIdentitydocuments = [...formState.identityDocuments]
                arrayIdentitydocuments.splice(addressIndex, 1)
                setFormState({
                    identityDocuments: arrayIdentitydocuments
                })
                dispatch(editUserClient({ ...formState, 'identityDocuments': arrayIdentitydocuments, clienttoupdate: clientselected }, null, null))
            }
        })
    }

    const handleEdit = async (e) => {
        e.preventDefault()
        setIslUpdating(true)

        dispatch(editUserClient({ ...formState, 'identityDocuments': formState?.identityDocuments, clienttoupdate: clientselected }, null, { document: newDocument, images: imagesAux }))

        setIslUpdating(false)
    }

    useEffect(() => {
        if (resultuser) {
            let newresult = resultuser
            dispatch({
                type: 'USER_RESET_RESULT'
            })
            switch (newresult) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'success',
                        text: t('users.useredited')
                    })
                    setImagesAux([])
                    setImagePreviews([])
                    break;
                default:
                    break;
            }
        }
        // eslint-disable-next-line
    }, [resultuser])


    return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <HeadConfig nuevaSeccion="identity" type={clientauth?.client === clientselected ? 'client' : 'member'}/>
                </Col>
            </Row>
            <Row className="my-3">
                <Col xs={12} lg={7} className='mb-2'>
                    <Card>
                        <Card.Body>
                            <h2>{t('users.editidentity')}</h2>
                            <hr className='mb-4' />
                            <h4>{t('users.newdocument')}</h4>
                            <Form className="formulario" onSubmit={handleEdit}>
                                <Row className="my-3">
                                    <Col xs={12}>
                                        <Form.Group className='form-group'>
                                            <select
                                                className="form-control select has-content"
                                                value={newDocument?.documentType}
                                                name="documentType"
                                                onChange={handleSelectChange}
                                            >
                                                <option value="dni">{t('users.identitytype.dni')}</option>
                                                <option value="passport">{t('users.identitytype.passport')}</option>
                                                <option value="identitycard">{t('users.identitytype.identitycard')}</option>
                                                <option value="driverlicense">{t('users.identitytype.driverlicense')}</option>
                                                <option value="residencepermitcard">{t('users.identitytype.residencepermitcard')}</option>
                                                <option value="socialsecuritycard">{t('users.identitytype.socialsecuritycard')}</option>
                                                <option value="studentcard">{t('users.identitytype.studentcard')}</option>
                                            </select>
                                            <Form.Label htmlFor='documentType'>{t('users.documenttype')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} className='my-3'>
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                className={newDocument?.documentNumber ? 'has-content' : ''}
                                                type='text'
                                                name='documentNumber'
                                                value={newDocument?.documentNumber || ''}
                                                onChange={handleInputChange}
                                                required
                                            />
                                            <Form.Label htmlFor='documentNumber'>{t('users.documentnumber')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="my-3">
                                    <div
                                        className='draganddrop'
                                        onDrop={handleDrop}
                                        onDragOver={(e) => e.preventDefault()}
                                        onClick={() => fileInputRef.current.click()}
                                    >
                                        <div className='mb-2'>
                                            {t('global.draganddrop')}<br/>
                                            <i className='fas fa-cloud-arrow-up fa-3x'></i>
                                        </div>
                                        {imagePreviews.map((preview, index) => (
                                            <img
                                                key={index}
                                                src={preview}
                                                alt={`${t('users.image')} ${index}`}
                                                style={{ maxWidth: '100px', maxHeight: '100px', margin: '5px' }}
                                            />
                                        ))}
                                        <input
                                            type="file"
                                            multiple
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                            onChange={(e) => handleFiles([...e.target.files])}
                                        />
                                    </div>
                                </Row>
                                <Row className="my-3">
                                    <Col xs={12} lg={6} className='ms-auto'>
                                        <Button variant='primary' type="submit" className="w-100 my-2">
                                            {isUpdating
                                                ?
                                                <>
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </>
                                                :
                                                <>{t('global.add')}</>
                                            }
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} lg={5}>
                    {formState?.identityDocuments?.length > 0 ?
                        formState?.identityDocuments?.map((identityDocument, index) => (
                            <Col xs={12} key={index} className='mb-2'>
                                <Card>
                                    <Row>
                                        <Col className='d-flex flex-column'>
                                            <Col className='d-flex justify-content-between' >
                                                <div>
                                                    <h3>{t('users.identitytype.' + identityDocument.documentType)}</h3>
                                                    {identityDocument.documentNumber ? <label>{identityDocument.documentNumber}</label> : ''}
                                                </div>
                                                <i className="fas fa-trash cursor" aria-hidden="true" onClick={() => deleteIdentitydocument(index)}></i>
                                            </Col>
                                            <Col>
                                                {identityDocument?.images?.length > 0 ?
                                                    <ul>
                                                        {identityDocument?.images?.map((documentimage, index) => (
                                                            <li>
                                                                <Link to={documentimage} className="link" target="_blank" rel="noopener noreferrer"><small><FileNameExtractor documentimage={documentimage}/></small></Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                    :
                                                    <div className="img-empty me-2 d-flex justify-content-center align-items-center">
                                                        <i className='fas fa-ban'></i>
                                                    </div>
                                                }
                                            </Col>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        ))
                        :
                        ''
                    }
                </Col>
            </Row>
        </Container>
    )
}
