import AxiosControl from '../../context/axios/AxiosControl'
const Axios = AxiosControl()

export const getClub = (club) => async (dispatch) => {
    dispatch({
        type: 'CLUB_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/club/get/'+ club, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'CLUB_GET_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'CLUB_NOT_LOADING'
        })
    }
}

export const getClubPublic = (club) => async (dispatch) => {
    dispatch({
        type: 'CLUB_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/club/public/get/'+ club)
        if (status === 200) {
            dispatch({
                type: 'CLUB_GET_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'CLUB_NOT_LOADING'
        })
    }
}

export const getClubPublicByUrl = (url) => async (dispatch) => {
    dispatch({
        type: 'CLUB_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/club/public/getbyurl/'+ url)
        if (status === 200) {
            dispatch({
                type: 'CLUB_GET_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'CLUB_NOT_LOADING'
        })
    }
}

export const getClubs = () => async (dispatch) => {
    dispatch({
        type: 'CLUB_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/club/getall/', {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'CLUB_GETALL_SUCCESS',
                payload: data.clubs
            })
        }
    } catch (error) {
        dispatch({
            type: 'CLUB_NOT_LOADING'
        })
    }
}

export const getClubsPublic = () => async (dispatch) => {
    dispatch({
        type: 'CLUB_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/club/public/getall/')
        if (status === 200) {
            dispatch({
                type: 'CLUB_GETALL_SUCCESS',
                payload: data.clubs
            })
        }
    } catch (error) {
        dispatch({
            type: 'CLUB_NOT_LOADING'
        })
    }
}

export const getClubsByUser = (user) => async (dispatch) => {
    dispatch({
        type: 'CLUB_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/club/getclubsbyuser/'+ user, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'CLUB_GETALL_SUCCESS',
                payload: data.clubs
            })
        }
    } catch (error) {
        dispatch({
            type: 'CLUB_NOT_LOADING'
        })
    }
}

export const addClub = (newclubdata) => async (dispatch) => {
    dispatch({
        type: 'CLUB_LOADING'
    })
    try {
        const { data, status} = await Axios.post('/api/club/register', newclubdata, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'CLUB_ADD_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'CLUB_NOT_LOADING'
        })
    }
}