import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Card, CardBody } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { getSitesByClub } from '../../../../actions/sites/sitesActions'
import AreaForm from '../../../../components/forms/areas/AreaForm'
import { getZonesBySite } from '../../../../actions/sites/zonesActions'
import { editArea, getArea } from '../../../../actions/sites/areasActions'

export default function EditArea({clubselected}) {
  	const { t } = useTranslation()
	const dispatch = useDispatch()
    const navigate = useNavigate()

    const {loading: loadingSite, sites} = useSelector(state => state.sites)
    const {loading: loadingZone, zones} = useSelector(state => state.zones)
    const {loading: loadingCourt, courts} = useSelector(state => state.courts)
    const {loading: loadingArea, result, area} = useSelector(state => state.areas)

    const [areaData, setAreaData] = useState({
        name: '',
        zone: null,
        site: null,
        rentable: true,
        active: true,
        court: null
    })

    const [siteOptions, setSiteOptions] = useState([])
    const [zoneOptions, setZoneOptions] = useState([])
    const [courtOptions, setCourtOptions] = useState([])

    const {areaId} = JSON.parse(sessionStorage.getItem("dataIncrono"))

    const handleInputChange = ({target})=>{
        setAreaData({
            ...areaData,
            [target.name]: target.value
        })
    }

    const handleCheckboxChange = ({target}) => {
        setAreaData({
            ...areaData,
            [target.name]: target.checked
        })
    }

    const handleSelectChange = (target, selectType) => {
        if (selectType === 'site') {
            dispatch(getZonesBySite(target.value))
        }
        setAreaData({
            ...areaData,
            [selectType]: target
        })
    }

    const handleAdd = async(e) => {
        e.preventDefault()

        if(areaData.name !== '' || areaData.address === '' || !areaData.province || areaData.town !== ""){
            dispatch(editArea({...areaData, zone: areaData.zone.value}, areaId))
        }else{
            Swal.fire({
                showConfirmButton: true,
                showDenyButton: false,
                confirmButtonText: t('global.ok'),
                icon: 'warning',
                text: t('areas.formNotFinished')
            })
        }
    }

    useEffect(() => {
        if(result?.message){
            switch (result.message) {
                case 'success':
                    navigate('/club/areas/edit/step2')
                    dispatch({
                        type: 'AREA_RESET_RESULT'
                    })
                    
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [result])

    useEffect(()=>{
        async function setAreaInfo(area) {
            setAreaData({
                name: area.name,
                zone: {value: area.court.zone._id, label: area.court.zone.name},
                site: {value: area.court.zone.site._id, label: area.court.zone.site.name},
                court: {value: area.court._id, label: area.court.name},
                rentable: area.rentable || false,
                active: area.active || false,
            })
        }

        if (area) {
            setAreaInfo(area.area)
        }
    //eslint-disable-next-line
    }, [area])

    useEffect(() => {
        if (areaId) {
            dispatch(getArea(areaId))
        }
    }, [areaId, dispatch])

    useEffect(() => {
        if (clubselected) {
            dispatch(getSitesByClub(clubselected))
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setSiteOptions(sites.map(site => {
            return {label: site.name, value: site._id}
        }))
    }, [sites])

    useEffect(() => {
        setZoneOptions(zones.map(zone => {
            return {label: zone.name, value: zone._id}
        }))
    }, [zones])

    useEffect(() => {
        setCourtOptions(courts.map(court => {
            return {label: court.name, value: court._id}
        }))
    }, [courts])

	return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <h1 className='ms-2 my-0'>{t('sites.sites')}</h1>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <CardBody>
                            <h2 className='fs-18'>{t('areas.editArea')}</h2>
                            <hr className='mb-4'/>
                            <AreaForm state={areaData} statetype={'edit'} usertype={'club'} isAdding={loadingSite || loadingZone || loadingCourt || loadingArea} submit={handleAdd} inputChange={handleInputChange} checkboxChange={handleCheckboxChange} selectChange={handleSelectChange} sites={siteOptions} zones={zoneOptions} courts={courtOptions} cancelBtn={<Link to="/club/areas"><Button variant="default" className="w-100 mt-3">{t('global.cancel')}</Button></Link>}/>
                        </CardBody>
                    </Card>
                </Col>                
            </Row>
        </Container>
	)
}