import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Card, CardBody } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { getSitesByClub } from '../../../../actions/sites/sitesActions'
import ZoneForm from '../../../../components/forms/zones/ZoneForm'
import useAuth from '../../../../context/auth/useAuth'
import { addZone } from '../../../../actions/sites/zonesActions'

export default function AddZone({clubselected}) {
  	const { t } = useTranslation()
	const dispatch = useDispatch()
    const navigate = useNavigate()
	const {clientauth} = useAuth()

    const {sites, loading} = useSelector(state=>state.sites)

    const {result, loading: zoneLoading} = useSelector(state => state.zones)

    const [zoneData, setZoneData] = useState({
        name: '',
        site: null,
        active: true,
        rentable: true,
        schedule: {
            days: [
                {weekDays: ['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'], startDate: null, endDate: null, startHour: null, endHour: null, price: null},
            ],
            datesBlocked: []
        },
        interval: 'null'
    })

    const [sitesOptions, setSitesOptions] = useState([])

    const handleInputChange = ({target})=>{
        setZoneData({
            ...zoneData,
            [target.name]: target.value
        })
    }

    const handleCheckboxChange = ({target}) => {
        setZoneData({
            ...zoneData,
            [target.name]: target.checked
        })
    }

    const handleSelectChange = (target, selectType) => {
        setZoneData({
            ...zoneData,
            [selectType]: target
        })
    }

    const handleAdd = async(e) => {
        e.preventDefault()

        if(zoneData.name !== '' || !zoneData.site){
            dispatch(addZone({...zoneData, site: zoneData.site.value, interval: zoneData.interval === 'null' ? null : zoneData.interval}))
        }else{
            Swal.fire({
                showConfirmButton: true,
                showDenyButton: false,
                confirmButtonText: t('global.ok'),
                icon: 'warning',
                text: t('zones.formNotFinished')
            })
        }
    }

    const addSchedule = () => {
        setZoneData({
            ...zoneData,
            schedule: {
                ...zoneData.schedule,
                days: [...zoneData.schedule.days, {weekDays: ['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'], startDate: null, endDate: null, startHour: null, endHour: null, price: null}]
            }
        })
    }

    const removeSchedule = (day, index) => {
        setZoneData({
            ...zoneData,
            schedule: {...zoneData.schedule, days: zoneData.schedule.days.filter((d, i) => i !== index)}
        })
    }

    const handleDayCheck = (target, index) => {
        setZoneData({
            ...zoneData,
            schedule: {
                ...zoneData.schedule,
                days: zoneData.schedule.days.map((day, i) => {
                    if (i === index) {
                        if (target.checked) {
                            if (target.name !== "all") {
                                return {...day, weekDays: [...day.weekDays, target.name]}
                            } else {
                                return {...day, weekDays: ['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']}
                            }
                        } else {
                            if (target.name !== 'all') {
                                return {
                                    ...day,
                                    weekDays: day.weekDays.filter(d => (d !== target.name && d !== 'all'))
                                }
                            } else {
                                return {...day, weekDays: []}
                            }
                        }
                    } else {
                        return day
                    }
                })
            }
        })
    }

    const handleScheduleInputChange = (target, index) => {
        setZoneData({
            ...zoneData,
            schedule: {
                ...zoneData.schedule,
                days: zoneData.schedule.days.map((d, i) => {
                    if (i === index) {
                        return {
                            ...d,
                            [target.name]: target.value
                        }
                    } else {
                        return d
                    }
                })
            }
        })
    }

    const handleSchedulePeriodChange = (value, index) => {
        setZoneData({
            ...zoneData,
            schedule: {
                ...zoneData.schedule,
                days: zoneData.schedule.days.map((d, i) => {
                    if (i === index) {
                        return {
                            ...d,
                            startDate: value ? value[0] : null,
                            endDate: value ? value[1] : null
                        }
                    } else {
                        return d
                    }
                })
            }
        })
    }

    const handleRemoveBlockedRange = (index) => {
        setZoneData({
            ...zoneData,
            schedule: {
                ...zoneData.schedule,
                datesBlocked: zoneData.schedule.datesBlocked.filter((d, i) => i !== index)
            }
        })
    }

    const handleAddBlockedRange = (range) => {
        setZoneData({
            ...zoneData,
            schedule: {
                ...zoneData.schedule,
                datesBlocked: [...zoneData.schedule.datesBlocked, {startDate: range[0], endDate: range[1]}]
            }
        })
    }

    useEffect(() => {
        if(clientauth){
            dispatch(getSitesByClub(clubselected))
        }
    },[dispatch, clientauth, clubselected])

    useEffect(() => {
        if(result?.message){
            switch (result.message) {
                case 'success':
                    navigate('/club/zones')
                    dispatch({
                        type: 'ZONE_RESET_RESULT'
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [result])

    useEffect(() => {
        if (sites) {
            setSitesOptions(sites.map(site => {
                return {label: site.name, value: site._id}
            }))
        }
    }, [sites])

	return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <h1 className='ms-2 my-0'>{t('sites.sites')}</h1>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <CardBody>
                            <h2 className='fs-18'>{t('zones.createZone')}</h2>
                            <hr className='mb-4'/>
                            <ZoneForm state={zoneData} action={'add'} usertype={'club'} isAdding={loading || zoneLoading} submit={handleAdd} inputChange={handleInputChange} checkboxChange={handleCheckboxChange} selectChange={handleSelectChange} sites={sitesOptions} addSchedule={addSchedule} removeSchedule={removeSchedule} handleDayCheck={handleDayCheck} onScheduleInputChange={handleScheduleInputChange} onPeriodChange={handleSchedulePeriodChange} handleRemoveBlockedRange={handleRemoveBlockedRange} handleAddBlockedRange={handleAddBlockedRange} cancelBtn={<Link to="/club/zones"><Button variant="default" className="w-100 mt-3">{t('global.cancel')}</Button></Link>}/>
                        </CardBody>
                    </Card>
                </Col>                
            </Row>
        </Container>
	)
}