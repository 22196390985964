import DateRangePicker from "@wojtekmaj/react-daterange-picker"
import { Link } from 'react-router-dom'
import { useState } from "react"
import { Button, Col, Form, Row, Toast } from "react-bootstrap"
import { useTranslation } from "react-i18next"

export default function CompleteSchedule({value, removeSchedule, addSchedule, handleDayCheck, onScheduleInputChange, onPeriodChange, handleRemoveBlockedRange, handleAddBlockedRange}) {
    const { t } = useTranslation()

    const [blockedDateRange, setBlockedDateRange] = useState([null, null])

    const handleClick = (dayName, index) => {
        const fakeEvent = {
            target: {
                name: dayName,
                checked: !value.days[index].weekDays.includes(dayName)
            }
        }
        handleDayCheck(fakeEvent, index)
    }

    return (
        <>
            <Row className="mb-3">
                <Col xs={12}>
                    {t('schedule.schedule')}
                    <hr className="fina"/>
                </Col>
                {
                    value.days.map((day, index) => (
                        <Col xs={12} className="my-3" key={index}>
                            <Row>
                                <Col className="d-flex">
                                    {value.days.length > 1 && (
                                        <Col className="d-grid justify-content-center" style={{ width: '50px' }}>
                                            <Button variant="vertical" role="button" className="d-flex" onClick={() => { removeSchedule(day.weekDays, index) }}>
                                                <img src="/images/close.svg" alt="Close" />
                                            </Button>
                                        </Col>
                                    )}
                                    <Col xs={value.days.length > 1 ? 11 : 12}>
                                        <Form.Label>{t('schedule.selectDay')}</Form.Label>
                                        <div className="d-flex justify-content-evenly">
                                            {['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map(d => {
                                                const isChecked = day.weekDays.includes(d) || day.weekDays.includes('all')
                                                return (
                                                    <div
                                                        className={`dia ${isChecked ? 'checked' : ''}`}
                                                        key={d}
                                                        onClick={() => handleClick(d, index)}
                                                    >
                                                        <Form.Label htmlFor={`${d}-${index}`} className="cursor">
                                                            {t('schedule.' + d)}
                                                        </Form.Label>
                                                        <Form.Check 
                                                            name={d} 
                                                            id={`${d}-${index}`}
                                                            onChange={({target}) => handleDayCheck(target, index)} 
                                                            checked={isChecked}
                                                        />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <Row className="my-3">
                                            <Col xs={4}>
                                                <Form.Group>
                                                    <Form.Label>{t('schedule.period')}</Form.Label>
                                                    <DateRangePicker
                                                        className="has-content" 
                                                        onChange={(value) => {onPeriodChange(value, index)}} 
                                                        value={[day.startDate, day.endDate]}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={3} className="pt-3">
                                                <Form.Group className="form-group">
                                                    <Form.Control
                                                        className="has-content"
                                                        required 
                                                        type="time" 
                                                        name="startHour" 
                                                        value={day.startHour || ''} 
                                                        onChange={({target}) => {onScheduleInputChange(target, index)}}
                                                    />
                                                    <Form.Label>{t('schedule.startHour')}</Form.Label>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={3} className="pt-3">
                                                <Form.Group className="form-group">
                                                    <Form.Control 
                                                        className="has-content"
                                                        required 
                                                        type="time" 
                                                        name="endHour" 
                                                        value={day.endHour || ''} 
                                                        onChange={({target}) => {onScheduleInputChange(target, index)}}
                                                    />
                                                    <Form.Label>{t('schedule.endHour')}</Form.Label>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={2} className="pt-3">
                                                <Form.Group className="form-group">                                                
                                                    <Form.Control 
                                                        className="has-content text-right"
                                                        required 
                                                        type="number" 
                                                        name="price" 
                                                        value={day.price || ''} 
                                                        onChange={({target}) => {onScheduleInputChange(target, index)}}
                                                    />
                                                    <Form.Label>{t('schedule.price')}</Form.Label>
                                                    <img src="/images/money.svg" alt="Money" className="icono-money" />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Col>
                            </Row>
                        </Col>
                    ))
                }
                <Col xs={12} className="border-top border-bottom border-black py-3 text-center">
                    <Link onClick={() => {addSchedule()}} className="link-oscuro text-decoration-none">
                        <img src="/images/add.svg" alt="Add" /> {t('schedule.addSchedule')}
                    </Link>                    
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={12} className="fw-bold">
                    {t('schedule.datesBlocked')}
                </Col>
                <Col xs={12}>
                    <Form.Label className="me-2">{t('schedule.selectDates')}</Form.Label><br/>
                    <DateRangePicker onChange={value => setBlockedDateRange(value)} value={blockedDateRange}/>
                    <Button variant="primary" className="ms-3 py-1" role="button" onClick={() => {setBlockedDateRange([null, null]); handleAddBlockedRange(blockedDateRange)}}><i className="fas fa-plus"/></Button>
                    <Row className="my-3">
                        <Col className="d-flex align-items-center flex-wrap">
                        {
                            value.datesBlocked.map((dateRange, index) => (
                                <div className="m-1" key={index}>
                                    <Toast onClose={() => handleRemoveBlockedRange(index)}>
                                        <Toast.Header>
                                            {`${dateRange.startDate?.toLocaleDateString('es-ES', {day: '2-digit', month: '2-digit', year: 'numeric'})} - ${dateRange.endDate?.toLocaleDateString('es-ES', {day: '2-digit', month: '2-digit', year: 'numeric'})}`}
                                        </Toast.Header>
                                    </Toast>
                                </div>
                            ))
                        }
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}