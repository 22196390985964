import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom'
import useAuth from "../../context/auth/useAuth"

export default function MenuMarketplace({section}) {
    const { t } = useTranslation()
    const {clientauth} = useAuth()
    
    return (
        <div  className="sticky-top p-3 bg-white m-0 border-bottom border-2 border-dark">
            <Container>
                <Row className='align-items-center justify-content-between'>
                    <Col>
                        <Link to='/'><img src="/images/logotipo-incrono-cabecera.svg" alt="Logotipo Incrono" /></Link>
                    </Col>
                    <Col>
                        <Link to='/clubs'>
                            <Button 
                                className='mx-2'
                                variant={section === 'clubs' ? 'secondary' : 'default'}>{t('marketplace.clubs')}
                            </Button>
                        </Link>
                        <Link to='/activities'>
                            <Button 
                                className='mx-2'
                                variant={section === 'activities' ? 'secondary' : 'default'}>{t('marketplace.activities')}
                            </Button>
                        </Link>
                    </Col>
                    <Col className='text-end'>
                        <Link to='/login'>
                            <Button variant='primary'>
                                {clientauth 
                                ?
                                    t('marketplace.privatearea')
                                :
                                    t('marketplace.login')
                                } <img src='/images/login.svg' alt='Login' />
                            </Button>
                        </Link>
                    </Col>
                </Row>
            </Container>
            
        </div>        
    )
}