export const zonesReducer = (state = {loading: false, result: null, zones: [], zone: null}, action) => {
    switch (action.type) {
        case 'ZONE_LOADING':
            return {
                ...state,
                loading: true
            }
        case 'ZONE_NOT_LOADING':
            return {
                ...state,
                loading: false
            }
        case 'ZONE_GETALL_SUCCESS':
            return {
                ...state,
                loading: false,
                zones: action.payload
            }
        case 'ZONE_GET_SUCCESS':
            return {
                ...state,
                loading: false,
                zone: action.payload
            }
        case 'ZONE_ADD_SUCCESS':
            return {
                ...state,
                loading: false,
                result: action.payload
            }
        case 'ZONE_EDIT_SUCCESS':
            return {
                ...state,
                loading: false,
                result: action.payload
            }
        case 'ZONE_REMOVE_RESULT':
            return {
                ...state,
                loading: false,
                result: action.payload,
                zones: action.payload.message === "deleteZoneSuccess" ? state.zones.filter(z => z._id !== action.payload.zone) : state.zones
            }
        case 'ZONE_RESET_RESULT':
            return {
                ...state,
                result: null
            }
        default:
            return state
    }
}