import React from "react";
import {Row, Col, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function BannerPhrase2({phrase,image}) {
    const { t } = useTranslation()
    const backImage = {
        backgroundImage: 'url('+image+')',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100%'
    }

    return (
        <Card className='border-0 p-0 card-banner' style={backImage} >
            <div className="gradient"></div>
            <Card.Body className="d-flex flex-column">
                <Row>
                    <Col className="d-flex align-items-center">
                        <h2 className='display-7 text-white m-0' dangerouslySetInnerHTML={{ __html: phrase }} />
                    </Col>
                    <Col className="text-end"><img src="/images/logoreducido.svg" alt="Logo Incrono" style={{width:'90px'}}/></Col>
                </Row>
            </Card.Body>
        </Card>
    )
}