import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { getClub } from '../../../actions/profiles/clubsActions'
import { useDispatch, useSelector } from 'react-redux'

export const MenuDropdown = ({ backgroundImage, title, children }) => {
    return (
        <Dropdown className='ms-4'>
            <Dropdown.Toggle as="div" className='avatar-menu' style={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}>
                {title}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {children}
            </Dropdown.Menu>
        </Dropdown>
    )
}

export const MenuDropdownClub = ({ children }) => {
    const dispatch = useDispatch()
    const [selectedOption, setSelectedOption] = useState('')
    const [clubIdSelected, setClubIdSelected] = useState(null)

    const {club} = useSelector(state=>state.clubs)

	useEffect(() => {
		const storedData = sessionStorage.getItem('dataIncrono')
		if (storedData) {
			const {clubId} = JSON.parse(storedData)
			if (clubId) setClubIdSelected(clubId)
		}
	}, [])

	useEffect(() => {
		if (clubIdSelected) {
            dispatch(getClub(clubIdSelected))
		}
	}, [clubIdSelected, dispatch])

	useEffect(() => {
		if (club) {
            setSelectedOption(club?.name)
		}
	}, [club])


    const handleSelect = (option) => {
        setSelectedOption(option)
    }

    return (
        <Dropdown onSelect={handleSelect} className='avatar-club'>
            <Dropdown.Toggle as="div">
                {selectedOption || 'Seleccione club'}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {children}
            </Dropdown.Menu>
        </Dropdown>
    )
}

