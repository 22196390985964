export const activitiesReducer = (state = {loadingactivity: true, resultactivity: null, activities: null, activity: null } , action) => {
    switch (action.type) {
        case 'ACTIVITY_GET_SUCCESS':
            return {
                ...state,
                loadingactivity: false,
                activity: action.payload.activity
            }
        case 'ACTIVITY_GETALL_SUCCESS':
            return {
                ...state,
                loadingactivity: false,
                activities: action.payload.activities
            }
        case 'ACTIVITY_ADD_SUCCESS':
            return {
                ...state,
                loadingactivity:false,
                resultactivity: action.payload.message,
                activity: action.payload.activity
            }
        case 'ACTIVITY_EDIT_SUCCESS':
            return {
                ...state,
                loading:false,
                resultactivity: action.payload
            }
        case 'ACTIVITY_REMOVE_RESULT':
            return {...state,
                loading:false,
                resultactivity: action.payload.message,
                activities: state.activities.filter(activities => activities._id !== action.payload.activity)
            }
        case 'ACTIVITY_RESET_RESULT':
            return {
                ...state,
                resultactivity: null
            }
        case 'ACTIVITY_LOADING':
            return {
                ...state,
                loadingactivity:true
            }
        case 'ACTIVITY_NOT_LOADING':
            return {
                ...state,
                loadingactivity:false
            }
        default:
            return state
    }
}