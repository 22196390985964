import { Button, Col, Form, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import Slider from "rc-slider"
import { Link } from 'react-router-dom'
import "rc-slider/assets/index.css"

export default function CategoryForm({state, statetype, usertype, isAddingOrEditing, submit, inputChange, checkboxChange, sliderChange}) {
    const { t } = useTranslation()

    return (
        <Form onSubmit={submit}>
            <Row>
                <Col xs={12}>
                    <Form.Group className='form-group'>
                        <Form.Control 
                            className={state?.title ? 'has-content' : ''}
                            type='text'
                            name='title'
                            value={state?.title ||  ''}
                            onChange={inputChange}
                            required
                        />
                        <Form.Label htmlFor='title'>{t('categories.title')}</Form.Label>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="my-3">
                <Col xs={12}>
                    <Form.Group>  
                        <Form.Label htmlFor='agerange'>{t('categories.agerange')}: {state?.ageFrom} - {state?.ageTo}</Form.Label>                  
                        <Slider
                            className="has-content"
                            range
                            name='agerange'
                            min={0}
                            max={100}
                            value={[state?.ageFrom || 0, state?.ageTo || 100]}
                            onChange={(value) => sliderChange(value[0], value[1])}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row className="my-3">
                <Col xs={12}>
                    <Form.Group className="d-flex">
                        <Form.Label htmlFor='active'>{t('categories.active')}</Form.Label>
                        <Form.Check
                            className="ms-3"
                            type="switch"
                            name="active"
                            onChange={checkboxChange}
                            checked={state?.active || false}
                        />
                    </Form.Group>
                </Col>
            </Row>
            {isAddingOrEditing
                ?
                    //TODO:
                    'CARGANDO'
                :
                   statetype === 'add' 
                   ?
                        <Row>
                            <Col xs={12}>
                                <Button type="submit" variant="primary" className='mt-3 w-100'>
                                    {t('global.add')}
                                </Button>
                            </Col>
                        </Row>
                        
                   :
                        <Row>
                            <Col xs={12} lg={6}>                                
                                <Link  to="/club/categories" className="btn btn-default mt-3 w-100">
                                    {t('global.cancel')}
                                </Link>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Button type="submit" variant="primary" className='mt-3 w-100'>
                                    {t('global.edit')}
                                </Button>
                            </Col>
                        </Row>
                        
            }
        </Form>
    )    
}
