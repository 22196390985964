import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import ActivityForm from '../../../../components/forms/activities/ActivityForm'
import SportsComponent from '../../../../components/sports'
import { getClub } from '../../../../actions/profiles/clubsActions'
import { addActivity } from '../../../../actions/activities/activitiesActions'

import { EditorState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'

export default function ActivityAdd({clubselected, handleActivitySelect}) { 
  	const { t } = useTranslation()
	const dispatch = useDispatch()
    const navigate = useNavigate()

	const {activity, resultactivity, loadingactivity} = useSelector(state=>state.activities)
	const {club} = useSelector(state=>state.clubs)

	const {sports} = SportsComponent()
    const [sportsAux, setSportsAux] = useState([])

    const [newActivity, setNewActivity] = useState()
    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    useEffect(()=>{
        if(clubselected){
            dispatch(getClub(clubselected))
        }
    //eslint-disable-next-line
    }, [dispatch, clubselected])

    useEffect(()=>{
        if(club){
            setNewActivity({
                title: '',
                description: '',
                image: null,
                club: {value: club._id, label: club.name},
                sport: '',
                visibility_type: 'general',
                visibility: true,
                active: true
            })
    
            setSportsAux(sports)
        }
    //eslint-disable-next-line
    }, [club])

    const handleInputChange = ({target})=>{
        setNewActivity({
            ...newActivity,
            [target.name]: target.value
        })
    }

    const handleCheckboxChange = ({target}) => {
        setNewActivity({
            ...newActivity,
            [target.name]: target.checked
        })
    }

    const handleSelectChange = (target, selectType) => {
        setNewActivity({
            ...newActivity,
            [selectType]: target.value
        })
    }

    const handleImage = (e)=>{
        setNewActivity({
            ...newActivity,
            image: e.target.files[0]
        })
    }

    const handleEditorStateChange = (editorState) => {
        setEditorState(editorState)

        setNewActivity({
            ...newActivity,
            description: draftToHtml(convertToRaw(editorState.getCurrentContent()))
        })
    }

    const handleAdd = async(e) => {
        e.preventDefault()

        if(newActivity.sport !== ''){
            dispatch(addActivity({...newActivity, club: clubselected}))
        }else{
            Swal.fire({
                showConfirmButton: true,
                showDenyButton: false,
                confirmButtonText: t('global.ok'),
                icon: 'warning',
                text: t('activities.nosportselected')
            })
        }
    }

    useEffect(() => {
        if(resultactivity){
            let newresult = resultactivity
            dispatch({
                type: 'ACTIVITY_RESET_RESULT'
            })
            switch (newresult) {
                case 'success':
                    handleActivitySelect(activity?._id)
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('activities.addsuccess')
                    }).then(() => {
                        navigate('/club/categories')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [resultactivity])

	return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <h1 className='ms-2 my-0'>{t('club.activities')}</h1>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <h2>{t('activities.createnewactivity')}</h2>
                            <hr className='mb-4'/>
                            <ActivityForm state={newActivity} statetype={'add'} usertype={'club'} isAdding={loadingactivity} sports={sportsAux} submit={handleAdd} inputChange={handleInputChange} checkboxChange={handleCheckboxChange} selectChange={handleSelectChange} handleImage={handleImage} editorState={editorState} handleEditorStateChange={handleEditorStateChange}/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
	)
}