import React, { useEffect } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import useAuth from '../../context/auth/useAuth'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '../../actions/profiles/usersActions'
import { Link } from 'react-router-dom'

export default function Dashboard() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
	const {clientauth} = useAuth()

    const {user} = useSelector(state=>state.users)

    useEffect(() => {
        if(clientauth){
            dispatch(getUser(clientauth?.id))
        }
    },[dispatch, t, clientauth])

    return (
        <Container className='p-5'> 
            <Row>
                <Col>
                    <div className='sd-label-dashboard'>{t('admin.dashboard.welcome')}</div>
                    <div className="sd-label-userdashboard">{user?.username}</div>
                </Col>
            </Row>
            <Row className='mb-5'>
                <Col>
                    <h3>{t('admin.dashboard.subwelcome')}</h3>
                </Col>
            </Row>
            <Row>
                <Card>
                    <Link to="/club/activities" className='btn btn-dashboard'>
                        <h2 className='noBold'>{t('club.dashboard.activities')}</h2>
                    </Link>
                </Card>
            </Row>
            <Row>
                <Card>
                    <Link to="/admin/config" className='btn btn-dashboard'>
                        <h2 className='noBold'>{t('client.dashboard.config')}</h2>
                    </Link>
                </Card>
            </Row>
        </Container>
    )
}