export const clubsReducer = (state = {loadingclub: true, resultclub: null, clubs: null, club: null } , action) => {
    switch (action.type) {
        case 'CLUB_GET_SUCCESS':
            return {
                ...state,
                loadingclub: false,
                club: action.payload.club
            }
        case 'CLUB_GETALL_SUCCESS':
            return {
                ...state,
                loadingclub: false,
                clubs: action.payload
            }
        case 'CLUB_ADD_SUCCESS':
            return {
                ...state,
                loadingclub:false,
                resultclub: action.payload.message,
                club: action.payload.club
            }
        case 'CLUB_RESET_RESULT':
            return {
                ...state,
                resultclub: null
            }
        case 'CLUB_LOADING':
            return {
                ...state,
                loadingclub:true
            }
        case 'CLUB_NOT_LOADING':
            return {
                ...state,
                loadingclub:false
            }
        default:
            return state
    }
}