import React, { Fragment } from 'react'
import { Container, Row, Col, Button} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom'

export default function RegisterLanding() {
    const { t } = useTranslation()

    return (
        <Fragment>
            <header className="cabecera sticky-top d-flex align-items-center px-4">
                <Link to='/'><img src="/images/logotipo-incrono-cabecera.svg" alt="Logotipo Incrono" /></Link>
            </header>
            <Container fluid className='p-0 m-0'>
                <Row className='p-0 m-0 centro-auth'>   
                    <Col xs={12} className='d-flex align-items-center justify-content-center p-5'>
                        <Container>
                            <Row>
                                <Col className='text-center'>
                                    <h1>{t('registerlanding.whoareyou')}</h1>
                                </Col>
                            </Row>
                            <Row className="my-3 d-flex justify-content-center">
                                <Col className='text-center'>
                                    <Link to="/registerclient" className='link-oscuro'>
                                            <img
                                                src="/images/athlete.png"
                                                alt="Icon athlete"
                                                width={150}
                                            />
                                            <h2>{t('registerlanding.client')}</h2>
                                    </Link>
                                </Col>
                                <Col className='text-center'>
                                    <Link to="/registerclub" className='link-oscuro'>
                                            <img
                                                src="/images/shield.png"
                                                alt="Icon shield"
                                                width={150}
                                            />
                                            <h2>{t('registerlanding.club')}</h2>
                                    </Link>
                                </Col>
                            </Row>
                            <Row className="my-3">
                                <Col className='text-center'>
                                    <Link to="/login">
                                        <Button className="btn-secondary my-2 px-5">
                                            {t('global.return')}
                                        </Button>
                                    </Link>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
