import { useTranslation } from "react-i18next"

const SportsComponent = () => {
    const { t } = useTranslation()

    let sports = [
        { "value": "acroyoga", "label": t('sports.acroyoga') },
        { "value": "aerobics", "label": t('sports.aerobics') },
        { "value": "mixed_martial_arts", "label": t('sports.mixed_martial_arts') },
        { "value": "athletics", "label": t('sports.athletics') },
        { "value": "mountain_athletics", "label": t('sports.mountain_athletics') },
        { "value": "badminton", "label": t('sports.badminton') },
        { "value": "beach_badminton", "label": t('sports.beach_badminton') },
        { "value": "basketball", "label": t('sports.basketball') },
        { "value": "handball", "label": t('sports.handball') },
        { "value": "biathlon", "label": t('sports.biathlon') },
        { "value": "bodyboard", "label": t('sports.bodyboard') },
        { "value": "bowling", "label": t('sports.bowling') },
        { "value": "bouldering", "label": t('sports.bouldering') },
        { "value": "boxing", "label": t('sports.boxing') },
        { "value": "diving", "label": t('sports.diving') },
        { "value": "baseball", "label": t('sports.baseball') },
        { "value": "beach_baseball", "label": t('sports.beach_baseball') },
        { "value": "cycling", "label": t('sports.cycling') },
        { "value": "cricket", "label": t('sports.cricket') },
        { "value": "crossfit", "label": t('sports.crossfit') },
        { "value": "curling", "label": t('sports.curling') },
        { "value": "disc_golf", "label": t('sports.disc_golf') },
        { "value": "equestrian", "label": t('sports.equestrian') },
        { "value": "fencing", "label": t('sports.fencing') },
        { "value": "wheelchair_fencing", "label": t('sports.wheelchair_fencing') },
        { "value": "skiing", "label": t('sports.skiing') },
        { "value": "water_skiing", "label": t('sports.water_skiing') },
        { "value": "freestyle_scooter", "label": t('sports.freestyle_scooter') },
        { "value": "fronton", "label": t('sports.fronton') },
        { "value": "soccer", "label": t('sports.soccer') },
        { "value": "soccer_11", "label": t('sports.soccer_11') },
        { "value": "soccer_7", "label": t('sports.soccer_7') },
        { "value": "american_football", "label": t('sports.american_football') },
        { "value": "beach_soccer", "label": t('sports.beach_soccer') },
        { "value": "futsal", "label": t('sports.futsal') },
        { "value": "gymnastics", "label": t('sports.gymnastics') },
        { "value": "artistic_gymnastics", "label": t('sports.artistic_gymnastics') },
        { "value": "hot_air_balloon", "label": t('sports.hot_air_balloon') },
        { "value": "golf", "label": t('sports.golf') },
        { "value": "adapted_golf", "label": t('sports.adapted_golf') },
        { "value": "ice_hockey", "label": t('sports.ice_hockey') },
        { "value": "surfing_beginners", "label": t('sports.surfing_beginners') },
        { "value": "judo", "label": t('sports.judo') },
        { "value": "karate", "label": t('sports.karate') },
        { "value": "kickboxing", "label": t('sports.kickboxing') },
        { "value": "kitesurfing", "label": t('sports.kitesurfing') },
        { "value": "krav_maga", "label": t('sports.krav_maga') },
        { "value": "lacrosse", "label": t('sports.lacrosse') },
        { "value": "beach_wrestling", "label": t('sports.beach_wrestling') },
        { "value": "motocross", "label": t('sports.motocross') },
        { "value": "mountain_biking", "label": t('sports.mountain_biking') },
        { "value": "muay_thai", "label": t('sports.muay_thai') },
        { "value": "swimming", "label": t('sports.swimming') },
        { "value": "other_sports", "label": t('sports.other_sports') },
        { "value": "paragliding", "label": t('sports.paragliding') },
        { "value": "parkour", "label": t('sports.parkour') },
        { "value": "skating", "label": t('sports.skating') },
        { "value": "ice_skating", "label": t('sports.ice_skating') },
        { "value": "pentathlon", "label": t('sports.pentathlon') },
        { "value": "fishing", "label": t('sports.fishing') },
        { "value": "pickleball", "label": t('sports.pickleball') },
        { "value": "canoeing", "label": t('sports.canoeing') },
        { "value": "paddle_tennis", "label": t('sports.paddle_tennis') },
        { "value": "quidditch", "label": t('sports.quidditch') },
        { "value": "beach_racquetball", "label": t('sports.beach_racquetball') },
        { "value": "rafting", "label": t('sports.rafting') },
        { "value": "racquetball", "label": t('sports.racquetball') },
        { "value": "rowing", "label": t('sports.rowing') },
        { "value": "rugby", "label": t('sports.rugby') },
        { "value": "pole_vaulting", "label": t('sports.pole_vaulting') },
        { "value": "high_jump", "label": t('sports.high_jump') },
        { "value": "long_jump", "label": t('sports.long_jump') },
        { "value": "trampoline", "label": t('sports.trampoline') },
        { "value": "skateboarding", "label": t('sports.skateboarding') },
        { "value": "slackline", "label": t('sports.slackline') },
        { "value": "beach_slackline", "label": t('sports.beach_slackline') },
        { "value": "slopestyle", "label": t('sports.slopestyle') },
        { "value": "snowboard", "label": t('sports.snowboard') },
        { "value": "snowkite", "label": t('sports.snowkite') },
        { "value": "softball", "label": t('sports.softball') },
        { "value": "beach_softball", "label": t('sports.beach_softball') },
        { "value": "squash", "label": t('sports.squash') },
        { "value": "beach_squash", "label": t('sports.beach_squash') },
        { "value": "street_workout", "label": t('sports.street_workout') },
        { "value": "sumo", "label": t('sports.sumo') },
        { "value": "surfing", "label": t('sports.surfing') },
        { "value": "taekkyon", "label": t('sports.taekkyon') },
        { "value": "taekwondo", "label": t('sports.taekwondo') },
        { "value": "tennis", "label": t('sports.tennis') },
        { "value": "table_tennis", "label": t('sports.table_tennis') },
        { "value": "beach_tennis", "label": t('sports.beach_tennis') },
        { "value": "archery", "label": t('sports.archery') },
        { "value": "beach_archery", "label": t('sports.beach_archery') },
        { "value": "triathlon", "label": t('sports.triathlon') },
        { "value": "adventure_triathlon", "label": t('sports.adventure_triathlon') },
        { "value": "volleyball", "label": t('sports.volleyball') },
        { "value": "beach_volleyball", "label": t('sports.beach_volleyball') },
        { "value": "volleyball_6x6", "label": t('sports.volleyball_6x6') },
        { "value": "water_polo", "label": t('sports.water_polo') },
        { "value": "weightlifting", "label": t('sports.weightlifting') },
        { "value": "windsurfing", "label": t('sports.windsurfing') },
        { "value": "yoga", "label": t('sports.yoga') }
    ]

    sports = sports?.sort((a, b) => (a.label > b.label ? 1 : -1))

    return { 'sports': sports}
}

export default SportsComponent