import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Card} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import AreaFormStep2 from '../../../../components/forms/areas/AreaFormStep2'
import { addAreaSchedulesAndBlockedDates, getArea } from '../../../../actions/sites/areasActions'

export default function EditAreaStep2() {
  	const { t } = useTranslation()
	const dispatch = useDispatch()
    const navigate = useNavigate()

    const {result, loading, area} = useSelector(state => state.areas)

    const [areaData, setAreaData] = useState({
        days: [
            {weekDays: ['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'], startDate: null, endDate: null, startHour: null, endHour: null, price: null},
        ],
        datesBlocked: [],
        interval: 'null',
        deletedDatesBlocked: [],
        deletedDays: []
    })
    
    const {areaId} = JSON.parse(sessionStorage.getItem("dataIncrono"))
    

    const handleDayCheck = (target, index) => {
        setAreaData({
            ...areaData,
            days: areaData?.days?.map((day, i) => {
                if (i === index) {
                    if (target.checked) {
                        if (target.name !== 'all') {
                            return {
                                ...day,
                                weekDays: [...day.weekDays, target.name]
                            }
                        } else {
                            return {
                                ...day,
                                weekDays: ['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
                            }
                        }
                    } else {
                        if (target.name !== 'all') {
                            return {
                                ...day,
                                weekDays: day.weekDays.filter(d => (d !== target.name && d !== "all"))
                            } 
                        } else {
                            return {
                                ...day,
                                weekDays: []
                            }
                        }
                    }
                } else {
                    return day
                }
            })
        })
    }

    const addSchedule = () => {
        setAreaData({
            ...areaData,
            days: [...areaData.days, {weekDays: ['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'], startDate: null, endDate: null, startHour: null, endHour: null, price: null},]
        })
    }

    const removeSchedule = (day, index) => {
        let deletedScheduleId = areaData.days.find((d, i) => i === index)._id
        setAreaData({
            ...areaData,
            days: areaData.days.filter((d, i) => i !== index),
            deletedDays: deletedScheduleId ? [...areaData.deletedDays, deletedScheduleId] : areaData.deletedDays
        })
    }

    const handleScheduleInputChange = (target, index) => {
        setAreaData({
            ...areaData,
            days: areaData.days.map((d, i) => {
                if (i === index) {
                    return {
                        ...d,
                        [target.name]: target.value
                    }
                } else {
                    return d
                }
            })
        })
    }

    const handleAddBlockedRange = (range) => {
        setAreaData({
            ...areaData,
            datesBlocked: [...areaData.datesBlocked, {startDate: range[0], endDate: range[1]}]
        })
    }

    const handleRemoveBlockedRange = (index) => {
        let deletedBlockedRangeId = areaData.datesBlocked.find((d, i) => i === index)._id
        setAreaData({
            ...areaData,
            datesBlocked: areaData.datesBlocked.filter((d, i) => i !== index),
            deletedDatesBlocked: deletedBlockedRangeId ? [...areaData.deletedDatesBlocked, deletedBlockedRangeId] : areaData.deletedDatesBlocked
        })
    }

    const handleAdd = async(e) => {
        e.preventDefault()

        dispatch(addAreaSchedulesAndBlockedDates(areaData, areaId))
    }

    const handleInputChange = ({target})=>{
        setAreaData({
            ...areaData,
            [target.name]: target.value
        })
    }

    const handleSchedulePeriodChange = (value, index) => {
        setAreaData({
            ...areaData,
            days: areaData.days.map((d, i) => {
                if (i === index) {
                    return {
                        ...d,
                        startDate: value ? value[0] : null,
                        endDate: value ? value[1] : null
                    }
                } else {
                    return d
                }
            })
        })
    }

    useEffect(() => {
        if(result){
            switch (result) {
                case 'success':
                    navigate('/club/areas')
                    dispatch({
                        type: 'AREA_RESET_RESULT'
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [result])

    useEffect(() => {
        if (areaId) {
            dispatch(getArea(areaId))
        }
    }, [areaId, dispatch])

    useEffect(()=>{
        async function setAreaInfo(area) {
            setAreaData({
                days: area.schedule?.map(schedule => {
                    return {...schedule, startDate: new Date(schedule.startDate), endDate: new Date(schedule.endDate)}
                }) || [{weekDays: ['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'], startDate: null, endDate: null, startHour: null, endHour: null, price: null}],
                datesBlocked: area.datesBlocked?.map(dateBlocked => {
                    return {...dateBlocked, startDate: new Date(dateBlocked.startDate), endDate: new Date(dateBlocked.endDate)}
                }) || [],
                interval: area.area.interval || 'null',
                deletedDatesBlocked: [],
                deletedDays: []
            })
        }

        if (area) {
            setAreaInfo(area)
        }
    //eslint-disable-next-line
    }, [area])

	return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <h1 className='ms-2 my-0'>{t('sites.sites')}</h1>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <h2 className='fs-18'>{t('areas.editArea')}</h2>
                            <hr className='mb-4'/>
                            <AreaFormStep2 state={areaData} statetype={'edit'} usertype={'club'} isAdding={loading} addSchedule={addSchedule} removeSchedule={removeSchedule} submit={handleAdd} handleDayCheck={handleDayCheck} handleScheduleInputChange={handleScheduleInputChange} handleAddBlockedRange={handleAddBlockedRange} handleRemoveBlockedRange={handleRemoveBlockedRange} inputChange={handleInputChange} onPeriodChange={handleSchedulePeriodChange} cancelBtn={<Link to="/club/areas/edit"><Button variant="default" className="w-100 mt-3">{t('global.back')}</Button></Link>}/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
	)
}