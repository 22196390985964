import React from "react";
import {Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function BannerPhrase({phrase,image}) {
    const { t } = useTranslation()
    const backImage = {
        backgroundImage: 'url('+image+')',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100%'
    }

    return (
        <Card className='border-0 card-banner' style={backImage} >
            <div className="gradient"></div>
            <Card.Body className="d-flex flex-column">
                <div className="text-end"><img src="/images/logoreducido.svg" alt="Logo Incrono" style={{width:'90px'}}/></div>
                <h2 className='display-6 text-white mt-auto'>{phrase}</h2>
            </Card.Body>
        </Card>
    )
}