import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Button, Row, Form, Col, Card} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { addMember } from '../../../actions/profiles/clientsActions'
import Swal from 'sweetalert2'
import { Link, useNavigate } from 'react-router-dom'
import { getUser } from '../../../actions/profiles/usersActions'
import useAuth from '../../../context/auth/useAuth'
import Select, { components } from "react-select"
import FamiliarComponent from '../../../components/familiar'

export default function RegisterMember() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
	const {clientauth} = useAuth()
	const {familiar} = FamiliarComponent()
    const Input = ({ ...rest }) => <components.Input {...rest} autoComplete={'nope'} />

    const [familiarsAux, setFamiliarsAux] = useState([])

    const {user, resultuser} = useSelector(state=>state.users)
    const {resultclient} = useSelector(state=>state.clients)

    const [formState, setFormState] = useState({})

    useEffect(() => {
        if(clientauth){
            dispatch(getUser(clientauth?.id))
            setFamiliarsAux(familiar)
        }
    },[dispatch, t, clientauth])

    useEffect(() => {
        if(user){
            let arrayMembersOfMembers = []

            if(user?.members?.length > 0){
                user?.members?.map((client, index) => (
                    arrayMembersOfMembers[index] = {
                        name: client?.firstname+' '+client?.lastname,
                        client: client,
                        membertype: 'nothing',
                        permit: ['nothing']
                    }
                ))
            }

            setFormState({
                profile: 'Client',
                parent: user?.client,
                firstname: '',
                lastname: '',
                birthdate: '',
                membertype: '',
                permitUsers: arrayMembersOfMembers,
                tutor1Name: null,
                tutor1Email: null,
                tutor1Phone: null,
                tutor2Name: null,
                tutor2Email: null,
                tutor2Phone: null
            })
        }
    }, [user])

    const handleInputChange = ({target})=>{
        setFormState({
            ...formState,
            [target.name]: target.value
        })
    }

    const handleDateChange = ({target}) => {
        let currentDate = new Date()
        let date18YearsAgo = new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate())

        if (new Date(target.value) > date18YearsAgo) {
            setFormState({
                ...formState,
                tutor1Name: formState.tutor1Name ? formState.tutor1Name : '',
                tutor1Email: formState.tutor1Email ? formState.tutor1Email : '',
                tutor1Phone: formState.tutor1Phone ? formState.tutor1Phone : '',
                tutor2Name: formState.tutor2Name ? formState.tutor2Name : '',
                tutor2Email: formState.tutor2Email ? formState.tutor2Email : '',
                tutor2Phone: formState.tutor2Phone ? formState.tutor2Phone : '',
                [target.name]: target.value
            })
        } else {
            setFormState({
                ...formState,
                tutor1Name: null,
                tutor1Email: null,
                tutor1Phone: null,
                tutor2Name: null,
                tutor2Email: null,
                tutor2Phone: null,
                [target.name]: target.value
            })
        }
    }

    const handleSelectChange = (target) => {
        setFormState({
            ...formState,
            membertype: target.value
        })
    }

    const handleSelectMemberChange = (target, index) => {
        let arrayMembersOfMembers = formState?.permitUsers
        arrayMembersOfMembers[index] = {
            ...formState?.permitUsers[index],
            membertype: target.value
        }

        setFormState({
            ...formState,
            permitUsers: arrayMembersOfMembers
        })
    }

    const handleAdd = async(e) => {
        e.preventDefault()

        if(formState.membertype !== ''){
            dispatch(addMember(formState))
        }else{
            Swal.fire({
                showConfirmButton: true,
                icon: 'warning',
                text: t('users.selectmembertype')
            })
        }
    }

    useEffect(() => {
        if(resultclient){
            let newresult = resultclient
            dispatch({
                type: 'CLIENT_RESET_RESULT'
            })
            switch (newresult) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('users.memberadd')
                    }).then(() => {
                        navigate('/client/members')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [resultclient])

    return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <h1 className='ms-2 my-0'>{t('users.members')}</h1>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <h2>{t('users.newmember')}</h2>
                            <hr className='mb-4'/>
                            <Form className="formulario" onSubmit={handleAdd}>
                                <Row className="my-3">
                                    <Col xs={12} lg={6}>
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                className={formState.firstname ? 'has-content' : ''}
                                                type='text'
                                                name='firstname' 
                                                value={formState.firstname ||  ''}
                                                onChange={handleInputChange}
                                                required
                                            />
                                            <Form.Label htmlFor='firstname'>{t('users.firstname')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                className={formState.firstname ? 'has-content' : ''}
                                                type='text'
                                                name='lastname' 
                                                value={formState.lastname ||  ''}
                                                onChange={handleInputChange}
                                                required
                                            />
                                            <Form.Label htmlFor='lastname'>{t('users.lastname')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="my-3">
                                    <Col xs={12} lg={6}>
                                        <Form.Label htmlFor='birthdate'>{t('users.birthdate')}</Form.Label>
                                        <Form.Control
                                            className="login"
                                            type='date'
                                            name='birthdate' 
                                            value={formState.birthdate ||  ''}
                                            onChange={handleDateChange}
                                            required
                                        />
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <Form.Group className='form-group'>
                                            <Select
                                                components={{ Input }} 
                                                className="form-control select has-content"
                                                type='text'
                                                name='membertype'
                                                inputProps={{ autoComplete: 'nope' }}
                                                options={familiarsAux}
                                                value={{ value: formState?.membertype || '', label: t('users.membertypes.' + (formState?.membertype || '')) }}
                                                onChange={(e) => handleSelectChange(e)}
                                                required
                                            />
                                            <Form.Label htmlFor='membertype'>{t('users.membertype')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {
                                    formState.tutor1Name !== null &&
                                    <>
                                        <Row className='my-3'>
                                            <Col xs={12} lg={6}>
                                                <Form.Label htmlFor='tutor1Name'>{t('users.tutor1Name')}</Form.Label>
                                                <Form.Control
                                                    className="login"
                                                    type='text'
                                                    name='tutor1Name' 
                                                    value={formState.tutor1Name ||  ''}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </Col>
                                            <Col xs={12} lg={6}>
                                                <Form.Label htmlFor='tutor1Phone'>{t('users.tutor1Phone')}</Form.Label>
                                                <Form.Control
                                                    className="login"
                                                    type='text'
                                                    name='tutor1Phone' 
                                                    value={formState.tutor1Phone ||  ''}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                        <Row className='my-3'>
                                            <Col xs={12} lg={6}>
                                                <Form.Label htmlFor='tutor1Email'>{t('users.tutor1Email')}</Form.Label>
                                                <Form.Control
                                                    className="login"
                                                    type='text'
                                                    name='tutor1Email' 
                                                    value={formState.tutor1Email ||  ''}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </Col>
                                            <Col xs={12} lg={6}>
                                                <Form.Label htmlFor='tutor2Name'>{t('users.tutor2Name')}</Form.Label>
                                                <Form.Control
                                                    className="login"
                                                    type='text'
                                                    name='tutor2Name' 
                                                    value={formState.tutor2Name ||  ''}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                        <Row className='my-3'>
                                            <Col xs={12} lg={6}>
                                                <Form.Label htmlFor='tutor2Phone'>{t('users.tutor2Phone')}</Form.Label>
                                                <Form.Control
                                                    className="login"
                                                    type='text'
                                                    name='tutor2Phone' 
                                                    value={formState.tutor2Phone ||  ''}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </Col>
                                            <Col xs={12} lg={6}>
                                                <Form.Label htmlFor='tutor2Email'>{t('users.tutor2Email')}</Form.Label>
                                                <Form.Control
                                                    className="login"
                                                    type='text'
                                                    name='tutor2Email' 
                                                    value={formState.tutor2Email ||  ''}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                    </>
                                }
                                {formState?.permitUsers?.length > 0 ?
                                    formState?.permitUsers?.map((client, index) => (
                                        <Row key={index} className="my-3">
                                            <Col xs={2}>
                                                {client.name}
                                            </Col>
                                            <Col xs={10}>
                                                <Form.Group className='form-group'>
                                                    <Select
                                                        components={{ Input }} 
                                                        className="form-control select has-content"
                                                        type='text'
                                                        name='membertype'
                                                        inputProps={{ autoComplete: 'nope' }}
                                                        options={familiarsAux}
                                                        value={{ value: formState?.permitUsers[index]?.membertype || '', label: t('users.membertypes.' + (formState?.permitUsers[index]?.membertype || '')) }}
                                                        onChange={(e) => handleSelectMemberChange(e, index)}
                                                        required
                                                    />
                                                    <Form.Label htmlFor='membertype'>{t('users.membertype')}</Form.Label>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    ))
                                :
                                    ''
                                }
                                <Row className="my-3">
                                    <Col xs={12} lg={6}>
                                        <Link to="/client/members" className="btn btn-default my-2 w-100">
                                            {t('global.cancel')}
                                        </Link>
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <Button variant='primary' type="submit" className="w-100 my-2">
                                            {t('users.createaccountbutton')}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}
