import React, { useState } from 'react'
import { Row, Col, Card, Form, Button } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import Select from "react-select"
import SportsComponent from '../../../components/sports'

export default function SearchGeneric({ancho}){
    const { t } = useTranslation()
    const {sports} = SportsComponent()
    const [formState, setFormState] = useState({})
    
    const handleInputChange = ({target})=>{
        setFormState({
            ...formState,
            [target.name]: target.value
        })
    }

    const handleSelectChange = (target, selectType) => {
        
    }

    return (
        <Card className='p-0 form-special w-100'>
            <Card.Body className='m-auto' style={{ width: ancho }}>
                <Row>
                    <Col>
                        <Form.Group className='form-group'>
                            <Form.Control
                                type='text'
                                name='search'
                                onChange={handleInputChange}
                                placeholder={t('marketplace.textsearch')}
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className='form-group'>
                            <Select
                                className="form-control select has-content"
                                name= "sport"
                                options={sports}
                                placeholder={t('marketplace.sport')}
                                onChange={(e) => handleSelectChange(e, 'sport')}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Control type="date" />
                    </Col>
                    <Col>
                        <Form.Control type="text" 
                            placeholder={t('marketplace.schedule')}
                        />
                    </Col>
                    <Col>
                        <Button variant='secondary' type="submit" className="w-100 my-2">
                            {t('marketplace.search')}
                        </Button>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}