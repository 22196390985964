import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Button, Row, Form, Col, Card, CardBody} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Select from "react-select"
import { editUserClub } from '../../../actions/profiles/usersActions'
import Swal from 'sweetalert2'
import useAuth from '../../../context/auth/useAuth'
import { getClub } from '../../../actions/profiles/clubsActions'
import HeadConfigClub from '../../../components/menus/SubMenuConfigClub'
import provinces from '../../../components/provinces.json'
import towns from '../../../components/towns.json'

export default function Billingdata({clubselected}) {
    const { t } = useTranslation()
	const {clientauth} = useAuth()
    const dispatch = useDispatch()

    const {user, resultuser} = useSelector(state=>state.users)
    const {club} = useSelector(state=>state.clubs)

    const [isUpdating, setIslogging] = useState(false)
    const [formState, setFormState] = useState({})

    const [provinceAux, setProvinceAux] = useState({label: '', value: ''})
    const [townAux, setTownAux] = useState({label: '', value: ''})
    const [townsAux, setTownsAux] = useState([])

    useEffect(() => {
        if(clientauth && clubselected){
            dispatch(getClub(clubselected))
        }
    },[dispatch, t, clientauth, clubselected])

    useEffect(() => {
        if(club){
            setFormState({
                billing: club?.billing || {
                    nif: '',
                    businessName: '',
                    address: '',
                    postalCode: '',
                    province: '',
                    town: '',
                }
            })

            if(club?.billing?.province){
                setProvinceAux({label: club?.billing?.province, value: club?.billing?.province})
                setTownsAux(towns.filter(town => town.parent_value === club?.billing?.province))
            }

            if(club?.billing?.town){
                setTownAux({label: club?.billing?.town, value: club?.billing?.town})
            }
        }
    // eslint-disable-next-line
    }, [user, club])

    const handleInputChange = ({target})=>{
        setFormState({
            ...formState,
            billing: {...formState.billing,
                [target.name]: target.value
            }
        })
    }

    const handleSelectProvinceChange = (target)=>{
        setFormState({
            ...formState,
            billing: {...formState.billing,
                'province': target.value,
                'town': ''
            }
        })
        setProvinceAux(provinces.filter(province => province.value === target.value))
        setTownAux({label: '', value: ''})
        setTownsAux(towns.filter(town => town.parent_value === target.value))
    }

    const handleSelectTownChange = (target)=>{
        setFormState({
            ...formState,
            billing: {...formState.billing,
                'town': target.label
            }
        })
        setTownAux(towns.filter(town => town.value === target.value))
    }

    const handleEdit = async(e) => {
        e.preventDefault()
        setIslogging(true)
        dispatch(editUserClub(formState, clubselected, null, null, null, null))
        setIslogging(false)
    }

    useEffect(() => {
        if(resultuser){
            let newresult = resultuser
            dispatch({
                type: 'USER_RESET_RESULT'
            })
            switch (newresult) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'success',
                        text: t('club.clubedited')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [resultuser])

    return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <HeadConfigClub nuevaSeccion="billingdata"/>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <CardBody>
                            <h2>{t('club.editbilling')}</h2>
                            <hr className='mb-4'/>
                            <Form className="formulario" onSubmit={handleEdit}>
                                <Row className="my-3">
                                    <Col xs={12} lg={6}>
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                className={formState?.billing?.nif ? 'has-content' : ''}
                                                type='text'
                                                name='nif' 
                                                value={formState?.billing?.nif ||  ''}
                                                onChange={handleInputChange}
                                                required
                                            />
                                            <Form.Label htmlFor='nif'>{t('club.nif')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                className={formState?.billing?.businessName ? 'has-content' : ''}
                                                type='text'
                                                name='businessName' 
                                                value={formState?.billing?.businessName ||  ''}
                                                onChange={handleInputChange}
                                                required
                                            />
                                            <Form.Label htmlFor='businessName'>{t('club.businessname')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="my-3">
                                    <Col xs={12} lg={6}>
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                className={formState?.billing?.address ? 'has-content' : ''}
                                                type='text'
                                                name='address' 
                                                value={formState?.billing?.address ||  ''}
                                                onChange={handleInputChange}
                                                required
                                            />
                                            <Form.Label htmlFor='address'>{t('club.address')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                className={formState?.billing?.postalCode ? 'has-content' : ''}
                                                type='text'
                                                name='postalCode' 
                                                value={formState?.billing?.postalCode ||  ''}
                                                onChange={handleInputChange}
                                                required
                                            />
                                            <Form.Label htmlFor='postalCode'>{t('club.postalCode')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="my-3">
                                    <Col xs={12} lg={6}>
                                        <Form.Group className='form-group'>
                                            <Select
                                                className="form-control select has-content" 
                                                name={'province'}
                                                options={provinces}
                                                value={provinceAux || null}
                                                onChange={(e) => handleSelectProvinceChange(e)}
                                                required
                                            />
                                            <input
                                                tabIndex={-1}
                                                autoComplete="off"
                                                style={{ opacity: 0, height: 0 }}
                                                value={formState?.billing?.province || ''}
                                                required
                                                readOnly
                                            />
                                            <Form.Label htmlFor='province'>{t('users.addresses.province')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <Form.Group className='form-group'>
                                            <Select
                                                className="form-control select has-content"
                                                name={'town'}
                                                options={townsAux}
                                                value={townAux || null}
                                                onChange={(e) => handleSelectTownChange(e)}
                                                required
                                            />
                                            <input
                                                tabIndex={-1}
                                                autoComplete="off"
                                                style={{ opacity: 0, height: 0 }}
                                                value={formState?.billing?.town || ''}
                                                required
                                                readOnly
                                            />
                                            <Form.Label htmlFor='town'>{t('users.addresses.town')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="mt-5">
                                    <Col xs={12} lg={6} className='ms-auto'>
                                        <Button variant='primary' type="submit" className="w-100 my-2">
                                        {isUpdating
                                            ?
                                                <>
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </>
                                            :
                                                <>{t('global.update')}</>
                                        }
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}
