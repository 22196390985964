import { Button, Col, Form, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import Select from "react-select"
import CompleteSchedule from "../../design/Schedule/CompleteSchedule"
import { useEffect, useState } from "react"

export default function ZoneForm({sites, state, usertype, isAddingOrEditing, clubs, submit, inputChange, checkboxChange, selectChange, cancelBtn, action, removeSchedule, addSchedule, handleDayCheck, onScheduleInputChange, onPeriodChange, handleRemoveBlockedRange, handleAddBlockedRange}) {
    const { t } = useTranslation()

    const [intervals, setIntervals] = useState([])

    useEffect(() => {
        let intervalValues = []

        for (let index = 15; index < 65; index += 5) {
            intervalValues.push(index)
        }

        intervalValues.push(75)
        intervalValues.push(90)
        intervalValues.push(120)

        setIntervals(intervalValues)
    }, [])

    return (
        <Form onSubmit={submit}>
            {usertype === 'superadmin' ?
                    <Row>
                        <Col xs={12} className="my-3">
                            <Form.Group className='form-group'>
                                <Select
                                    className='has-content'
                                    type='text'
                                    name='club'
                                    options={clubs}
                                    value={{ value: state?.club?.value || '', label: state?.club?.label }}
                                    onChange={(e) => selectChange(e, 'club')}
                                    required
                                />
                                <Form.Label>{t('sites.club')}</Form.Label>
                            </Form.Group>
                        </Col>
                    </Row>
                :
                    <></>
            }
            <Row className="my-3">
                <Col xs={12} lg={6}>
                    <Form.Group className='form-group'>
                        <Form.Control 
                            className={state?.name ? 'has-content' : ''}
                            type='text'
                            name='name'
                            value={state?.name ||  ''}
                            onChange={inputChange}
                            required
                        />
                        <Form.Label htmlFor='name'>{t('sites.name')}</Form.Label>
                    </Form.Group>
                </Col>
                <Col xs={12} lg={6}>
                    <Form.Group>
                        <Form.Label htmlFor='active'>{t(`sites.status`)}</Form.Label>
                        <div className='d-flex ms-4'>
                            <Form.Check
                                type="switch"
                                name="active"
                                onChange={checkboxChange}
                                checked={state?.active || false}
                            />
                            <span className="ms-3">
                                {state?.active
                                ?
                                    <>{t('sites.active')}</>
                                :
                                    <>{t('sites.inactive')}</>
                                }
                            </span>
                        </div>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="my-3">
                <Col xs={12} lg={6}>
                    <Form.Group className='form-group'>
                        <Select
                            className="form-control has-content"
                            name='site'
                            options={sites}
                            value={state?.site || []}
                            onChange={(e) => selectChange(e, 'site')}
                            required
                        />
                        <Form.Label htmlFor='site'>{t('zones.site')}</Form.Label>
                    </Form.Group>
                </Col>
                <Col xs={12} lg={6}>
                    <Form.Group className="form-group">
                        <div className='d-flex ms-4'>
                            <Form.Check
                                type="switch"
                                name="rentable"
                                onChange={checkboxChange}
                                checked={state?.rentable || false}
                            />
                            <span className="ms-3">
                                {state?.rentable
                                ?
                                    <>{t('zones.rentable')}</>
                                :
                                    <>{t('zones.notRentable')}</>
                                }
                            </span>
                        </div>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="my-3">
                <Col lg={6} xs={12}>
                    <Form.Group className="form-group">
                        <Form.Select 
                            className="form-control has-content"
                            name="interval" 
                            value={state.interval || 'null'} 
                            onChange={inputChange}
                        >
                            <option value={"null"}>{t('schedule.undefined')}</option>
                            {intervals.map(interval => (
                                <option key={interval} value={interval}>{interval} {t('schedule.minutes')}</option>
                            ))}
                        </Form.Select>
                        <Form.Label htmlFor='interval'>{t('schedule.interval')}</Form.Label>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <CompleteSchedule value={state.schedule} removeSchedule={removeSchedule} addSchedule={addSchedule} handleDayCheck={handleDayCheck} onScheduleInputChange={onScheduleInputChange} onPeriodChange={onPeriodChange} handleRemoveBlockedRange={handleRemoveBlockedRange} handleAddBlockedRange={handleAddBlockedRange}/>
                </Col>
            </Row>
            <Row className="my-3">
                <Col xs={6}>
                    {cancelBtn}
                </Col>
                <Col xs={6}>
                    {isAddingOrEditing
                        ?
                            //TODO:
                            'CARGANDO'
                        :
                            <Button type="submit" variant="primary" className='mt-3 w-100'>
                                {
                                    action === "add"
                                        ?
                                            t('global.add')
                                        :
                                            t('global.edit')
                                }
                            </Button>
                    }
                </Col>
            </Row>
        </Form>
    )    
}
