import React, { useEffect, useState } from 'react'
import { Container, Row, Col,Card, Button,Form } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import Select from "react-select"
import CategoryForm from '../../../../components/forms/activities/CategoryForm'
import { getActivitiesByClub, getActivity } from '../../../../actions/activities/activitiesActions'
import { addCategory, importCategories } from '../../../../actions/activities/categoriesActions'

export default function CategoryAdd({clubselected, activityselected, isNew}) { 
  	const { t } = useTranslation()
	const dispatch = useDispatch()

	const {loadingcategory} = useSelector(state=>state.categories)
	const {activities} = useSelector(state=>state.activities)

    const [newCategory, setNewCategory] = useState()
    const [activitiesList, setActivitiesList] = useState()
    const [activityToImport, setActivityToImport] = useState(null)

    useEffect(()=>{
        if(activityselected){
            dispatch(getActivity(activityselected))
            dispatch(getActivitiesByClub(clubselected))

            setNewCategory({
                title: '',
                ageFrom: 0,
                ageTo: 50,
                active: true
            })
        }
    //eslint-disable-next-line
    }, [dispatch, activityselected])

    useEffect(()=>{
        if (activities) {
                if (activities.length > 0) {
                const options = activities.map(activity => ({
                    value: activity._id,
                    label: activity.title
                }))
                setActivitiesList(options)
            }
        }
    //eslint-disable-next-line
    }, [activities])

    const handleInputChange = ({target})=>{
        setNewCategory({
            ...newCategory,
            [target.name]: target.value
        })
    }

    const handleCheckboxChange = ({target}) => {
        setNewCategory({
            ...newCategory,
            [target.name]: target.checked
        })
    }

    const handleSliderChange = (low, high) => {
        setNewCategory({
            ...newCategory,
            ageFrom: low,
            ageTo: high
        })
    }
    
    const selectActivityImport = (target) => {
        setActivityToImport(target.value)
    }

    const handleImport = async(e) => {
        e.preventDefault()

        dispatch(importCategories(activityToImport, activityselected))
    }

    const handleAdd = async(e) => {
        e.preventDefault()

        dispatch(addCategory({...newCategory, activity: activityselected}))
    }

	return (
        <Container className='cuerpo'>
            {isNew
            ?
            <Card>               
                <Row>
                    <Col>
                        <CategoryForm state={newCategory} statetype={'add'} usertype={'club'} isAdding={loadingcategory} submit={handleAdd} inputChange={handleInputChange} checkboxChange={handleCheckboxChange} sliderChange={handleSliderChange}/>                    
                    </Col>
                </Row>
            </Card>
            :
            <Card className='mt-3'>                
                <Row className="my-2">
                    <Col>
                        <Form.Group className='form-group'>
                            <Select
                                className="form-control has-content"
                                type='text'
                                name='sport'
                                options={activitiesList}
                                onChange={(e) => selectActivityImport(e)}
                                required
                            />
                            <Form.Label htmlFor='active'>{t('categories.activity')}</Form.Label>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="my-2">
                    <Col>
                        <Button variant="primary" className="w-100 my-2" onClick={(e) => handleImport(e)}>
                            {t('categories.import')}
                        </Button>
                    </Col>
                </Row>
            </Card>
            }
        </Container>
	)
}