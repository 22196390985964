import { createContext, useState, useEffect } from "react"
import { useNavigate } from 'react-router-dom'
import { jwtDecode } from 'jwt-decode'
import Axios from 'axios'
import Swal from 'sweetalert2'
import { useDispatch } from "react-redux"

export const AuthContext = createContext()

export default function AuthProvider({children}) {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    let token = sessionStorage.getItem('jwt')

    const [clientauth, setClientauth] = useState()

    /**
     * Loguea un usuario y genera el token
     * @param {*} userCredentials
     */
    const loginClient = async (userCredentials) => {
        const options = {
            headers: { "Access-Control-Allow-Origin": "*" },
        }
        const result = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/login/in', { username: userCredentials.username, password: userCredentials.password }, options)
        if(result?.data?.message !== 'notverified'){
            sessionStorage.setItem('jwt', result.data['token'])
            token = sessionStorage.getItem('jwt')

            if (token!=='undefined') {
                setClientauth(jwtDecode(token))
                let roles = jwtDecode(token).roles
                let hasSuperadminRole = roles.some(role => role.profileType === 'Superadmin')

                if(hasSuperadminRole){
                    navigate('/admin/dashboard')
                }else{
                    navigate('/client/dashboard')
                }
            } else {
                // Mostramos el popup informativo
                Swal.fire({
                    showConfirmButton: true,
                    confirmButtonColor: '#3085d6',
                    buttonsStyling: false,                    
                    confirmButtonText: 'Cerrar',
                    icon: 'error',
                    text: 'Credenciales incorrectas'
                }).then(()=>{
                    window.location.reload(false)
                })           
            }
        }else{
            navigate('/activation/'+result?.data?.idUser)
        }
    }

    // Desloguea un usuario eliminando token y seteando el usuario a null
    const logout = () => {
        setClientauth(null);
        dispatch({ type: 'USER_LOGOUT'})
        sessionStorage.removeItem('jwt')
        navigate('/')
    }

    // Comprueba el estado del usuario y devuelve true o false
    const isLogged = () => !!clientauth;

    // Variables accesibles desde cualquier componente
    const contextValue = {clientauth, isLogged, loginClient, logout}

    /**
     * Usamos el useEffect para comprobar que el token no ha cambiado
     */
    // token = sessionStorage.getItem('jwt')
    useEffect(() =>{
        try {
            setClientauth(jwtDecode(token))
        } catch (error) {
            setClientauth(null);
            sessionStorage.removeItem('jwt')
        }
    }, [token])

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    )
}
