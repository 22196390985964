import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Card} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import CourtFormStep2 from '../../../../components/forms/courts/CourtFormStep2'
import { addCourtSchedulesAndBlockedDates } from '../../../../actions/sites/courtsActions'

export default function AddCourtStep2({selectedCourt}) {
  	const { t } = useTranslation()
	const dispatch = useDispatch()
    const navigate = useNavigate()

    const {result, loading} = useSelector(state => state.courts)

    const [courtData, setCourtData] = useState({
        days: [
            {weekDays: ['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'], startDate: null, endDate: null, startHour: null, endHour: null, price: null},
        ],
        datesBlocked: [],
        interval: 'null'
    })
    

    const handleDayCheck = (target, index) => {
        setCourtData({
            ...courtData,
            days: courtData?.days?.map((day, i) => {
                if (i === index) {
                    if (target.checked) {
                        if (target.name !== 'all') {
                            return {
                                ...day,
                                weekDays: [...day.weekDays, target.name]
                            }
                        } else {
                            return {
                                ...day,
                                weekDays: ['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
                            }
                        }
                    } else {
                        if (target.name !== 'all') {
                            return {
                                ...day,
                                weekDays: day.weekDays.filter(d => (d !== target.name && d !== "all"))
                            } 
                        } else {
                            return {
                                ...day,
                                weekDays: []
                            }
                        }
                    }
                } else {
                    return day
                }
            })
        })
    }

    const addSchedule = () => {
        setCourtData({
            ...courtData,
            days: [...courtData.days, {weekDays: ['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'], startDate: null, endDate: null, startHour: null, endHour: null, price: null},]
        })
    }

    const removeSchedule = (day, index) => {
        let deletedScheduleId = courtData.days.find((d, i) => i === index)._id
        setCourtData({
            ...courtData,
            days: courtData.days.filter((d, i) => i !== index),
            deletedDays: deletedScheduleId ? [...courtData.deletedDays, deletedScheduleId] : courtData.deletedDays
        })
    }

    const handleScheduleInputChange = (target, index) => {
        setCourtData({
            ...courtData,
            days: courtData.days.map((d, i) => {
                if (i === index) {
                    return {
                        ...d,
                        [target.name]: target.value
                    }
                } else {
                    return d
                }
            })
        })
    }

    const handleAddBlockedRange = (range) => {
        setCourtData({
            ...courtData,
            datesBlocked: [...courtData.datesBlocked, {startDate: range[0], endDate: range[1]}]
        })
    }

    const handleRemoveBlockedRange = (index) => {
        let deletedBlockedRangeId = courtData.datesBlocked.find((d, i) => i === index)._id
        setCourtData({
            ...courtData,
            datesBlocked: courtData.datesBlocked.filter((d, i) => i !== index),
            deletedDatesBlocked: deletedBlockedRangeId ? [...courtData.deletedDatesBlocked, deletedBlockedRangeId] : courtData.deletedDatesBlocked
        })
    }

    const handleAdd = async(e) => {
        e.preventDefault()

        dispatch(addCourtSchedulesAndBlockedDates(courtData, selectedCourt))
    }

    const handleInputChange = ({target})=>{
        setCourtData({
            ...courtData,
            [target.name]: target.value
        })
    }

    const handleSchedulePeriodChange = (value, index) => {
        setCourtData({
            ...courtData,
            days: courtData.days.map((d, i) => {
                if (i === index) {
                    return {
                        ...d,
                        startDate: value[0],
                        endDate: value[1]
                    }
                } else {
                    return d
                }
            })
        })
    }

    useEffect(() => {
        if(result){
            switch (result) {
                case 'success':
                    navigate('/club/courts')
                    dispatch({
                        type: 'COURT_RESET_RESULT'
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [result])

	return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <h1 className='ms-2 my-0'>{t('sites.sites')}</h1>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <h2>{t('sites.createSite')}</h2>
                            <hr className='mb-4'/>
                            <CourtFormStep2 state={courtData} statetype={'add'} usertype={'club'} isAdding={loading} addSchedule={addSchedule} removeSchedule={removeSchedule} submit={handleAdd} handleDayCheck={handleDayCheck} handleScheduleInputChange={handleScheduleInputChange} handleAddBlockedRange={handleAddBlockedRange} handleRemoveBlockedRange={handleRemoveBlockedRange} inputChange={handleInputChange} onPeriodChange={handleSchedulePeriodChange} cancelBtn={<Link to="/club/courts/new"><Button variant="default" className="w-100 mt-3">{t('global.back')}</Button></Link>}/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
	)
}