import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Button, Row, Col, Card, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getCategoriesByActivity, removeCategory } from '../../../../actions/activities/categoriesActions'
import { CustomDataTable } from '../../../../components/design/Datatables/CustomDataTable'
import useAuth from '../../../../context/auth/useAuth'
import CategoryAdd from './CategoryAdd'
import Swal from 'sweetalert2'
import { getActivity } from '../../../../actions/activities/activitiesActions'

export default function Categories({clubselected, activityselected, handleCategorySelect}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
	const {clientauth} = useAuth()

    const [isNew,setIsNew] = useState(true)
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)

    const openModal = (typeModal) => {
        if(typeModal==='newCategory'){
            setIsNew(true)
        }else{
            setIsNew(false)
        }
        setShow(true)
    }

    const {categories, resultcategory} = useSelector(state=>state.categories)
    const {activity} = useSelector(state=>state.activities)

    useEffect(() => {
        if(clientauth){
            dispatch(getCategoriesByActivity(activityselected))
            dispatch(getActivity(activityselected))
        }
    },[dispatch, t, clientauth, activityselected])

    const handleRemoveCategory = async(e, categoryId) => {
        e.preventDefault()

        dispatch(removeCategory(categoryId))
    }

    useEffect(() => {
        if(resultcategory){
            let newresult = resultcategory
            dispatch({
                type: 'CATEGORY_RESET_RESULT'
            })
            switch (newresult) {
                case 'successaddcategory':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('categories.addsuccess')
                    }).then(() => {
                        handleClose()
                    })
                    break;
                case 'successimport':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('categories.importsuccess')
                    }).then(() => {
                        handleClose()
                    })
                    break;
                case 'deletecategorysuccess':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('categories.deletecategorysuccess')
                    })
                    break;
                case 'deletecategorywrong':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.ok'),
                        icon: 'success',
                        text: t('categories.deletecategorywrong')
                    })
                    break;
                case 'deletecategorynotexist':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.ok'),
                        icon: 'success',
                        text: t('categories.deletecategorynotexist')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [resultcategory])

	const columnsCategories = [
        {
            name: t('categories.title'),
            cell: category =>
                <Link onClick={() => handleCategorySelect(category._id)} to={`/club/inscriptions`} className='table-link'>
                    {category.title}
                </Link>,
            selector: (category) => category.title,
            sortable: true,
        },{
            name: t('categories.agefrom'),
            cell: category =>
                <Link onClick={() => handleCategorySelect(category._id)} to={`/club/inscriptions`} className='table-link'>
                    {category.ageFrom} {t('global.years')}
                </Link>,
            selector: (category) => category.ageFrom,
            sortable: true,
        },{
            name: t('categories.ageto'),
            cell: category =>
                <Link onClick={() => handleCategorySelect(category._id)} to={`/club/inscriptions`} className='table-link'>
                    {category.ageTo} {t('global.years')}
                </Link>,
            selector: (category) => category.ageTo,
            sortable: true,
        },{
			name: t('global.actions'),
            center: 'true', 
            width: '120px',
			cell: category =>
                <OverlayTrigger
                    placement="bottom"
                    trigger='focus'
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <Tooltip className='menu-puntos text-left'>
                            <Link onClick={() => handleCategorySelect(category._id)} to={`/club/editcategory`} className='dropdown-item'>
                                <img src="/images/edit.svg" alt="pencil" width={15}></img> {t('global.edit')}
                            </Link>
                            <Link onClick={() => handleCategorySelect(category._id)} to={`/club/inscriptions`} className='dropdown-item'>
                                <img src="/images/lupa.svg" alt="pencil" width={15}></img> {t('inscriptions.inscriptionlist')}
                            </Link>
                            <Link onClick={(e) => handleRemoveCategory(e, category._id)} className='dropdown-item'>						
                                <img src="/images/trash.svg" alt="trash" width={15} /> {t('global.delete')}
					        </Link>
                        </Tooltip>
                    }
                    >
                    <Button variant="puntos"><img src='/images/menupuntos.svg' alt='Menu auxiliar'/></Button>
                </OverlayTrigger>,
			sortable: false,
		}
	]

    return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <h1 className='ms-2 my-0'>{activity?.title}</h1>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <h2>{t('categories.categorylist')}</h2>
                            <hr className='mb-4'/>
                            <div className='buttons d-flex justify-content-end'>                                
                                <Button variant='secondary' onClick={() => openModal('newCategory')}>
                                    <i className='fas fa-plus'></i> {t('categories.createnewcategory')}
                                </Button>
                                <Button variant='secondary' className='ms-2 btn-outline' onClick={() => openModal('importCategory')}>
                                    <i className='fas fa-plus'></i> {t('categories.importcategory')}
                                </Button>
                            </div>
                            <div className="my-3">
                                <Row>
                                    <Col>
                                        {categories?.length > 0 ?
                                            <CustomDataTable columns={columnsCategories} data={categories} exportable={false} printable={false}/>
                                        :
                                            <div className='contenedor-vacio text-center'>
                                                <h4>{t('categories.thereisnotcategories')}</h4>
                                                {t('categories.thereisnotcategoriesphrase')}
                                                <div className='mt-3'>
                                                    <Button variant='secondary' onClick={() => openModal('newCategory')}>
                                                        <i className='fas fa-plus'></i> {t('categories.createnewcategory')}
                                                    </Button>
                                                </div>
                                            </div>
                                        }
                                    </Col>
                                </Row>
                                <Row className="mt-5">
                                    <Col xs={12} lg={6}>
                                        <Link to="/club/activities" className="btn btn-default my-2 w-100">
                                            {t('global.return')}
                                        </Link>
                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h2>
                            {isNew
                            ?
                                <>{t('categories.createnewcategory')}</>
                            :
                                <>{t('categories.importcategory')}</>
                            }
                            
                        </h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CategoryAdd clubselected={clubselected} activityselected={activityselected} isNew={isNew}/>
                </Modal.Body>
            </Modal>
        </Container>
    )
}
