import React, { useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import useAuth from '../../context/auth/useAuth'
import { useDispatch, useSelector } from 'react-redux'
import { getClub } from '../../actions/profiles/clubsActions'

export default function Dashboard({clubselected}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
	const {clientauth} = useAuth()

    const {club} = useSelector(state=>state.clubs)

    useEffect(() => {
        if(clientauth && clubselected){
            dispatch(getClub(clubselected))
        }
    },[dispatch, t, clientauth, clubselected])

    return (
        <Container className='p-5'> 
            <Row>
                <Col>
                    <div className='sd-label-dashboard'>{t('club.dashboard.welcome')}</div>
                    <div className="sd-label-userdashboard">{club?.name}</div>
                </Col>
            </Row>
            <Row className='mb-5'>
                <Col>
                    <h3>{t('club.dashboard.subwelcome')}</h3>
                </Col>
            </Row>
        </Container>
    )
}