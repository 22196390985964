import React, { useEffect } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from "react-i18next"
import { getClubsPublic } from '../../actions/profiles/clubsActions'
import { getActivitiesPublic } from '../../actions/activities/activitiesActions'
import SearchGeneric from '../../components/design/Search/SearchGeneric'
import MenuMarketplace from '../../components/menus/MenuMarketplace'
import Grid from '../../components/design/Grid/Grid'
import { FooterMarketplace } from '../../components/footers/FooterMarketplace'
import BannerFullWidth from '../../components/Banners/BannerFullWidth'
import Suscribete from '../../components/Banners/Subscribe'
import UsersRegistered from '../../components/Banners/UsersRegistered'
import BannerPhrase from '../../components/Banners/BannerPhrase'
import BannerPhrase2 from '../../components/Banners/BannerPhrase2'

export default function Home() {
    const { t } = useTranslation()	
    const dispatch = useDispatch()

    const {activities} = useSelector(state=>state.activities)

    useEffect(() =>{
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        dispatch(getClubsPublic())
        dispatch(getActivitiesPublic())
        
    }, [dispatch])
    return (
        <div>
            <div className='firstView d-flex align-items-center justify-content-center'>
                <div className="video-background">
                    <video autoPlay loop muted>
                        <source src="/videos/video-presentacion.mp4" type="video/mp4" />
                        Tu navegador no soporta la reproducción de video.
                    </video>
                </div>
                <div className='position-relative d-flex flex-column align-items-center text-center w-75'>
                    <figure className='mb-5'><img src='/images/logo-marketplace.svg' alt="Logotipo Incrono" style={{width: '250px'}} /></figure>
                    <h2 className='font-generic text-white'>{t('marketplace.home.phrase1')}</h2>
                    <h4 className='font-generic fw-light w-50 text-white'>{t('marketplace.home.phrase2')}</h4>
                    <SearchGeneric ancho="100%"/>
                    <h4 className='font-generic w-50 text-white mt-5 mb-1'>{t('marketplace.home.phrase3')}</h4>
                    <h4 className='font-generic fw-light w-50 text-white'>{t('marketplace.home.phrase4')}</h4>
                    <Button variant='invert'>
                        {t('marketplace.home.discoverincrono')}
                    </Button>
                </div>                
            </div>
            <MenuMarketplace className='m-0'/>
            <div className='marketplace border-bottom border-2 border-dark'>
                <Container className='market'>
                    <Row>
                        <Col xs='12' lg='6'>
                            <h2 className='display-6' dangerouslySetInnerHTML={{ __html: t('marketplace.clubsmoresearch') }} />
                            <img src='/images/clubsmoresearch.png' alt="Club más buscados" className='img-fluid'/>
                        </Col>
                        <Col xs='12' lg='6'>
                        </Col>
                    </Row>
                    <Row>
                        <Col>                        
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='marketplace mb-0'>
                <Container className='market-border'>
                    <Row>
                        <Col xs='12'>
                            <h2 className='display-6 text-center'>{t('marketplace.lastactivities') }</h2>                            
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Grid type="activity" data={activities} />
                        </Col>
                    </Row>
                </Container>
            </div>
            <BannerFullWidth />
            <div className='marketplace mb-0'>
                <Container className='market'>
                    <Row>
                        <Col xs='12 my-5 pt-5'>
                            <h2 className='display-6 text-center'>{t('marketplace.userexperience') }</h2>                            
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Grid type="opinion"  />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='marketplace mb-0'>
                <Container className='market h-100'>
                    <Row className='h-100'>
                        <Col xs={12} lg={8} className='columna1 d-flex flex-column'>
                            <Suscribete />
                        </Col>
                        <Col xs={12} lg={4} className='columna2 d-flex flex-column'>
                            <Row className='flex-grow-0'>
                                <Col>
                                    <UsersRegistered />
                                </Col>
                            </Row>
                            <Row className='mt-3 flex-grow-1 d-flex'>
                                <Col className='d-flex flex-column'>
                                    <BannerPhrase 
                                        className='flex-grow-1' 
                                        phrase={t('marketplace.suscribetitle')}
                                        image="/images/banner-incrono01.jpg"
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='marketplace mb-0'>
                <Container className='market h-100'>
                    <Row className='my-3'>
                        <Col xs={6}>
                            <UsersRegistered />
                        </Col>
                        <Col xs={6}>
                            <BannerPhrase2
                                phrase={t('marketplace.banners.yoursport')}
                                image="/images/banner-incrono01.jpg"
                            />
                        </Col>
                    </Row>
                    <Row className='h-100'>
                        <Col xs={12} className='columna1 d-flex flex-column'>
                            <Suscribete />
                        </Col>
                    </Row>
                </Container>
            </div>
            <FooterMarketplace className='m-0'/>
        </div>
    )
}