import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Row, Col, Card, CardBody, Form, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { CustomDataTable } from '../../../../components/design/Datatables/CustomDataTable'
import useAuth from '../../../../context/auth/useAuth'
import { changeAreaStatus, getAreasByClub, removeArea } from '../../../../actions/sites/areasActions'
import SitesSubMenu from '../../../../components/menus/SitesSubMenu'
import Swal from 'sweetalert2'

export default function Areas({clubselected, handleAreaSelect}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
	const {clientauth} = useAuth()

    const {areas, result} = useSelector(state=>state.areas)

    useEffect(() => {
        handleAreaSelect(null)
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if(clientauth){
            dispatch(getAreasByClub(clubselected))
        }
        //eslint-disable-next-line
    },[dispatch, t, clientauth])

    useEffect(() => {
        if(result?.message){
            switch (result.message) {
                case 'editedSuccess':
                    dispatch(getAreasByClub(clubselected))
                    dispatch({
                        type: 'AREA_RESET_RESULT'
                    })
                    break;
                case 'deleteAreaWrongRerservations':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.accept'),
                        icon: 'warning',
                        text: t('areas.deleteAreaWrongRerservations')
                    })
                    dispatch({
                        type: 'AREA_RESET_RESULT'
                    })
                    break;
                default:
                    break;
            }
        }
        //eslint-disable-next-line
    }, [result, dispatch])

    const handleStatusChange = (areaId, status) => {
        dispatch(changeAreaStatus(areaId, status))
    }

    const handleRemoveArea = (e, areaId) => {
        e.preventDefault()

        dispatch(removeArea(areaId))
    }

	const columnsAreas = [{
            name: t('areas.name'),
            cell: area =>
                <Link onClick={() => handleAreaSelect(area._id)} to={`/club/areas/edit`} className='table-link'>
                    {area.name}
                </Link>,
            selector: (area) => area.name,
            sortable: true,
        },{
            name: t('areas.court'),
            cell: area =>
                <div>
                    {area.court?.name}
                </div>,
            selector: (area) => area.court?.name,
            sortable: true,
        },{
            name: t('areas.zone'),
            cell: area =>
                <div>
                    {area.court?.zone?.name}
                </div>,
            selector: (area) => area.court?.zone?.name,
            sortable: true,
        },{
            name: t('areas.site'),
            cell: area =>
                <div>
                    {area.court?.zone?.site?.name}
                </div>,
            selector: (area) => area.court?.zone?.site?.name,
            sortable: true,
        },{
            name: t('areas.status'),
            center: 'true',
            width: '100px',
            cell: area =>
                <div>
                    <Form.Check
                        type='switch'
                        checked={area.active}
                        onChange={({target}) => (handleStatusChange(area._id, target.checked))}
                    />
                </div>,
            selector: (area) => area.active,
            sortable: false,
        },{
			name: t('global.actions'), 
            center: 'true',
            width:'120px',
			cell: area =>
                <OverlayTrigger
                    placement="bottom"
                    trigger='focus'
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <Tooltip className='menu-puntos text-left'>
                            <Link onClick={() => handleAreaSelect(area._id)} to={`/club/areas/edit`} className='dropdown-item'>
                                <img src="/images/edit.svg" alt={t('sites.dataTitle')} width={15}></img> {t('global.edit')}
                            </Link>
                            <Link onClick={() => handleAreaSelect(area._id)} to={`/club/areas/edit/step2`} className='dropdown-item'>
                                <img src="/images/menus/calendar.svg" alt={t('sites.dataTitle')} width={15}></img> {t('schedule.schedule')}
                            </Link>
                            <Link onClick={(e) => handleRemoveArea(e, area._id)} className='dropdown-item'>						
                                <img src="/images/trash.svg" alt="trash" width={15} /> {t('global.delete')}
					        </Link>                            
                        </Tooltip>
                    }
                    >
                    <Button variant="puntos"><img src='/images/menupuntos.svg' alt='Menu auxiliar'/></Button>
                </OverlayTrigger>,
			sortable: false,
		}
	]

    return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <SitesSubMenu active="areas"/>
                </Col>
            </Row>
            <Row className='my-3'>
                <Col>
                    <Card>
                        <CardBody>
                            <h2>{t('areas.areasList')}</h2>
                            <hr className='mb-4'/>
                            <div className='buttons d-flex justify-content-end'>
                                <Link to="/club/areas/new" className='btn btn-secondary'>
                                    <i className='fas fa-plus'></i> {t('areas.createArea')}
                                </Link>
                            </div>
                            <div className='my-3'>
                                <Row>
                                    <Col>
                                        {
                                            areas?.length > 0
                                                ?
                                                    <CustomDataTable columns={columnsAreas} data={areas} exportable={false} printable={false} />
                                                :
                                                    <div className='contenedor-vacio text-center'>
                                                        <h4>{t('areas.noAreas')}</h4>
                                                        {t('areas.thereIsNoAreasPhrase')}
                                                        <div className='mt-3'>
                                                            <Link to="/club/areas/new" className='btn btn-secondary'>
                                                                {t('areas.createArea')}
                                                            </Link>
                                                        </div>
                                                    </div>
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}