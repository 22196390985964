import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import GroupForm from '../../../../components/forms/activities/GroupForm'
import { addGroup, getGroupsByInscription } from '../../../../actions/activities/groupsActions'

export default function GroupAdd({inscriptionselected}) { 
	const dispatch = useDispatch()

	const {loadinggroup} = useSelector(state=>state.groups)

    const [newGroup, setNewGroup] = useState()

    useEffect(()=>{
        if(inscriptionselected){
            dispatch(getGroupsByInscription(inscriptionselected))

            setNewGroup({
                name: '',
                places: 0,
                trainer: '',
                days: [{
                    weekDays: ['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
                    startHour: null,
                    endHour: null
                }]
            })
        }
    //eslint-disable-next-line
    }, [dispatch, inscriptionselected])
 
    const handleDayCheck = (target, position) => {
        setNewGroup({
            ...newGroup,
            days: newGroup.days.map((day, index) => {
                if(index === position){
                    if(target.checked){
                        if(target.name !== 'all'){
                            return {
                                ...day,
                                weekDays: [...day.weekDays, target.name]
                            }
                        }else{
                            return {
                                ...day,
                                weekDays: ['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
                            }
                        }
                    }else{
                        if(target.name !== 'all'){
                            return {
                                ...day,
                                weekDays: day.weekDays.filter(dayFilter => (dayFilter !== target.name && dayFilter !== "all"))
                            } 
                        }else{
                            return {
                                ...day,
                                weekDays: []
                            }
                        }
                    }
                }else{
                    return day
                }
            })
        })
    }

    const addSchedule = () => {
        setNewGroup({
            ...newGroup,
            days: [...newGroup.days, {weekDays: ['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'], startHour: null, endHour: null}]
        })
    }

    const removeSchedule = (day, position) => {
        setNewGroup({
            ...newGroup,
            days: newGroup.days.filter((d, i) => i !== position)
        })
    }

    const handleScheduleInputChange = (target, day) => {
        setNewGroup({
            ...newGroup,
            days: newGroup.days.map(d => {
                if (d.weekDays === day) {
                    return {
                        ...d,
                        [target.name]: target.value
                    }
                } else {
                    return d
                }
            })
        })
    }

    const handleInputChange = ({target})=>{
        setNewGroup({
            ...newGroup,
            [target.name]: target.value
        })
    }

    const handleCheckboxChange = ({target}) => {
        setNewGroup({
            ...newGroup,
            [target.name]: target.checked
        })
    }

    const handleAdd = async(e) => {
        e.preventDefault()

        dispatch(addGroup({...newGroup, inscription: inscriptionselected}))
    }

	return (
        <Container className='cuerpo'>
            <Card>
                <Row>
                    <Col>
                        <GroupForm state={newGroup} statetype={'add'} usertype={'club'} isAdding={loadinggroup} submit={handleAdd} inputChange={handleInputChange} checkboxChange={handleCheckboxChange} addSchedule={addSchedule} removeSchedule={removeSchedule} handleDayCheck={handleDayCheck} handleScheduleInputChange={handleScheduleInputChange}/>                    
                    </Col>
                </Row>
            </Card>
        </Container>
	)
}