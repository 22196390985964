import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function Opinion({opinion}) {
    const { t } = useTranslation()
   
    return (
        <Card className="miniatura">
            <Card.Body>                
                <Row className="mt-3">
                    <Col className="d-flex">
                        <div className='img-opinion me-3'>
                            
                        </div> 
                        <div>
                            <h4 className="my-1">Sencilla y rápida</h4>
                            <div>Alfonso Tena</div>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="d-flex">
                        { [1,2,3,4,5].map((i) => (
                            <img src="/images/star.svg" alt="Star" className="me-1"/>
                            ))
                        }
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col className="d-flex align-items-center justify-content-between flex-wrap">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}