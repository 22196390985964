import AxiosControl from '../../context/axios/AxiosControl'
const Axios = AxiosControl()

export const getSitesByClub = (club) => async (dispatch) => {
    dispatch({
        type: 'SITE_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/site/getSitesByClub/'+ club, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'SITE_GETALL_SUCCESS',
                payload: data.sites
            })
        }
    } catch (error) {
        dispatch({
            type: 'SITE_NOT_LOADING'
        })
    }
}

export const getSite = (siteId) => async (dispatch) => {
    dispatch({
        type: 'SITE_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/site/getSite/'+ siteId, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'SITE_GET_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'SITE_NOT_LOADING'
        })
    }
}

export const addSite = (sitedata) => async (dispatch) => {
    dispatch({
        type: 'SITE_LOADING'
    })
    try {
        if(sitedata.image !== null && sitedata.image !== undefined&& sitedata.image !== '' && typeof sitedata.image !== 'string'){
            let formData = new FormData()
            formData.append('image', sitedata.image)

            const {data} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/upload/uploadimage', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`,
                    auth: sessionStorage.getItem('jwt')
                }
            })
            sitedata.image = data
        }

        const { data, status} = await Axios.post('/api/site/register', sitedata, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'SITE_ADD_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'SITE_NOT_LOADING'
        })
    }
}

export const addSiteSchedulesAndBlockedDates = (sitedata, id) => async (dispatch) => {
    dispatch({
        type: 'SITE_LOADING'
    })
    try {
        const { data, status} = await Axios.put('/api/site/addScheduleAndBlockedDates/' + id, sitedata, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'SITE_ADD_SUCCESS',
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: 'SITE_NOT_LOADING'
        })
    }
}

export const editSiteManagerData = (managerData, id) => async (dispatch) => {
    dispatch({
        type: 'SITE_LOADING'
    })
    try {
        const { data, status} = await Axios.put('/api/site/editSiteManagerData/' + id, managerData, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'SITE_ADD_SUCCESS',
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: 'SITE_NOT_LOADING'
        })
    }
}

export const changeSiteStatus = (id, activeStatus) => async (dispatch) => {
    dispatch({
        type: 'SITE_LOADING'
    })
    try {
        const { data, status} = await Axios.put('/api/site/changeStatus/' + id, {active: activeStatus}, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'SITE_EDIT_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'SITE_NOT_LOADING'
        })
    }
}

export const editSite = (siteData, siteId) => async (dispatch) => {
    dispatch({
        type: 'SITE_LOADING'
    })
    try {
        if(siteData.image !== null && siteData.image !== undefined && siteData.image !== '' && typeof siteData.image !== 'string'){
            let formData = new FormData()
            formData.append('image', siteData.image)

            const {data} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/upload/uploadimage', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`,
                    auth: sessionStorage.getItem('jwt')
                }
            })
            siteData.image = data
        }

        const { data, status} = await Axios.post('/api/site/edit/' + siteId, siteData, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'SITE_ADD_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'SITE_NOT_LOADING'
        })
    }
}

export const removeSite = (siteId) => async (dispatch) => {
    try {
        const { data, status } = await Axios.delete('/api/site/remove/' + siteId, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200 || status === 263) {
            dispatch({
                type: 'SITE_REMOVE_RESULT',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'SITE_NOT_LOADING'
        })
    }
}