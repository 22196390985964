import React from "react"
import { Spinner } from "react-bootstrap"


export default function Loader() {
    return (
        <div className="d-flex justify-content-center align-items-center mt-5 pt-5">
            <Spinner animation="grow"/>
        </div>
    )
}