import { createRef } from 'react'
import { Button, Col, Form, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import Select from "react-select"

export default function SiteForm({provinces, state, statetype, usertype, isAddingOrEditing, clubs, sports, services, submit, inputChange, checkboxChange, selectChange, handleImage, cancelBtn}) {
    const { t } = useTranslation()
    const fileRef = createRef("<HTMLInputElement>")

    return (
        <Form onSubmit={submit}>
            <Row className="mt-3 mb-4">
                <Col xs={12} lg={6} className='d-flex align-items-center'>
                    <div className='avatar-cuadrado me-2'>
                        {state?.image
                            ?
                                <img className="img-profile" alt="Preview" src={typeof state?.image === 'string' ? state?.image : URL.createObjectURL(state?.image)} />
                            :
                                <div className="img-profile"></div>
                        }
                        <input 
                            className='custom-file-input' 
                            style={{ display: 'none' }}
                            ref={fileRef}
                            type="file" 
                            name="myImage" 
                            onChange={handleImage} 
                        />
                    </div>
                    <div className="editaravatar" onClick={() => fileRef.current?.click()}>{t('sites.avatar')}</div>
                </Col>
            </Row>
            {usertype === 'superadmin' ?
                    <Row>
                        <Col xs={12} className="my-3">
                            <Form.Group className='form-group'>
                                <Select
                                    className='has-content'
                                    type='text'
                                    name='club'
                                    options={clubs}
                                    value={{ value: state?.club?.value || '', label: state?.club?.label }}
                                    onChange={(e) => selectChange(e, 'club')}
                                    required
                                />
                                <Form.Label>{t('sites.club')}</Form.Label>
                            </Form.Group>
                        </Col>
                    </Row>
                :
                    <></>
            }
            <Row className="my-3">
                <Col xs={12} lg={6}>
                    <Form.Group className='form-group'>
                        <Form.Control 
                            className={state?.name ? 'has-content' : ''}
                            type='text'
                            name='name'
                            value={state?.name ||  ''}
                            onChange={inputChange}
                            required
                        />
                        <Form.Label htmlFor='name'>{t('sites.name')}</Form.Label>
                    </Form.Group>
                </Col>
                <Col xs={12} lg={6}>
                    <Form.Group>
                        <Form.Label htmlFor='active'>{t(`sites.status`)}</Form.Label>
                        <div className='d-flex ms-4'>
                            <Form.Check
                                type="switch"
                                name="active"
                                onChange={checkboxChange}
                                checked={state?.active || false}
                            />
                            <span className="ms-3">
                                {state?.active
                                ?
                                    <>{t('sites.active')}</>
                                :
                                    <>{t('sites.inactive')}</>
                                }
                            </span>
                        </div>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="my-3">
                <Col xs={12} lg={6}>
                    <Form.Group className='form-group'>
                        <Select
                            className="form-control has-content"
                            name='sports'
                            options={sports}
                            value={state?.sports || []}
                            onChange={(e) => selectChange(e, 'sports')}
                            isMulti
                            required
                        />
                        <Form.Label htmlFor='sports'>{t('sites.sports')}</Form.Label>
                    </Form.Group>
                </Col>
                <Col xs={12} lg={6}>
                    <Form.Group className="form-group">
                        <Select
                            className="form-control has-content"
                            name='province'
                            options={provinces}
                            value={state?.province || []}
                            onChange={(e) => selectChange(e, 'province')}
                            required
                        />
                        <Form.Label htmlFor='province'>{t('sites.province')}</Form.Label>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="my-3">
                <Col xs={12} lg={6}>
                    <Form.Group className='form-group'>
                        <Form.Control 
                            className={state?.town ? 'has-content' : ''}
                            type='text'
                            name='town'
                            value={state?.town ||  ''}
                            onChange={inputChange}
                            required
                        />
                        <Form.Label htmlFor='town'>{t('sites.town')}</Form.Label>
                    </Form.Group>
                </Col>
                <Col xs={12} lg={6}>
                    <Form.Group className='form-group'>
                        <Form.Control 
                            className={state?.town ? 'has-content' : ''}
                            type='text'
                            name='address'
                            value={state?.address ||  ''}
                            onChange={inputChange}
                            required
                        />
                        <Form.Label htmlFor='address'>{t('sites.address')}</Form.Label>
                    </Form.Group>
                </Col>
            </Row>
            <Row className='my-3'>
                <Col xs={12} lg={6}>
                    <Form.Group className='form-group'>
                        <Select
                            className="form-control has-content"
                            name='services'
                            options={services}
                            value={state?.services || []}
                            onChange={(e) => selectChange(e, 'services')}
                            required
                            isMulti
                        />
                        <Form.Label htmlFor='services'>{t('sites.services')}</Form.Label>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="my-3">
                <Col xs={6}>
                    {cancelBtn}
                </Col>
                <Col xs={6}>
                    {isAddingOrEditing
                        ?
                            //TODO:
                            'CARGANDO'
                        :
                            <Button type="submit" variant="primary" className='mt-3 w-100'>
                                {t('global.update')}
                            </Button>
                    }
                </Col>
            </Row>
        </Form>
    )    
}
