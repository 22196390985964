import { Button, Col, Form, Row, Toast } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css'
import 'react-calendar/dist/Calendar.css'
import { useState } from "react"
import SimpleSchedule from "../../design/Schedule/SimpleSchedule"

export default function SiteFormStep2({state, isAddingOrEditing, submit, handleDayCheck, addSchedule, removeSchedule, handleScheduleInputChange, handleAddBlockedRange, handleRemoveBlockedRange, cancelBtn}) {
    const { t } = useTranslation()

    const [blockedDateRange, setBlockedDateRange] = useState([null, null])

    return (
        <Form onSubmit={submit}>
            <SimpleSchedule title={t('sites.scheduleTitle')} value={state.days} removeSchedule={removeSchedule} addSchedule={addSchedule} handleDayCheck={handleDayCheck} onScheduleInputChange={handleScheduleInputChange}/>
            <Row className="mt-5 mb-3">
                <Col xs={12}>
                    {t('sites.datesBlockedTitle')}
                    <hr className="fina" />
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <Form.Label className="me-2">{t('sites.selectDates')}</Form.Label><br/>
                    <DateRangePicker onChange={value => setBlockedDateRange(value)} value={blockedDateRange}/>
                    <Button variant="primary" className="ms-3 py-1" role="button" onClick={() => {setBlockedDateRange([null, null]); handleAddBlockedRange(blockedDateRange)}}><i className="fas fa-plus"/></Button>
                    <Row className="my-3">
                        <Col className="d-flex align-items-center flex-wrap">
                        {
                            state.datesBlocked.map((dateRange, index) => (
                                <div className="m-1" key={index}>
                                    <Toast onClose={() => handleRemoveBlockedRange(index)}>
                                        <Toast.Header>
                                            {`${dateRange.startDate?.toLocaleDateString('es-ES', {day: '2-digit', month: '2-digit', year: 'numeric'})} - ${dateRange.endDate?.toLocaleDateString('es-ES', {day: '2-digit', month: '2-digit', year: 'numeric'})}`}
                                        </Toast.Header>
                                    </Toast>
                                </div>
                            ))
                        }
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="my-3">
                <Col xs={6}>
                    {cancelBtn}
                </Col>
                <Col xs={6}>
                    {isAddingOrEditing
                        ?
                            //TODO:
                            'CARGANDO'
                        :
                            <Button type="submit" variant="primary" className='mt-3 w-100'>
                                {t('global.update')}
                            </Button>
                    }
                </Col>
            </Row>
        </Form>
    )    
}
