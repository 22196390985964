import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { thunk } from 'redux-thunk'
import { usersReducer } from './reducers/profiles/usersReducer'
import { redirectReducer } from './reducers/redirectReducer'
import { logsReducer } from './reducers/logsReducer'
import { superadminsReducer } from './reducers/profiles/superadminsReducer'
import { clubsReducer } from './reducers/profiles/clubsReducer'
import { clientsReducer } from './reducers/profiles/clientsReducer'
import { activitiesReducer } from './reducers/activities/activitiesReducer'
import { categoriesReducer } from './reducers/activities/categoriesReducer'
import { inscriptionsReducer } from './reducers/activities/inscriptionsReducer'
import { groupsReducer } from './reducers/activities/groupsReducer'
import { sitesReducer } from './reducers/sites/sitesReducer'
import { zonesReducer } from './reducers/sites/zonesReducer'
import { courtsReducer } from './reducers/sites/courtsReducer'
import { areasReducer } from './reducers/sites/areasReducer'

const intialState = {}
const appReducer = combineReducers({
    users: usersReducer,
    logs: logsReducer,
	redirectReducer: redirectReducer,
    superadmins: superadminsReducer,
    clubs: clubsReducer,
    clients: clientsReducer,
    activities: activitiesReducer,
    categories: categoriesReducer,
    inscriptions: inscriptionsReducer,
    groups: groupsReducer,
    sites: sitesReducer,
    zones: zonesReducer,
    courts: courtsReducer,
    areas: areasReducer
})

const reducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        return appReducer(undefined, action)
    }
    return appReducer(state, action)
}

const composeEnhancer = window._REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(reducer, intialState, composeEnhancer(applyMiddleware(thunk)))

export default store