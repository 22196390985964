export const areasReducer = (state = {loading: false, result: null, areas: [], area: null}, action) => {
    switch (action.type) {
        case 'AREA_LOADING':
            return {
                ...state,
                loading: true
            }
        case 'AREA_NOT_LOADING':
            return {
                ...state,
                loading: false
            }
        case 'AREA_GETALL_SUCCESS':
            return {
                ...state,
                loading: false,
                areas: action.payload
            }
        case 'AREA_GET_SUCCESS':
            return {
                ...state,
                loading: false,
                area: action.payload
            }
        case 'AREA_ADD_SUCCESS':
            return {
                ...state,
                loading: false,
                result: action.payload
            }
        case 'AREA_EDIT_SUCCESS':
            return {
                ...state,
                loading: false,
                result: action.payload
            }
        case 'AREA_REMOVE_RESULT':
            return {
                ...state,
                loading: false,
                result: action.payload,
                areas: action.payload.message === "deleteAreaSuccess" ? state.areas.filter(s => s._id !== action.payload.area) : state.areas
            }
        case 'AREA_RESET_RESULT':
            return {
                ...state,
                result: null
            }
        default:
            return state
    }
}