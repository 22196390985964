import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import CategoryForm from '../../../../components/forms/activities/CategoryForm'
import { getActivity } from '../../../../actions/activities/activitiesActions'
import { getCategory, updateCategory } from '../../../../actions/activities/categoriesActions'

export default function CategoryEdit({activityselected, categoryselected}) { 
  	const { t } = useTranslation()
	const dispatch = useDispatch()
    const navigate = useNavigate()

	const {activity} = useSelector(state=>state.activities)
	const {category, resultcategory, loadingcategory} = useSelector(state=>state.categories)

    const [editCategory, setEditCategory] = useState()

    useEffect(()=>{
        if(activityselected){
            dispatch(getActivity(activityselected))
            dispatch(getCategory(categoryselected))
        }
    //eslint-disable-next-line
    }, [dispatch, activityselected, categoryselected])

    useEffect(()=>{
        if(activity && category){
            setEditCategory({
                title: category?.title,
                ageFrom: category?.ageFrom,
                ageTo: category?.ageTo,
                active: category?.active
            })
        }
    //eslint-disable-next-line
    }, [activity, category])

    const handleInputChange = ({target})=>{
        setEditCategory({
            ...editCategory,
            [target.name]: target.value
        })
    }

    const handleCheckboxChange = ({target}) => {
        setEditCategory({
            ...editCategory,
            [target.name]: target.checked
        })
    }

    const handleSelectChange = (target, selectType) => {
        setEditCategory({
            ...editCategory,
            [selectType]: target.value
        })
    }

    const handleSliderChange = (low, high) => {
        setEditCategory({
            ...editCategory,
            ageFrom: low,
            ageTo: high
        })
    }

    const handleAdd = async(e) => {
        e.preventDefault()

        dispatch(updateCategory(editCategory, categoryselected))
    }

    useEffect(() => {
        if(resultcategory){
            let newresult = resultcategory
            dispatch({
                type: 'CATEGORY_RESET_RESULT'
            })
            switch (newresult) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('categories.editedsuccess')
                    }).then(() => {
                        navigate('/club/categories')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [resultcategory])

	return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <h1 className='ms-2 my-0'>{activity?.title}</h1>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <h2>{t('categories.editcategory')}</h2>
                            <hr className='mb-4'/>
                            <Row>
                                <Col>
                                    <CategoryForm state={editCategory} statetype={'edit'} usertype={'club'} isAdding={loadingcategory} submit={handleAdd} inputChange={handleInputChange} checkboxChange={handleCheckboxChange} selectChange={handleSelectChange} sliderChange={handleSliderChange}/>
                                </Col>
                            </Row>                            
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
	)
}