import React from 'react'
import { useTranslation } from 'react-i18next'

export default function NotFoundPage () {
    const { t } = useTranslation()

	return (
		<div>
			{t('global.error404')}
			<hr/>
			{t('global.error404content')}
			<hr/>
			{t('global.error404description')}
		</div>
	)
}