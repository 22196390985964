import { Button, Col, Form, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom'
import Select from "react-select"
import "rc-slider/assets/index.css"
import DateRangePicker from "@wojtekmaj/react-daterange-picker"
import { useEffect, useState } from "react"

export default function InscriptionForm({state, statetype, usertype, isAddingOrEditing, submit, inputChange, checkboxChange, daterangeChange}) {
    const { t } = useTranslation()
    const recoveryDays = 5 // TODO: Usar parámetros del Club o Incrono

    const [registrationPaymentAux, setRegistrationPayAux] = useState({"value": false, "label": t('inscriptions.registrationpaymentfalse')})
    const [seasonPayDaysQuantity, setSeasonPayDaysQuantity] = useState(0)
    const [seasonPayDays, setSeasonPayDays] = useState([])
    const [seasonPayAux, setSeasonPayAux] = useState({"value": false, "label": t('inscriptions.seasonpayfalse')})
    const [initialized, setInitialized] = useState(false)

    useEffect(() => {
        if (state && !initialized) {
            if(state?.seasonPayments?.length > 0){
                setSeasonPayAux({"value": true, "label": t('inscriptions.seasonpaytrue')})
                setSeasonPayDaysQuantity(state?.seasonPayments?.length)
                setSeasonPayDays(state?.seasonPayments)
            }

            if(state?.registrationPayment !== 0){
                setRegistrationPayAux({"value": true, "label": t('inscriptions.registrationpaymenttrue')})
            }

            setInitialized(true)
        }
    // eslint-disable-next-line
    }, [state, initialized])

    const handleRegistrationPayAux = (target) => {
        setRegistrationPayAux({"value": target.value, "label": t('inscriptions.registrationpayment'+target.value)})

        inputChange({target: {name: 'registrationPayment', value: 0}})
    }

    const handleSeasonPayAux = (target) => {
        setSeasonPayAux({"value": target.value, "label": t('inscriptions.seasonpay'+target.value)})
    }

    const handleSeasonPayDaysQuantity = ({target}) => {
        setSeasonPayDaysQuantity(target.value)
        
        if(target.value > seasonPayDays.length){
            const newDays = [...seasonPayDays]
            for(let i = seasonPayDays.length; i < target.value; i++){
                newDays.push({ date: '', price: 0, priceAfterDatePay: 0 })
            }
            setSeasonPayDays(newDays)
        } else {
            setSeasonPayDays(seasonPayDays.slice(0, target.value))
        }
    }

    const handleSeasonDayChange = (e, index, toChange) => {
        const newSeasonPayDays = [...seasonPayDays]
        if (!newSeasonPayDays[index]) {
            newSeasonPayDays[index] = {}
        }
        const value = e.target.value
        newSeasonPayDays[index][toChange] = value

        if (toChange === 'date') {
            const date = new Date(value)
            date.setDate(date.getDate() + recoveryDays)
            const alternativeDate = date.toISOString().split('T')[0]
            newSeasonPayDays[index]['alternativeDate'] = alternativeDate
        }
    
        setSeasonPayDays(newSeasonPayDays)
        inputChange({target: {name: 'seasonPayments', value: newSeasonPayDays}})
    }

    return (
        <Form onSubmit={submit}>
            <Row>
                <Col xs={12}>
                    <Form.Group className='form-group'>
                        <Form.Control 
                            className={state?.title ? 'has-content' : ''}
                            type='text'
                            name='title'
                            value={state?.title ||  ''}
                            onChange={inputChange}
                            required
                        />
                        <Form.Label htmlFor='title'>{t('inscriptions.title')}</Form.Label>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={6} className="my-3">
                    <Form.Group className="d-flex">
                        <Form.Label htmlFor='active'>{t('inscriptions.active')}</Form.Label>
                        <Form.Check
                            className="ms-3"
                            type="switch"
                            name="active"
                            onChange={checkboxChange}
                            checked={state?.active || false}
                        />
                        <span className="ms-3">
                            {state?.active
                            ?
                                <>{t('global.yes')}</>
                            :
                                <>{t('global.no')}</>
                            }
                        </span>
                    </Form.Group>
                </Col>
                <Col xs={12} lg={6} className="my-3">
                    <Form.Group className="d-flex">
                        <Form.Label htmlFor='verify'>{t('inscriptions.assignablegroup')}</Form.Label>
                        <Form.Check
                            className="ms-3"
                            type="switch"
                            name="assignableGroup"
                            onChange={checkboxChange}
                            checked={state?.assignableGroup || false}
                        />
                        <span className="ms-3">
                            {state?.assignableGroup
                            ?
                                <>{t('inscriptions.selectableroup')}</>
                            :
                                <>{t('inscriptions.unassignablegroup')}</>
                            }
                        </span>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={6} className="my-3">
                    <Form.Group className="d-flex">
                        <Form.Label htmlFor='verify'>{t('inscriptions.verify')}</Form.Label>
                        <Form.Check
                            className="ms-3"
                            type="switch"
                            name="verify"
                            onChange={checkboxChange}
                            checked={state?.verify || false}
                        />
                        <span className="ms-3">
                            {state?.verify
                            ?
                                <>{t('global.yes')}</>
                            :
                                <>{t('global.no')}</>
                            }
                        </span>
                    </Form.Group>
                </Col>
                <Col xs={12} lg={6} className="my-3">
                    <Form.Group className="d-flex">
                        <Form.Label htmlFor='federated'>{t('inscriptions.federated')}</Form.Label>
                        <Form.Check
                            className="ms-3"
                            type="switch"
                            name="federated"
                            onChange={checkboxChange}
                            checked={state?.federated || false}
                        />
                        <span className="ms-3">
                            {state?.federated
                            ?
                                <>{t('global.yes')}</>
                            :
                                <>{t('global.no')}</>
                            }
                        </span>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="my-3">
                <Col xs={12} lg={6} className="my-3">                    
                    <Form.Label className="me-3">{t('inscriptions.seasonlength')}</Form.Label><br/>
                    <DateRangePicker onChange={(value) => {daterangeChange(value, 'season')}} value={[state?.seasonStart, state?.seasonEnd]}/>
                </Col>
                <Col xs={12} lg={6} className="my-3">
                    <Form.Label className="me-3">{t('inscriptions.registrationlength')}</Form.Label><br/>
                    <DateRangePicker onChange={(value) => {daterangeChange(value, 'registration')}} value={[state?.registrationStart, state?.registrationEnd]}/>
                </Col>
            </Row>
            <Row>
                <Col xs={6} lg={3} className="my-3">
                    <Form.Group className='form-group'>
                        <Select
                            className="form-control select has-content"
                            options={[
                                {"value": false, "label": t('global.no')},
                                {"value": true, "label": t('global.yes')}
                            ]}
                            name='registrationpaymentaux'
                            value={registrationPaymentAux}
                            onChange={handleRegistrationPayAux}
                        />
                        <Form.Label htmlFor='registrationpaymentaux'>{t('inscriptions.thereisregistrationpayment')}</Form.Label>
                    </Form.Group>
                </Col>
                <Col xs={6} lg={3} className="my-3">
                    {registrationPaymentAux?.value ?   
                        <Form.Group className='form-group'>
                            <Form.Control
                                className='has-content text-right'
                                type="text"
                                name='registrationPayment'
                                value={state?.registrationPayment || 0}
                                onChange={inputChange}
                                required
                            />
                            <Form.Label htmlFor='registrationPayment'>{t('inscriptions.registrationpayment')}</Form.Label>
                            <img src="/images/money.svg" alt="Money" className="icono-money" />
                        </Form.Group>
                    
                    :
                        ''
                    }
                </Col>
                <Col xs={6} lg={3} className="my-3">
                    <Form.Group className='form-group'>
                        <Select
                            className="form-control select has-content"
                            options={[
                                {"value": false, "label": t('global.no')},
                                {"value": true, "label": t('global.yes')}
                            ]}
                            name='recirringpayaux'
                            value={seasonPayAux}
                            onChange={handleSeasonPayAux}
                        />
                        <Form.Label htmlFor='gender'>{t('inscriptions.thereisseasonpayment')}</Form.Label>
                    </Form.Group>
                </Col>
                <Col xs={6} lg={3} className="my-3">
                        {seasonPayAux?.value ?
                            
                                <Form.Group className='form-group'>
                                    <Form.Control
                                        className='has-content'
                                        type="number"
                                        name='seasonpaydays'
                                        value={seasonPayDaysQuantity}
                                        onChange={handleSeasonPayDaysQuantity}
                                        required
                                    />
                                    <Form.Label htmlFor='seasonPay'>{t('inscriptions.seasonpaydays')}</Form.Label>
                                </Form.Group>
                        :
                            ''
                        }
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Row className='border-bottom border-1 border-dark pb-2 mb-3'>
                        <Col xs={1}>{t('inscriptions.payment')}</Col>
                        <Col xs={8}>{t('inscriptions.seasonDay')}</Col>
                        <Col>{t('inscriptions.priceclub')}</Col>
                        <Col>{t('inscriptions.priceclient')}</Col>
                    </Row>
                    {seasonPayDaysQuantity > 0 ?
                        (() => {
                            const rows = []
                            for (let index = 0; index < seasonPayDaysQuantity; index++) {
                                rows.push(
                                    <Row key={index}>
                                        <Col xs={1} className="d-flex align-items-center justify-content-center">
                                            {index+1}
                                        </Col>
                                        <Col xs={8}>
                                                <Form.Control
                                                    className='has-content'
                                                    type='date'
                                                    name='seasonDay' 
                                                    value={seasonPayDays[index]?.date ||  ''}
                                                    onChange={(e) => handleSeasonDayChange(e, index, 'date')}
                                                    required
                                                />    
                                        </Col>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Control
                                                    className="has-content"
                                                    type="number"
                                                    name='seasonPrice'
                                                    value={seasonPayDays[index]?.price ||  0}
                                                    onChange={(e) => handleSeasonDayChange(e, index, 'price')}
                                                    required
                                                />
                                                <img src="/images/money.svg" alt="Money" className="icono-money" />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            Calculadora
                                        </Col>
                                    </Row>
                                )
                            }
                            return rows
                        })()
                    :
                        ''
                    }
                </Col>
            </Row>
            {isAddingOrEditing ?
                //TODO:
                'CARGANDO'
            :
                <Row>
                    <Col xs={12} lg={6}>
                        <Link  to="/club/inscriptions" className="btn btn-default mt-3 w-100">
                            {t('global.cancel')}
                        </Link>
                    </Col>
                    <Col xs={12} lg={6}>
                        <Button type="submit" variant="primary" className='mt-3 w-100'>
                        {statetype === 'add' 
                        ?    
                            t('global.add')
                        :
                            t('global.edit')
                        }
                        </Button>
                    </Col>
                </Row>
            }
        </Form>
    )    
}
