import { Navigate } from "react-router-dom"
import { jwtDecode } from 'jwt-decode'

const PrivateClientRouter = ({ children }) => {
    const token = sessionStorage.getItem('jwt')

    if (token && token !== 'undefined') {
        let token_decode
        try {
            token_decode = jwtDecode(token)
        } catch (error) {
            console.error("Error decoding token:", error)
            return <Navigate to="/"/>
        }

        const hasClientRole = token_decode.roles && token_decode.roles.some(role => role.profileType === 'Client')
        if (hasClientRole) {
            return children
        } else {
            return <Navigate to="/"/>
        }
    } else {
        return <Navigate to="/"/>
    }
}

export default PrivateClientRouter