import { Button, Col, Form, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css'
import 'react-calendar/dist/Calendar.css'
import { useEffect, useState } from "react"
import CompleteSchedule from "../../design/Schedule/CompleteSchedule"

export default function CourtFormStep2({state, statetype, isAddingOrEditing, submit, handleDayCheck, addSchedule, removeSchedule, handleScheduleInputChange, handleAddBlockedRange, handleRemoveBlockedRange, cancelBtn, inputChange, onPeriodChange}) {
    const { t } = useTranslation()

    const [intervals, setIntervals] = useState([])

    useEffect(() => {
        let intervalValues = []

        for (let index = 15; index < 65; index += 5) {
            intervalValues.push(index)
        }

        intervalValues.push(75)
        intervalValues.push(90)
        intervalValues.push(120)

        setIntervals(intervalValues)
    }, [])

    return (
        <Form onSubmit={submit}>
            <Row className="mt-5 mb-3">
                <Col lg={6} xs={12}>
                    <Form.Group className="form-group">
                        <Form.Select 
                            className="form-control has-content"
                            name="interval" 
                            value={state.interval || 'null'} 
                            onChange={inputChange}>
                            <option value={"null"}>{t('schedule.undefined')}</option>
                            {intervals.map(interval => (
                                <option key={interval} value={interval}>{interval} {t('schedule.minutes')}</option>
                            ))}
                        </Form.Select>
                        <Form.Label htmlFor='interval'>{t('schedule.interval')}</Form.Label>
                    </Form.Group>
                </Col>
            </Row>
            <CompleteSchedule value={state} removeSchedule={removeSchedule} addSchedule={addSchedule} handleDayCheck={handleDayCheck} onScheduleInputChange={handleScheduleInputChange} onPeriodChange={onPeriodChange} handleRemoveBlockedRange={handleRemoveBlockedRange} handleAddBlockedRange={handleAddBlockedRange}/>
            <Row className="my-3">
                <Col xs={6}>
                    {cancelBtn}
                </Col>
                <Col xs={6}>
                    {isAddingOrEditing
                        ?
                            //TODO:
                            'CARGANDO'
                        :
                            <Button type="submit" variant="primary" className='mt-3 w-100'>
                                {
                                    statetype === "add"
                                        ?
                                            t('global.add')
                                        :
                                            t('global.edit')
                                }
                            </Button>
                    }
                </Col>
            </Row>
        </Form>
    )    
}
