import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Row, Col, Card, Button, OverlayTrigger, Tooltip} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { CustomDataTable } from '../../../../components/design/Datatables/CustomDataTable'
import useAuth from '../../../../context/auth/useAuth'
import Swal from 'sweetalert2'
import { getActivity } from '../../../../actions/activities/activitiesActions'
import { getCategory } from '../../../../actions/activities/categoriesActions'
import { getInscriptionsByCategory, removeInscription } from '../../../../actions/activities/inscriptionsActions'

export default function Inscriptions({activityselected, categoryselected, handleInscriptionSelect}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
	const {clientauth} = useAuth()

    const {inscriptions, resultinscription} = useSelector(state=>state.inscriptions)
    const {activity} = useSelector(state=>state.activities)
    const {category} = useSelector(state=>state.categories)

    useEffect(() => {
        if(clientauth){
            dispatch(getActivity(activityselected))
            dispatch(getCategory(categoryselected))
            dispatch(getInscriptionsByCategory(categoryselected))
        }
    },[dispatch, t, clientauth, activityselected, categoryselected])

    const handleRemoveInscription = async(e, inscriptionId) => {
        e.preventDefault()

        dispatch(removeInscription(inscriptionId))
    }

	const columnsInscriptions = [
        {
            name: t('inscriptions.title'),
            cell: inscription =>
                <Link onClick={() => handleInscriptionSelect(inscription._id)} to={`/club/groups`} className='table-link'>
                    {inscription.title}
                </Link>,
            selector: (inscription) => inscription.title,
            sortable: true,
        },{
            name: t('inscriptions.seasonlength'),
            cell: inscription => {
                let datestart = new Date(inscription?.seasonStart)
                let dateend = new Date(inscription?.seasonEnd)

                datestart.setHours(datestart.getHours() + 2)
                dateend.setHours(dateend.getHours() + 2)

                let formattedDateStart = [
                    String(datestart.getUTCDate()).padStart(2, '0'),
                    String(datestart.getUTCMonth() + 1).padStart(2, '0'),
                    datestart.getUTCFullYear()
                ].join('/')

                let formattedDateEnd = [
                    String(dateend.getUTCDate()).padStart(2, '0'),
                    String(dateend.getUTCMonth() + 1).padStart(2, '0'),
                    dateend.getUTCFullYear()
                ].join('/')

                return <Link onClick={() => handleInscriptionSelect(inscription._id)} to={`/club/groups`} className='table-link'>{formattedDateStart} - {formattedDateEnd}</Link>
            },
            selector: (inscription) => inscription.seasonStart,
            sortable: true,
        },{
            name: t('inscriptions.registrationlength'),
            cell: inscription => {
                let datestart = new Date(inscription?.registrationStart)
                let dateend = new Date(inscription?.registrationEnd)

                datestart.setHours(datestart.getHours() + 2)
                dateend.setHours(dateend.getHours() + 2)
            
                let formattedDateStart = [
                    String(datestart.getUTCDate()).padStart(2, '0'),
                    String(datestart.getUTCMonth() + 1).padStart(2, '0'),
                    datestart.getUTCFullYear()
                ].join('/')
            
                let formattedDateEnd = [
                    String(dateend.getUTCDate()).padStart(2, '0'),
                    String(dateend.getUTCMonth() + 1).padStart(2, '0'),
                    dateend.getUTCFullYear()
                ].join('/')
                
                return <Link onClick={() => handleInscriptionSelect(inscription._id)} to={`/club/groups`} className='table-link'>{formattedDateStart} - {formattedDateEnd}</Link>
            },
            selector: (inscription) => inscription.registrationStart,
            sortable: true,
        },{
			name: t('global.actions'), 
            center: 'true',
            width:'120px',
			cell: inscription =>
                <OverlayTrigger
                    placement="bottom"
                    trigger='focus'
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <Tooltip className='menu-puntos text-left'>
                            <Link onClick={() => handleInscriptionSelect(inscription._id)} to={`/club/editinscription`} className='dropdown-item'>
                                <img src="/images/edit.svg" alt={t('sites.dataTitle')} width={15}></img> {t('global.edit')}
                            </Link>
                            <Link onClick={() => handleInscriptionSelect(inscription._id)} to={`/club/groups`} className='dropdown-item'>
                                <img src="/images/menus/calendar.svg" alt={t('sites.dataTitle')} width={15}></img> {t('groups.grouplist')}
                            </Link>
                            <Link onClick={(e) => handleRemoveInscription(e, inscription._id)} className='dropdown-item'>						
                                <img src="/images/trash.svg" alt="trash" width={15} /> {t('global.delete')}
					        </Link>                            
                        </Tooltip>
                    }
                    >
                    <Button variant="puntos"><img src='/images/menupuntos.svg' alt='Menu auxiliar'/></Button>
                </OverlayTrigger>,
			sortable: false,
		}
	]

    useEffect(() => {
        if(resultinscription){
            let newresult = resultinscription
            dispatch({
                type: 'INSCRIPTION_RESET_RESULT'
            })
            switch (newresult) {
                case 'deleteinscriptionsuccess':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('inscriptions.deleteinscriptionsuccess')
                    })
                    break;
                case 'deleteinscriptionwrong':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.ok'),
                        icon: 'success',
                        text: t('inscriptions.deleteinscriptionwrong')
                    })
                    break;
                case 'deleteinscriptionnotexist':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.ok'),
                        icon: 'warning',
                        text: t('inscriptions.deleteinscriptionnotexist')
                    })
                    break;
                case 'clientsininscriptionalready':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.ok'),
                        icon: 'warning',
                        text: t('inscriptions.clientsininscriptionalready')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [resultinscription])

    return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <h1 className='ms-2 my-0'>{activity?.title} - {category?.title}</h1>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <h2>{t('inscriptions.inscriptionlist')}</h2>
                            <hr className='mb-4'/>
                            <div className='buttons d-flex justify-content-end'>
                                <Link to="/club/newinscription" className='btn btn-secondary'>
                                    <i className='fas fa-plus'></i> {t('inscriptions.createnewinscription')}
                                </Link>
                            </div>
                            <div className="my-3">
                                <Row>
                                    <Col>
                                        {inscriptions?.length > 0 ?
                                            <CustomDataTable columns={columnsInscriptions} data={inscriptions} exportable={false} printable={false} />
                                        :
                                            <div className='contenedor-vacio text-center'>
                                                <h4>{t('inscriptions.thereisnotinscriptions')}</h4>
                                                {t('inscriptions.thereisnotinscriptionsphrase')}
                                                <div className='mt-3'>
                                                    <Link to="/club/newinscription" className='btn btn-secondary'>
                                                        {t('inscriptions.createnewinscription')}
                                                    </Link>
                                                </div>
                                            </div>
                                        }
                                    </Col>
                                </Row>
                            </div>
                            <Row>
                                <Col xs={12} lg={6}>
                                    <Link  to="/club/categories" className="btn btn-default mt-3 w-100">
                                        {t('global.return')}
                                    </Link>
                                </Col>                                
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}
