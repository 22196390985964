import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Button, Row, Col, Card, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { CustomDataTable } from '../../../../components/design/Datatables/CustomDataTable'
import useAuth from '../../../../context/auth/useAuth'
import GroupAdd from './GroupAdd'
import Swal from 'sweetalert2'
import { getActivity } from '../../../../actions/activities/activitiesActions'
import { getCategory } from '../../../../actions/activities/categoriesActions'
import { getInscription } from '../../../../actions/activities/inscriptionsActions'
import { getGroupsByInscription, removeGroup } from '../../../../actions/activities/groupsActions'

export default function Groups({ activityselected, categoryselected, inscriptionselected, handleGroupSelect}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
	const {clientauth} = useAuth()

    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)

    const openModal = () => {
        setShow(true)
    }

    const {groups, resultgroup} = useSelector(state=>state.groups)
    const {activity} = useSelector(state=>state.activities)
    const {category} = useSelector(state=>state.categories)
    const {inscription} = useSelector(state=>state.inscriptions)

    useEffect(() => {
        if(clientauth){
            dispatch(getActivity(activityselected))
            dispatch(getCategory(categoryselected))
            dispatch(getInscription(inscriptionselected))
            dispatch(getGroupsByInscription(inscriptionselected))
        }
    },[dispatch, t, clientauth, activityselected, categoryselected, inscriptionselected])

    const handleRemoveGroup = async(e, groupId) => {
        e.preventDefault()

        dispatch(removeGroup(groupId))
    }

    useEffect(() => {
        if(resultgroup){
            let newresult = resultgroup
            dispatch({
                type: 'GROUP_RESET_RESULT'
            })
            switch (newresult) {
                case 'successaddgroup':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('groups.successaddgroup')
                    }).then(() => {
                        handleClose()
                    })
                    break;
                case 'groupdontexist':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.ok'),
                        icon: 'warning',
                        text: t('groups.groupdontexist')
                    })
                    break;
                case 'inscriptiondontexist':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.ok'),
                        icon: 'warning',
                        text: t('groups.inscriptiondontexist')
                    })
                    break;
                case 'deletegroupsuccess':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.ok'),
                        icon: 'success',
                        text: t('groups.deletegroupsuccess')
                    })
                    break;
                case 'clientsingroupalready':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.ok'),
                        icon: 'success',
                        text: t('groups.clientsingroupalready')
                    })
                    break;
                case 'deletegroupnotexist':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.ok'),
                        icon: 'success',
                        text: t('groups.deletegroupnotexist')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [resultgroup])

	const columnsGroups = [
        {
            name: t('groups.name'),
            cell: group =>
                <Link onClick={() => handleGroupSelect(group._id)} to={`/club/editgroup`} className='table-link'>
                    {group.name}
                </Link>,
            selector: (group) => group.name,
            sortable: true,
        },{
            name: t('groups.places'),
            cell: group =>
                <Link onClick={() => handleGroupSelect(group._id)} to={`/club/editgroup`} className='table-link'>
                    {group.places}
                </Link>,
            selector: (group) => group.places,
            sortable: true,
        },{
            name: t('groups.trainer'),
            cell: group =>
                <Link onClick={() => handleGroupSelect(group._id)} to={`/club/editgroup`} className='table-link'>
                    {group.trainer}
                </Link>,
            selector: (group) => group.trainer,
            sortable: true,
        },{
			name: t('global.actions'),
            center: 'true',
            width:'120px',
			cell: group =>
                <OverlayTrigger
                    placement="bottom"
                    trigger='focus'
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <Tooltip className='menu-puntos text-left'>
                            <Link onClick={() => handleGroupSelect(group._id)} to={`/club/editgroup`} className='dropdown-item'>
                                <img src="/images/edit.svg" alt={t('sites.dataTitle')} width={15}></img> {t('global.edit')}
                            </Link>
                            <Link onClick={(e) => handleRemoveGroup(e, group._id)} className='dropdown-item'>						
                                <img src="/images/trash.svg" alt="trash" width={15} /> {t('global.delete')}
					        </Link>                            
                        </Tooltip>
                    }
                    >
                    <Button variant="puntos"><img src='/images/menupuntos.svg' alt='Menu auxiliar'/></Button>
                </OverlayTrigger>,
			sortable: false,
		}
	]

    return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <h1 className='ms-2 my-0'>{activity?.title} - {category?.title} - {inscription?.title}</h1>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <h2>{t('groups.grouplist')}</h2>
                            <hr className='mb-4'/>
                            <div className='buttons d-flex justify-content-end'>                                
                                <Button variant='secondary' onClick={() => openModal()}>
                                    <i className='fas fa-plus'></i> {t('groups.createnewgroup')}
                                </Button>
                            </div>
                            <div className="my-3">
                                <Row>
                                    <Col>
                                        {groups?.length > 0 ?
                                            <CustomDataTable columns={columnsGroups} data={groups} exportable={false} printable={false} />
                                        :
                                            <div className='contenedor-vacio text-center'><h4>{t('groups.thereisnotgroups')}</h4></div>
                                        }
                                    </Col>
                                </Row>
                                <Row className="mt-5">
                                    <Col xs={12} lg={6}>
                                        <Link to="/club/inscriptions" className="btn btn-default my-2 w-100">
                                            {t('global.return')}
                                        </Link>
                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Modal show={show} size="lg" onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h2>{t('groups.createnewgroup')}</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <GroupAdd inscriptionselected={inscriptionselected}/>
                </Modal.Body>
            </Modal>
        </Container>
    )
}
