import {Fragment, useState} from 'react'
import Axios from 'axios'
import { useTranslation } from "react-i18next"
import Swal from 'sweetalert2'
import { Container, Row, Col, Button, Form} from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function Forgot() {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    
    /**
     * Comprueba los datos del formulario
     * @param {*} e evento
     */
    const handleSubmit = async (e) => {
        e.preventDefault()

        // Obtenemos el mail del usuario
        const clientEmail = e.target.email.value

        setIsLoading(true)

        // Enviamos la petición al /forgotpassword con el email
        await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/forgot/sendmail', {email: clientEmail})
            .then((res) => {
                setIsLoading(false)
                // Mostramos el popup informativo
                Swal.fire({
                    showConfirmButton: true,
                    icon: 'success',
                    text: 'Revise su email, se le ha enviado un enlace para crear una nueva contraseña',
                    target: document.getElementById('root')
                })
            }).catch((err) => {
                setIsLoading(false)
                // Mostramos el popup informativo
                Swal.fire({
                    showConfirmButton: true,
                    icon: 'error',
                    text: 'Ha habido un error al intentar enviar los datos, comprueba el correo introducido o vuelva a intentarlo más tarde'
                })
            })
    } 
    return (
        <Fragment>
            <header className="cabecera sticky-top d-flex align-items-center px-4">
                <Link to='/'><img src="/images/logotipo-incrono-cabecera.svg" alt="Logotipo Incrono" /></Link>
            </header>
            <Container fluid className='p-0 m-0'>
                <Row className='p-0 m-0 centro-auth'>
                    <Col md={7} className='d-none d-md-block aside-auth'>
                    </Col>
                    <Col xs={12} md={5} className='d-flex align-items-center p-5'>
                        <Container>
                            <Row>
                                <Col>
                                    <h1 className='mb-4'>{t('forgot.question')}</h1>
                                    {t('forgot.help')}
                                    <Form className="formulario" onSubmit={handleSubmit}>
                                        <Row className="my-3">
                                            <Col>
                                                <Form.Label htmlFor='email'>{t('forgot.email')}</Form.Label>
                                                <Form.Control name="email" className="login" type="email" autoComplete="off" />
                                            </Col>
                                        </Row>
                                        <Row className="my-3">
                                            <Col>
                                                <Button variant='primary' type="submit" id="kt_password_reset_submit" className="w-100">
                                                    {isLoading
                                                        ?
                                                        <>
                                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                        </>
                                                        :
                                                        <>{t('forgot.submit')}</>
                                                    }
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='text-center pt-4'>
                                                {t('forgot.rememberpassword')}<br/>
                                                <Link to="/login" className='btn btn-default w-100 my-2'>
                                                        {t('signin.signin')}                                                
                                                </Link>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )    
}
