import Home from '../pages/marketplace/Home'
import Clubs from '../pages/marketplace/Clubs'
import Club from '../pages/marketplace/Club'
import Activities from '../pages/marketplace/activities/Activities'
import Activity from '../pages/marketplace/activities/Activity'

// Mapeo de alias a paths reales
export const aliasMapMarketplace = {
    "": "home",
    "/": "home",
    "home": "home",
    "clubs": "clubs",
    "clubs/:clubUrl": "club",
    "activities": "activities",
    "clubs/:clubUrl/activities/:activityUrl": "activity"
}

// Función auxiliar para obtener el componente basado en el path real
export const getComponentMarketplace = (path) => {
    switch (path) {
        case "": return <Home />;
        case "home": return <Home />;
        case "clubs": return <Clubs />;
        case "club": return <Club />;
        case "activities": return <Activities />;
        case "activity": return <Activity />;
        default: return <Home />;
    }
}