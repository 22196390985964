import DataTable from "react-data-table-component"
import DataTableExtensions from 'react-data-table-component-extensions'
import "react-data-table-component-extensions/dist/index.css"
import { useTranslation } from "react-i18next"

export const CustomDataTable = (props) => {
    const { t } = useTranslation()

    const {columns, data, exportable, printable} = props
  
    const tableData = {
        columns: columns,
        data: data,
        exportHeaders: true,
        print: printable,
        export: exportable,
        filterPlaceholder: t('datatables.buscar'),
        filterDigit: 3
    }

    return (
        <>
            <DataTableExtensions {...tableData}>
                <DataTable
                    className="table table-sm table-striped table-bordered table-hover customtable"
                    noDataComponent={t('datatables.sinresultados')}
                    labelRowsPerPage={t('datatables.elementosporpagina')}
                    defaultSortField="id"
                    defaultSortAsc={false}              
                    pagination
                    paginationRowsPerPageOptions={[10, 25, 50, 100, 500]}
                    paginationPerPage = {25}
                    paginationComponentOptions={{ 
                        rowsPerPageText: t('datatables.elementosporpagina'),
                        rangeSeparatorText: t('datatables.de'),
                        noRowsPerPage: false,
                        selectAllRowsItem: true,
                        selectAllRowsItemText: t('datatables.todos')
                    }}
                />
            </DataTableExtensions>
        </>
  )
}