import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Card } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { editSiteManagerData } from '../../../../actions/sites/sitesActions'
import SiteFormStep3 from '../../../../components/forms/sites/SiteFormStep3'
import Swal from 'sweetalert2'

export default function AddSiteStipe3({selectedSite}) { 
  	const { t } = useTranslation()
	const dispatch = useDispatch()
    const navigate = useNavigate()

	const {club} = useSelector(state=>state.clubs)

    const {result, loading} = useSelector(state => state.sites)

    const [newSite, setNewSite] = useState({
        days: [
            {weekDays: ['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'], startHour: null, endHour: null},
        ],
        name: null,
        email: null,
        phoneNumber: null
    })

    useEffect(()=>{
        if(club){
            setNewSite({
                days: [
                    {weekDays: ['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'], startHour: null, endHour: null},
                ],
                name: null,
                email: null,
                phone: null
            })
        }
    //eslint-disable-next-line
    }, [club])

    const handleDayCheck = (target, index) => {
        setNewSite({
            ...newSite,
            days: newSite.days.map((day, i) => {
                if (i === index) {
                    if (target.checked) {
                        if (target.name !== 'all') {
                            return {
                                ...day,
                                weekDays: [...day.weekDays, target.name]
                            }
                        } else {
                            return {
                                ...day,
                                weekDays: ['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
                            }
                        }
                    } else {
                        if (target.name !== 'all') {
                            return {
                                ...day,
                                weekDays: day.weekDays.filter(d => (d !== target.name && d !== "all"))
                            } 
                        } else {
                            return {
                                ...day,
                                weekDays: []
                            }
                        }
                    }
                } else {
                    return day
                }
            })
        })
    }

    const addSchedule = () => {
        setNewSite({
            ...newSite,
            days: [...newSite.days, {weekDays: ['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'], startHour: null, endHour: null}]
        })
    }

    const removeSchedule = (day, index) => {
        setNewSite({
            ...newSite,
            days: newSite.days.filter((d, i) => i !== index)
        })
    }

    const handleScheduleInputChange = (target, day) => {
        setNewSite({
            ...newSite,
            days: newSite.days.map(d => {
                if (d.weekDays === day) {
                    return {
                        ...d,
                        [target.name]: target.value
                    }
                } else {
                    return d
                }
            })
        })
    }
    
    const handleInputChange = ({target}) => {
        setNewSite({
            ...newSite,
            [target.name]: target.value
        })
    }

    const handleAdd = async(e) => {
        e.preventDefault()

        dispatch(editSiteManagerData(newSite, selectedSite))
    }

    useEffect(() => {
        if(result){
            switch (result) {
                case 'success':
                    dispatch({
                        type: 'SITE_RESET_RESULT'
                    })
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('sites.addSuccess')
                    }).then(() => {
                        navigate('/club/sites')
                    })
                    
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [result])

	return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <h1 className='ms-2 my-0'>{t('sites.sites')}</h1>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <h2 className='fs-18'>{t('sites.createSite')}</h2>
                            <hr className='mb-4'/>
                            <SiteFormStep3 state={newSite} statetype={'add'} usertype={'club'} isAdding={loading} addSchedule={addSchedule} removeSchedule={removeSchedule} submit={handleAdd} handleDayCheck={handleDayCheck} handleScheduleInputChange={handleScheduleInputChange} handleInputChange={handleInputChange} cancelBtn={<Link to="/club/sites/new/step2"><Button variant="default" className="w-100 mt-3">{t('global.back')}</Button></Link>}/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
	)
}