import { Button, Col, Form, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom'
import SimpleSchedule from "../../design/Schedule/SimpleSchedule"

export default function GroupForm({state, statetype, usertype, isAddingOrEditing, submit, inputChange, checkboxChange, addSchedule, removeSchedule, handleDayCheck, handleScheduleInputChange}) {
    const { t } = useTranslation()
    
    return (
        <Form onSubmit={submit}>
            <Row className="my-3">
                <Col xs={12} lg={6}>
                    <Form.Group className='form-group'>
                        <Form.Control 
                            className={state?.name ? 'has-content' : ''}
                            type='text'
                            name='name'
                            value={state?.name ||  ''}
                            onChange={inputChange}
                            required
                        />
                        <Form.Label htmlFor='name'>{t('groups.name')}</Form.Label>
                    </Form.Group>
                </Col>
                <Col xs={12} lg={6}>
                    <Form.Group className='form-group'>
                        <Form.Control 
                            className={state?.trainer ? 'has-content' : ''}
                            type='text'
                            name='trainer'
                            value={state?.trainer ||  ''}
                            onChange={inputChange}
                        />
                        <Form.Label htmlFor='trainer'>{t('groups.trainer')}</Form.Label>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col className="my-3" xs={12} lg={statetype === 'add' ? 6 : 3}>
                    <Form.Group>
                        <Form.Label htmlFor='active'>{t(`groups.status`)}</Form.Label>
                        <div className='d-flex ms-4'>
                            <Form.Check
                                type="switch"
                                name="active"
                                onChange={checkboxChange}
                                checked={state?.active || false}
                            />
                            <span className="ms-3">
                                {state?.active
                                ?
                                    <>{t('groups.active')}</>
                                :
                                    <>{t('groups.inactive')}</>
                                }
                            </span>
                        </div>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mt-4 mb-3">
                <Col xs={12} lg={6}>
                    <Form.Group className='form-group'>
                        <Form.Control 
                            className={state?.places ? 'has-content' : ''}
                            type='number'
                            name='places'
                            value={state?.places ||  ''}
                            onChange={inputChange}
                            required
                        />
                        <Form.Label htmlFor='places'>{t('groups.places')}</Form.Label>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                {state?.days 
                ?
                    <SimpleSchedule title={t('groups.schedule')} value={state?.days} removeSchedule={removeSchedule} addSchedule={addSchedule} handleDayCheck={handleDayCheck} onScheduleInputChange={handleScheduleInputChange}/>
                :
                    ''
                }
                </Col>
            </Row>
            
            {isAddingOrEditing ?
                //TODO:
                'CARGANDO'
            :
                statetype === 'add' ?
                    <Row>
                        <Col xs={12}>
                            <Button type="submit" variant="primary" className='mt-3 w-100'>
                                {t('global.add')}
                            </Button>
                        </Col>
                    </Row>
                        
                :
                    <Row>
                        <Col xs={12} lg={6}>                                
                            <Link  to="/club/groups" className="btn btn-default mt-3 w-100">
                                {t('global.cancel')}
                            </Link>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Button type="submit" variant="primary" className='mt-3 w-100'>
                                {t('global.edit')}
                            </Button>
                        </Col>
                    </Row>
                        
            }
        </Form>
    )    
}
