export const clientsReducer = (state = {loadingclient: true, resultclient: null, clients: null, client: null } , action) => {
    switch (action.type) {
        case 'CLIENT_GET_SUCCESS':
            return {
                ...state,
                loadingclient:false,
                client: action.payload.client
            }
        case 'CLIENT_ADDMEMBER_SUCCESS':
            return {
                ...state,
                loading:false,
                resultclient: action.payload.message
            }
        case 'CLIENT_ACTIVATEDEACTIVATE_SUCCESS':
            return {
                ...state,
                loading:false,
                resultclient: action.payload.message
            }
        case 'CLIENT_RESET_RESULT':
            return {
                ...state,
                resultclient: null
            }
        case 'CLIENT_LOADING':
            return {
                ...state,
                loadingclient:true
            }
        case 'CLIENT_NOT_LOADING':
            return {
                ...state,
                loadingclient:false
            }
        default:
            return state
    }
}