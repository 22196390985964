import { useTranslation } from "react-i18next"
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Fragment } from "react"

export default function ResetPasswordSuccess() {
    const { t } = useTranslation()

    return (
        <Fragment>
            <header className="cabecera sticky-top d-flex align-items-center px-4">
                <Link to='/'><img src="/images/logotipo-incrono-cabecera.svg" alt="Logotipo Incrono" /></Link>
            </header>
            <Container fluid className='p-0 m-0'>
                <Row className='p-0 m-0 centro-auth'>   
                    <Col xs={12} className='d-flex align-items-center justify-content-center p-5 mx-auto'>
                        <Container>
                            <Row>
                                <Col className='text-center'>
                                    <img src="/images/verify.svg" alt="Password cambiado" />
                                    <h1 className="mt-5 mb-4">{t('passwordreset.passwordreset')}</h1>
                                    {t('passwordreset.restoresuccess')}
                                </Col>
                            </Row>                
                            <Row className="my-3">
                                <Col className='text-center'>
                                    <Link to="/login" className='btn btn-primary my-2 px-5'>
                                        {t('passwordreset.cancel')}
                                    </Link>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )    
}