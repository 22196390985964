import React from "react"
import Activity from "../../Activities/Activity"
import Club from "../../Club/Club"

import { Button, Col, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import Opinion from "../../Opinions/Opinion"


export default function Grid({type, data = [], showMoreBtnAction, }) {
    const { t } = useTranslation()
    return (
        <>
            <Row>
                {
                    type?.toLowerCase() === "activity" &&
                        data?.map((d, i) => (
                            <Col key={i} xs={12} sm={6} md={4} xxl={3}><Activity activity={d}/></Col>
                        ))
                }
                {
                    type?.toLowerCase() === "club" &&
                        data?.map((d, i) => (
                            <Col key={i} xs={12} sm={6} md={4} xxl={3}><Club club={d}/></Col>
                        ))
                }
                {
                    type?.toLowerCase() === "opinion" &&
                        // data?.map((d, i) => (
                        //     <Col key={i} xs={12} sm={6} md={4} xxl={3}><Opinion opinion={d}/></Col>
                        // ))
                        ['uno','dos','tres'].map((d, i) => (
                            <Col key={i} xs={12} sm={6} md={4}><Opinion opinion={d}/></Col>
                        ))
                }
            </Row>
            {
                showMoreBtnAction &&
                <div>
                    <Button type="button" role="button" onClick={showMoreBtnAction}>{t("global.showMore")}</Button>
                </div>
            }
        </>
    )
}