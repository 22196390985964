import React, { useEffect } from 'react'
import { Container, Row } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { getClubsPublic } from '../../../actions/profiles/clubsActions'
import { useDispatch, useSelector } from 'react-redux'
import { getActivitiesPublic } from '../../../actions/activities/activitiesActions'
import Grid from '../../../components/design/Grid/Grid'
import MenuMarketplace from '../../../components/menus/MenuMarketplace'
import BannerHead from '../../../components/Banners/BannerHead'
import SearchGeneric from '../../../components/design/Search/SearchGeneric'
import { FooterMarketplace } from '../../../components/footers/FooterMarketplace'

export default function Activities() {
    const { t } = useTranslation()
	const dispatch = useDispatch()

    // const {clubs} = useSelector(state=>state.clubs)
    const {activities} = useSelector(state=>state.activities)

    useEffect(() =>{
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        dispatch(getClubsPublic())
        dispatch(getActivitiesPublic())
    }, [dispatch])

    return (
        <>
            <MenuMarketplace section='activities'/>
            <BannerHead image='/images/fondo-auth.jpg' title={t('marketplace.home.activities')}/>            
            <SearchGeneric ancho="75%"/>
            <Container className='market'>
                <Row className="my-3">
                    <Grid type="activity" data={activities}/>
                </Row>
            </Container>
            <FooterMarketplace className='m-0'/>
        </>

    )
}