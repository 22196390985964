import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { getActivity } from '../../../../actions/activities/activitiesActions'
import { getCategory } from '../../../../actions/activities/categoriesActions'
import { getInscription, updateInscription } from '../../../../actions/activities/inscriptionsActions'
import InscriptionForm from '../../../../components/forms/activities/InscriptionForm'

export default function InscriptionEdit({activityselected, categoryselected, inscriptionselected}) { 
  	const { t } = useTranslation()
	const dispatch = useDispatch()
    const navigate = useNavigate()

	const {inscription, resultinscription, loadinginscription} = useSelector(state=>state.inscriptions)
	const {club} = useSelector(state=>state.clubs)
	const {activity} = useSelector(state=>state.activities)
	const {category} = useSelector(state=>state.categories)

    const [editInscription, setEditInscription] = useState()

    useEffect(()=>{
        if(activityselected && categoryselected){
            dispatch(getActivity(activityselected))
            dispatch(getCategory(categoryselected))
            dispatch(getInscription(inscriptionselected))
        }
    //eslint-disable-next-line
    }, [dispatch, activityselected, categoryselected, inscriptionselected])

    useEffect(()=>{
        if(inscription){
            const formattedSeasonPay = inscription.seasonPayments?.map(item => ({
                ...item,
                date: item.date ? new Date(item.date).toISOString().split('T')[0] : '',
                alternativeDate: item.alternativeDate ? new Date(item.alternativeDate).toISOString().split('T')[0] : ''
            })) || []

            setEditInscription({
                title: inscription?.title,
                seasonStart: inscription?.seasonStart,
                seasonEnd: inscription?.seasonEnd,
                seasonPayments: formattedSeasonPay,
                registrationStart: inscription?.registrationStart,
                registrationEnd: inscription?.registrationEnd,
                registrationPayment: inscription?.registrationPayment,
                assignableGroup: inscription?.assignableGroup,
                verify: inscription?.verify,
                federated: inscription?.federated,
                active: inscription?.active,
            })
        }
    //eslint-disable-next-line
    }, [club, inscription])

    const handleInputChange = ({target})=>{
        setEditInscription({
            ...editInscription,
            [target.name]: target.value
        })
    }

    const handleCheckboxChange = ({target}) => {
        setEditInscription({
            ...editInscription,
            [target.name]: target.checked
        })
    }

    const handleDaterangeChange = (value, index) => {
        setEditInscription({
            ...editInscription,
            [`${index}Start`]: value[0],
            [`${index}End`]: value[1]
        })
    }

    const handleAdd = async(e) => {
        e.preventDefault()

        dispatch(updateInscription(editInscription, inscriptionselected))
    }

    useEffect(() => {
        if(resultinscription){
            let newresult = resultinscription
            dispatch({
                type: 'INSCRIPTION_RESET_RESULT'
            })
            switch (newresult) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('inscriptions.editedsuccess')
                    }).then(() => {
                        navigate('/club/inscriptions')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [resultinscription])

	return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <h1 className='ms-2 my-0'>{activity?.title} - {category?.title}</h1>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <h2>{t('inscriptions.editinscription')}</h2>
                            <hr className='mb-4'/>
                            <InscriptionForm state={editInscription} statetype={'edit'} usertype={'club'} isAdding={loadinginscription} submit={handleAdd} inputChange={handleInputChange} checkboxChange={handleCheckboxChange} daterangeChange={handleDaterangeChange}/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
	)
}