import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Button, Row, Col, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getActivitiesByClub } from '../../../actions/activities/activitiesActions'
import { CustomDataTable } from '../../../components/design/Datatables/CustomDataTable'
import useAuth from '../../../context/auth/useAuth'

export default function Activities({clubselected, handleActivitySelect}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
	const {clientauth} = useAuth()

    const {activities} = useSelector(state=>state.activities)

    useEffect(() => {
        if(clientauth){
            dispatch(getActivitiesByClub(clubselected))
        }
    },[dispatch, t, clientauth, clubselected])

	const columnsActivities = [
        {
            name: t('activities.image'),
            cell: activity =>
                <div>
                    {activity.image ?
                        <img src={activity.image} alt={activity.title} height="60px" width="60px"/>
                    : 
                        t('activities.noimage')
                    }
                </div>,
            selector: (activity) => activity.image,
            sortable: false,
        },{
            name: t('activities.title'),
            cell: activity =>
                <div>
                    {activity.title}
                </div>,
            selector: (activity) => activity.title,
            sortable: true,
        },{
			name: t('activities.description'),
			cell: activity =>
				<div>
					{activity.description}
				</div>,
			selector: (activity) => activity.description,
			sortable: true,
		},{
			name: t('activities.sport'),
			cell: activity =>
				<div>
					{t('sports.'+activity.sport)}
				</div>,
			selector: (activity) => activity.sport,
			sortable: true,
		},{
			name: t('activities.visibilityType'),
			cell: activity =>
				<div>
					{t('activities.visibilityTypes.'+activity.visibility_type)}
				</div>,
			selector: (activity) => t('activities.visibilityTypes.'+activity.visibility_type),
			sortable: true,
		},{
			name: '',
			cell: activity =>
				<div>
					<Link onClick={() => handleActivitySelect(activity._id)} to={`/club/editactivity`}>
                        <img src="/images/pencil.svg" alt="pencil" width={15}></img>
					</Link>
				</div>,
			sortable: false,
		}
	]

    return (
        <Container fluid className='p-0 m-0'>
            <Row>
                <Col className="text-center my-3">
                    <Card>
                        <Link to="/club/newactivity" className='btn btn-dashboard'>
                            <h2 className='noBold'>{t('activities.createnewactivity')}</h2>
                        </Link>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col className="text-center my-3">
                    {activities?.length > 0 ?
                        <CustomDataTable columns={columnsActivities} data={activities} />
                    :
                        <h4 className='mt-4'>{t('activities.thereisnotactivities')}</h4>
                    }
                </Col>
            </Row>
            <Row className="text-center my-3">
                <Link to="/club/dashboard">
                    <Button className="btn-secondary my-2 px-5">
                        {t('global.return')}
                    </Button>
                </Link>
            </Row>
        </Container>
    )
}
