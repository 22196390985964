import React from 'react'
import { Container, Row, Col, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"


export default function HeadConfigClub({ nuevaSeccion }) {
    const { t } = useTranslation()

    const storedData = sessionStorage.getItem('dataIncrono')
        const { clubId, activityId, categoryId, inscriptionId, groupId, siteId, zoneId, courtId, areaId } = JSON.parse(storedData)

    return (        
        <Container fluid className='submenu px-0 mb-3'>
            <Row>
                <Col className='d-flex align-items-center'>
                    <h1 className='ms-2 my-0'>{t('club.myclub')}</h1>
                </Col>
            </Row>
            <Row className='my-4'>
                <Col>
                    <Nav defaultActiveKey="/home" as="ul">
                        <Nav.Item as="li">
                            <Nav.Link as={Link} active={nuevaSeccion === "basicdata"} to="/club/basicdata">{t('menus.config.basicdata')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            {clubId?
                            <Nav.Link as={Link} active={nuevaSeccion === "social"} to={`/club/${t("url.club.socialdata")}`}>{t('menus.config.social')}</Nav.Link>
                            : ''}
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link as={Link} active={nuevaSeccion === "documents"} to="/club/documents">{t('menus.config.documents')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link as={Link} active={nuevaSeccion === "billingdata"} to="/club/billingdata">{t('menus.config.billingdata')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link as={Link} active={nuevaSeccion === "paycometdata"} to="/club/paycometdata">{t('menus.config.paycometdata')}</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
            </Row>
        </Container>
    )
}