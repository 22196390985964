import React, { useEffect, useState } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import {Container} from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import MenuSuperior from '../components/menus/MenuSuperior'
import SidebarClub from '../components/menus/SidebarClub'
import Lobby from '../pages/club/Lobby'
import Dashboard from '../pages/club/Dashboard'
import Basicdata from '../pages/club/config/Basicdata'
import Socialdata from '../pages/club/config/Socialdata'
import Documents from '../pages/club/config/Documents'
import Billingdata from '../pages/club/config/Billingdata'
import Paycometdata from '../pages/club/config/Paycometdata'
import Activities from '../pages/club/activities/activities/Activities'
import ActivityAdd from '../pages/club/activities/activities/ActivityAdd'
import ActivityEdit from '../pages/club/activities/activities/ActivityEdit'
import Categories from '../pages/club/activities/categories/Categories'
import CategoryEdit from '../pages/club/activities/categories/CategoryEdit'
import Inscriptions from '../pages/club/activities/inscriptions/Inscriptions'
import InscriptionAdd from '../pages/club/activities/inscriptions/InscriptionAdd'
import InscriptionEdit from '../pages/club/activities/inscriptions/InscriptionEdit'
import Groups from '../pages/club/activities/groups/Groups'
import GroupEdit from '../pages/club/activities/groups/GroupEdit'
import Sites from '../pages/club/sites/sites/Sites'
import AddSite from '../pages/club/sites/sites/AddSite'
import AddSiteStipe2 from '../pages/club/sites/sites/AddSiteStep2'
import AddSiteStipe3 from '../pages/club/sites/sites/AddSiteStep3'
import useAuth from '../context/auth/useAuth'
import EditSite from '../pages/club/sites/sites/EditSite'
import EditSiteStipe2 from '../pages/club/sites/sites/EditSiteStep2'
import EditSiteStipe3 from '../pages/club/sites/sites/EditSiteStep3'
import Zones from '../pages/club/sites/zones/Zones'
import AddZone from '../pages/club/sites/zones/AddZone'
import EditZone from '../pages/club/sites/zones/EditZone'
import Courts from '../pages/club/sites/courts/Courts'
import AddCourt from '../pages/club/sites/courts/AddCourt'
import AddCourtStep2 from '../pages/club/sites/courts/AddCourtStep2'
import EditCourt from '../pages/club/sites/courts/EditCourt'
import EditCourtStep2 from '../pages/club/sites/courts/EditCourtStep2'
import Areas from '../pages/club/sites/areas/Areas'
import AddArea from '../pages/club/sites/areas/AddArea'
import AddAreaStep2 from '../pages/club/sites/areas/AddAreaStep2'
import EditArea from '../pages/club/sites/areas/EditArea'
import EditAreaStep2 from '../pages/club/sites/areas/EditAreaStep2'
import NotFoundPage from '../pages/error/404'
import { useDispatch, useSelector } from 'react-redux'
import { getClubsByUser } from '../actions/profiles/clubsActions'

export default function ClubRouter () {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const {clientauth} = useAuth()
    const dispatch = useDispatch()

    const {clubs} = useSelector(state=>state.clubs)
	
	const [selectedClubId, setSelectedClubId] = useState(null)
	const [selectedActivityId, setSelectedActivityId] = useState(null)
	const [selectedCategoryId, setSelectedCategoryId] = useState(null)
	const [selectedInscriptionId, setSelectedInscriptionId] = useState(null)
	const [selectedGroupId, setSelectedGroupId] = useState(null)
	const [selectedSiteId, setSelectedSite] = useState(null)
	const [selectedZoneId, setSelectedZone] = useState(null)
	const [selectedCourtId, setSelectedCourt] = useState(null)
	const [selectedAreaId, setSelectedArea] = useState(null)
	const [isCollapsed, setIsCollapsed] = useState(false)

	const toggleSidebar = () => {
		setIsCollapsed(!isCollapsed)
	}

	const updateSessionStorage = (newValues) => {
		const currentData = JSON.parse(sessionStorage.getItem('dataIncrono')) || {}
		const updatedData = { ...currentData, ...newValues }
		sessionStorage.setItem('dataIncrono', JSON.stringify(updatedData))
	}

	const handleClientSelect = (clientId) => {
		updateSessionStorage({clientId})
	}

    const handleClubSelect = (clubId) => {
        setSelectedClubId(clubId)
        handleActivitySelect(null)
        handleCategorySelect(null)
        handleInscriptionSelect(null)
        handleGroupSelect(null)
        setSelectedSite(null)
        setSelectedZone(null)
        setSelectedCourt(null)
        setSelectedArea(null)
        updateSessionStorage({clubId})
    }

    const handleActivitySelect = (activityId) => {
        setSelectedActivityId(activityId)
        updateSessionStorage({activityId})
        handleCategorySelect(null)
        handleInscriptionSelect(null)
        handleGroupSelect(null)
    }

    const handleCategorySelect = (categoryId) => {
        setSelectedCategoryId(categoryId)
        updateSessionStorage({categoryId})
        handleInscriptionSelect(null)
        handleGroupSelect(null)
    }

    const handleInscriptionSelect = (inscriptionId) => {
        setSelectedInscriptionId(inscriptionId)
        updateSessionStorage({inscriptionId})
        handleGroupSelect(null)
    }

	const handleGroupSelect = (groupId) => {
		setSelectedGroupId(groupId)
		updateSessionStorage({groupId})
	}

	const handleSiteSelect = (siteId) => {
		setSelectedSite(siteId)
		updateSessionStorage({siteId})
	}

	const handleZoneSelect = (zoneId) => {
		setSelectedZone(zoneId)
		updateSessionStorage({zoneId})
	}

	const handleCourtSelect = (courtId) => {
		setSelectedCourt(courtId)
		updateSessionStorage({courtId})
	}

	const handleAreaSelect = (areaId) => {
		setSelectedArea(areaId)
		updateSessionStorage({areaId})
	}

	useEffect(() => {
		const storedData = sessionStorage.getItem('dataIncrono')
		if (storedData) {
			const { clubId, activityId, categoryId, inscriptionId, groupId, siteId, zoneId, courtId, areaId } = JSON.parse(storedData)
			if (clubId) setSelectedClubId(clubId)
			if (activityId) setSelectedActivityId(activityId)
			if (categoryId) setSelectedCategoryId(categoryId)
			if (inscriptionId) setSelectedInscriptionId(inscriptionId)
			if (groupId) setSelectedGroupId(groupId)
			if (siteId) setSelectedSite(siteId)
			if (zoneId) setSelectedZone(zoneId)
			if (courtId) setSelectedCourt(courtId)
			if (areaId) setSelectedArea(areaId)
		}
	}, [])

	useEffect(() => {
		let token = sessionStorage.getItem('jwt')

		if (!token) {
			navigate('/login')
		}
	}, [navigate])

	useEffect(() => {
		if(clientauth){
			dispatch(getClubsByUser(clientauth?.id))
		}
	}, [dispatch, clientauth])

	const clubRoutesAlias = {
		"": "dashboard",
		"dashboard": "dashboard",
		"lobby": "lobby",
		"basicdata": "basicdata",
		[t("url.club.socialdata")]: "socialdata",
		"documents": "documents",
		"billingdata": "billingdata",
		"paycometdata": "paycometdata",
		"activities": "activities",
		"newactivity": "newactivity",
		"editactivity": "editactivity",
		"categories": "categories",
		"editcategory": "editcategory",
		"inscriptions": "inscriptions",
		"newinscription": "newinscription",
		"editinscription": "editinscription",
		"groups": "groups",
		"editgroup": "editgroup",
		"sites": "sites",
		"sites/new": "sites/new",
		"sites/new/step2": "sites/new/step2",
		"sites/new/step3": "sites/new/step3",
		"sites/edit": "sites/edit",
		"sites/edit/step2": "sites/edit/step2",
		"sites/edit/step3": "sites/edit/step3",
		"zones": "zones",
		"zones/new": "zones/new",
		"zones/edit": "zones/edit",
		"courts": "courts",
		"courts/new": "courts/new",
		"courts/new/step2": "courts/new/step2",
		"courts/edit": "courts/edit",
		"courts/edit/step2": "courts/edit/step2",
		"areas": "areas",
		"areas/new": "areas/new",
		"areas/new/step2": "areas/new/step2",
		"areas/edit": "areas/edit",
		"areas/edit/step2": "areas/edit/step2",
	}

	const getComponentClub = (path) => {
		switch (path) {
			case "lobby": return <Lobby handleClubSelect={handleClubSelect} />;
			case "dashboard": return <Dashboard clubselected={selectedClubId} />;

			case "basicdata": return <Basicdata clubselected={selectedClubId} />;
			case "socialdata": return <Socialdata clubselected={selectedClubId} />;
			case "documents": return <Documents clubselected={selectedClubId} />;
			case "billingdata": return <Billingdata clubselected={selectedClubId} />;
			case "paycometdata": return <Paycometdata clubselected={selectedClubId} />;

			case "activities": return <Activities clubselected={selectedClubId} handleActivitySelect={handleActivitySelect} />;
			case "newactivity": return <ActivityAdd clubselected={selectedClubId} handleActivitySelect={handleActivitySelect} />;
			case "editactivity": return <ActivityEdit clubselected={selectedClubId} activityselected={selectedActivityId} />;

			case "categories": return <Categories clubselected={selectedClubId} activityselected={selectedActivityId} handleCategorySelect={handleCategorySelect} />;
			case "editcategory": return <CategoryEdit clubselected={selectedClubId} activityselected={selectedActivityId} categoryselected={selectedCategoryId} />;

			case "inscriptions": return <Inscriptions activityselected={selectedActivityId} categoryselected={selectedCategoryId} handleInscriptionSelect={handleInscriptionSelect} />;
			case "newinscription": return <InscriptionAdd activityselected={selectedActivityId} categoryselected={selectedCategoryId} handleInscriptionSelect={handleInscriptionSelect} />;
			case "editinscription": return <InscriptionEdit activityselected={selectedActivityId} categoryselected={selectedCategoryId} inscriptionselected={selectedInscriptionId} />;

			case "groups": return <Groups activityselected={selectedActivityId} categoryselected={selectedCategoryId} inscriptionselected={selectedInscriptionId} handleGroupSelect={handleGroupSelect} />;
			case "editgroup": return <GroupEdit inscriptionselected={selectedInscriptionId} groupselected={selectedGroupId} />;

			case "sites": return <Sites clubselected={selectedClubId} handleSiteSelect={handleSiteSelect}/>
			case "sites/new": return <AddSite clubselected={selectedClubId} handleSiteSelect={handleSiteSelect}/>
			case "sites/new/step2": return <AddSiteStipe2 clubselected={selectedClubId} selectedSite={selectedSiteId}/>
			case "sites/new/step3": return <AddSiteStipe3 clubselected={selectedClubId} selectedSite={selectedSiteId}/>
			case "sites/edit": return <EditSite clubselected={selectedClubId} handleSiteSelect={handleSiteSelect}/>
			case "sites/edit/step2": return <EditSiteStipe2 selectedSite={selectedSiteId}/>
			case "sites/edit/step3": return <EditSiteStipe3 selectedSite={selectedSiteId}/>

			case "zones": return <Zones clubselected={selectedClubId} handleZoneSelect={handleZoneSelect} />;
			case "zones/new": return <AddZone clubselected={selectedClubId} />;
			case "zones/edit": return <EditZone clubselected={selectedClubId} selectedZoneId={selectedZoneId} />;

			case "courts": return <Courts clubselected={selectedClubId} handleCourtSelect={handleCourtSelect}/>
			case "courts/new": return <AddCourt clubselected={selectedClubId} handleCourtSelect={handleCourtSelect} selectedCourt={selectedCourtId}/>
			case "courts/new/step2": return <AddCourtStep2 clubselected={selectedClubId} handleCourtSelect={handleCourtSelect} selectedCourt={selectedCourtId}/>
			case "courts/edit": return <EditCourt clubselected={selectedClubId} selectedCourtId={selectedCourtId}/>
			case "courts/edit/step2": return <EditCourtStep2 clubselected={selectedClubId} selectedCourt={selectedCourtId}/>

			case "areas": return <Areas clubselected={selectedClubId} handleAreaSelect={handleAreaSelect}/>
			case "areas/new": return <AddArea clubselected={selectedClubId} handleAreaSelect={handleAreaSelect} selectedArea={selectedAreaId}/>
			case "areas/new/step2": return <AddAreaStep2 clubselected={selectedClubId} handleAreaSelect={handleAreaSelect} selectedArea={selectedAreaId}/>
			case "areas/edit": return <EditArea clubselected={selectedClubId} selectedAreaId={selectedAreaId}/>
			case "areas/edit/step2": return <EditAreaStep2 clubselected={selectedClubId} selectedArea={selectedAreaId}/>

			default: return <NotFoundPage />;
		}
	}

	return (
		<Container fluid className='d-flex p-0'>
			<SidebarClub isCollapsed={isCollapsed} />
			<Container fluid className={`contenedor ${isCollapsed ? 'expanded' : ''}`}>
				<MenuSuperior toggleSidebar={toggleSidebar} isCollapsed={isCollapsed} client={clientauth?.client} clubs={clubs} avatar={clientauth?.avatar} handleClientSelect={handleClientSelect} handleClubSelect={handleClubSelect}/>
				<Routes>
					{Object.entries(clubRoutesAlias).map(([alias, path]) => (
                        <Route key={alias} path={alias} element={getComponentClub(path)} />
                    ))}
					<Route path="*" element={<NotFoundPage />}/>
				</Routes>
			</Container>
		</Container>
	)
	
}