import React from 'react'
import { Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
// import { useTranslation } from "react-i18next"


export default function SidebarAdmin({ isCollapsed }) {
	// const { t } = useTranslation()
  	return (		
		<div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
			<Link to="/"><img src='/images/logotipo-incrono-cabecera.svg' id="logo-menu" alt='Logotipo Incrono' className='mb-5 ms-3' /></Link>
			<Nav className="flex-column p-0">
				
			</Nav>
		</div>
	)
}