import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Nav, Row } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { getClubPublicByUrl } from '../../../actions/profiles/clubsActions'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { getActivityPublicByUrl } from '../../../actions/activities/activitiesActions'
import Loader from '../../../components/design/Loader/Loader'
import { Link } from 'react-router-dom'
import Description from './Description'
import Inscription from './Inscription'

export default function Activity() {
    const { t } = useTranslation()
	const dispatch = useDispatch()
    const { clubUrl, activityUrl } = useParams()

    const {club, loadingclub} = useSelector(state=>state.clubs)
    const {activity, loadingactivity} = useSelector(state=>state.activities)

    const [activeTab, setActiveTab] = useState("description")

    useEffect(() =>{
        dispatch(getClubPublicByUrl(clubUrl))
        dispatch(getActivityPublicByUrl(activityUrl))
    }, [dispatch, clubUrl, activityUrl])

    return (
        <Container className='cuerpo'>
            {
                loadingactivity || loadingclub
                ?
                    <Loader/>
                :
                    <>
                        <div className="my-3">
                            <h1 className='ms-2 my-0'>{activity?.title}</h1>
                        </div>
                        <div className='my-3 text-bold'>
                            <span><Link to="/">{t('menus.general.home')}</Link> {'>'} {t(club?.name)} {'>'} {activity?.title} {'>'} <span className='fw-bold'>{t('activities.inscription')}</span></span>
                        </div>
                        <Row>
                            <Col xs={8}>
                                <div className='submenu my-3'>
                                    <Nav as="ul">
                                        <Nav.Item as="li">
                                            <Nav.Link as={Link} onClick={() => setActiveTab("description")} active={activeTab === "description"}>{t('activities.description')}</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <Nav.Link as={Link} onClick={() => setActiveTab("inscription")} active={activeTab === "inscription"}>{t('activities.inscription')}</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                                {
                                    activeTab === "description" &&
                                        <Description activity={activity}/>
                                }
                                {
                                    activeTab === "inscription" &&
                                        <Inscription activity={activity} club={club}/>
                                }
                            </Col>
                            <Col xs={4} className='mt-4'>
                                <Card className='border-0'>
                                </Card>
                            </Col>
                        </Row>
                    </>
            }
        </Container>
    )
}