import AxiosControl from '../../context/axios/AxiosControl'
const Axios = AxiosControl()

export const getInscription = (inscription) => async (dispatch) => {
    dispatch({
        type: 'INSCRIPTION_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/inscription/get/'+ inscription, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'INSCRIPTION_GET_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'INSCRIPTION_NOT_LOADING'
        })
    }
}

export const getInscriptionsByCategory = (category) => async (dispatch) => {
    dispatch({
        type: 'INSCRIPTION_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/inscription/getinscriptionsbycategory/'+ category, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'INSCRIPTION_GETALL_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'INSCRIPTION_NOT_LOADING'
        })
    }
}

export const addInscription = (inscriptiondata) => async (dispatch) => {
    dispatch({
        type: 'INSCRIPTION_LOADING'
    })
    try {
        const { data, status} = await Axios.post('/api/inscription/register', inscriptiondata, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'INSCRIPTION_ADD_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'INSCRIPTION_NOT_LOADING'
        })
    }
}

export const updateInscription = (inscriptiondata, inscriptionId) => async (dispatch) => {
    dispatch({
        type: 'INSCRIPTION_LOADING'
    })
    try {
        const { data, status} = await Axios.put('/api/inscription/edit/' + inscriptionId, inscriptiondata, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264 || status === 260) {
            dispatch({
                type: 'INSCRIPTION_EDIT_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'INSCRIPTION_NOT_LOADING'
        })
    }
}

export const removeInscription = (inscription) => async (dispatch) => {
    try {
        const { data, status } = await Axios.delete('/api/inscription/remove/' + inscription, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200 || status === 263) {
            dispatch({
                type: 'INSCRIPTION_REMOVE_RESULT',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'INSCRIPTION_NOT_LOADING'
        })
    }
}