import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Row, Col, Card, CardBody, Form, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { CustomDataTable } from '../../../../components/design/Datatables/CustomDataTable'
import useAuth from '../../../../context/auth/useAuth'
import { changeCourtStatus, getCourtsByClub, removeCourt } from '../../../../actions/sites/courtsActions'
import SitesSubMenu from '../../../../components/menus/SitesSubMenu'
import Swal from 'sweetalert2'

export default function Courts({clubselected, handleCourtSelect}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
	const {clientauth} = useAuth()

    const {courts, result} = useSelector(state=>state.courts)

    useEffect(() => {
        handleCourtSelect(null)
    // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if(clientauth){
            dispatch(getCourtsByClub(clubselected))
        }
        
    // eslint-disable-next-line
    },[dispatch, t, clientauth])

    useEffect(() => {
        if(result?.message){
            switch (result.message) {
                case 'editedSuccess':
                    dispatch(getCourtsByClub(clubselected))
                    dispatch({
                        type: 'COURT_RESET_RESULT'
                    })
                    break;
                case "deleteCourtWrongRerservations":
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.accept'),
                        icon: 'warning',
                        text: t('courts.deleteCourtWrongRerservations')
                    })
                    dispatch({
                        type: 'COURT_RESET_RESULT'
                    })
                    break;
                case "deleteAreaWrongRerservations":
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.accept'),
                        icon: 'warning',
                        text: t('courts.deleteAreaWrongRerservations')
                    })
                    dispatch({
                        type: 'COURT_RESET_RESULT'
                    })
                    break;
                default:
                    break;
            }
        }
        // eslint-disable-next-line
    }, [result])

    const handleStatusChange = (courtId, status) => {
        dispatch(changeCourtStatus(courtId, status))
    }

    const handleRemoveCourt = (e, courtId) => {
        e.preventDefault()

        dispatch(removeCourt(courtId))
    }

	const columnsCourts = [
        {
            name: t('courts.image'),
            width:'120px',
            cell: court =>
                <div>
                    {court.image ?
                        <img src={court.image} alt={court.title} className='imagen'/>
                    : 
                        <div className='imagen'></div>
                    }
                </div>,
            selector: (court) => court.image,
            sortable: false,
        },{
            name: t('courts.name'),
            cell: court =>
                <Link onClick={() => handleCourtSelect(court._id)} to={`/club/courts/edit`} className='table-link'>
                    {court.name}
                </Link>,
            selector: (court) => court.name,
            sortable: true,
        },{
            name: t('courts.zone'),
            cell: court =>
                <div>
                    {court.zone?.name}
                </div>,
            selector: (court) => court.zone?.name,
            sortable: true,
        },{
            name: t('courts.site'),
            cell: court =>
                <div>
                    {court.zone?.site?.name}
                </div>,
            selector: (court) => court.zone?.site?.name,
            sortable: true,
        },{
            name: t('courts.status'),
            center: 'true',
            width: '100px',
            cell: court =>
                <div>
                    <Form.Check
                        type='switch'
                        checked={court.active}
                        onChange={({target}) => (handleStatusChange(court._id, target.checked))}
                    />
                </div>,
            selector: (court) => court.active,
            sortable: false,
        },{
			name: t('global.actions'), 
            center: 'true',
            width:'120px',
			cell: court =>
                <OverlayTrigger
                    placement="bottom"
                    trigger='focus'
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <Tooltip className='menu-puntos text-left'>
                            <Link onClick={() => handleCourtSelect(court._id)} to={`/club/courts/edit`} className='dropdown-item'>
                                <img src="/images/edit.svg" alt={t('sites.dataTitle')} width={15}></img> {t('global.edit')}
                            </Link>
                            <Link onClick={() => handleCourtSelect(court._id)} to={`/club/courts/edit/step2`} className='dropdown-item'>
                                <img src="/images/menus/calendar.svg" alt={t('sites.dataTitle')} width={15}></img> {t('courts.schedules')}
                            </Link>
                            <Link onClick={(e) => handleRemoveCourt(e, court._id)} className='dropdown-item'>						
                                <img src="/images/trash.svg" alt="trash" width={15} /> {t('global.delete')}
					        </Link>                            
                        </Tooltip>
                    }
                    >
                    <Button variant="puntos"><img src='/images/menupuntos.svg' alt='Menu auxiliar'/></Button>
                </OverlayTrigger>,
			sortable: false,
		}
	]

    return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <SitesSubMenu active="courts"/>
                </Col>
            </Row>
            <Row className='my-3'>
                <Col>
                    <Card>
                        <CardBody>
                            <h2>{t('courts.courtsList')}</h2>
                            <hr className='mb-4'/>
                            <div className='buttons d-flex justify-content-end'>
                                <Link to="/club/courts/new" className='btn btn-secondary'>
                                    <i className='fas fa-plus'></i> {t('courts.createCourt')}
                                </Link>
                            </div>
                            <div className='my-3'>
                                <Row>
                                    <Col>
                                        {
                                            courts?.length > 0
                                                ?
                                                    <CustomDataTable columns={columnsCourts} data={courts} exportable={false} printable={false}/>
                                                :
                                                    <div className='contenedor-vacio text-center'>
                                                        <h4>{t('courts.noCourts')}</h4>
                                                        {t('courts.thereIsNoCourtsPhrase')}
                                                        <div className='mt-3'>
                                                            <Link to="/club/courts/new" className='btn btn-secondary'>
                                                                {t('courts.createCourt')}
                                                            </Link>
                                                        </div>
                                                    </div>
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}